import React, { useContext, useEffect, useState } from "react";
import { ConfigProvider, DatePicker } from "antd";
import { Context } from "../../context/StateContext";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import { URLconstants } from "../../api/constants";

const FilterForm = ({ setFilterOn }) => {
  const {
    section,
    subs,
    icons,
    setLoading,
    filter,
    userType,
    user,
    setCurrentPage,
    setFilterInfo,
    fetchData,
    filterFunction,
    setFilter,
  } = useContext(Context);
  const [items, setItems] = useState([]);
  const [fieldName, setName] = useState(filter);
  const [optionSelected, setSelected] = useState("");

  const handleFilter = async (e) => {
    e.preventDefault();

    try {
      setCurrentPage(1);
      setLoading(true);
      if (fieldName || optionSelected || e.target[4].value) {
        setFilterInfo({
          fieldName: fieldName,
          subFilter: optionSelected,
          date: e.target[4].value,
        });

        await filterFunction(1, fieldName, optionSelected, e.target[4].value);
      } else {
        setFilterOn(false);
        setFilterInfo({ fieldName: "", subFilter: "", date: "" });
        await fetchData(1);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchOptions = async () => {
    try {
      setItems([]);
      if (section === "organisations") {
        const { data } = await axios.get(
          `${URLconstants.fetchHospitals()}?&filters[visibility]=true`
        );
        const total = data.meta.pagination.total;
        const pages = Math.ceil(total / 100);
        for (let i = 1; i <= pages; i++) {
          const { data } = await axios.get(
            `${URLconstants.fetchHospitals()}?&filters[visibility]=true&pagination[pageSize]=100&pagination[page]=${i}`
          );

          setItems((prev) => [...prev, ...data.data]);
        }
      } else if (section === "hospitals" || section === "doctors") {
        const { data } = await axios.get(
          `${URLconstants.fetchClinics()}?${
            userType === "1"
              ? `&filters[hospital][organisation][id]=${user.id}`
              : userType === "2"
              ? `&filters[hospital][id]=${user.id}`
              : userType === "3" && `&filters[id]=${user.id}`
          }
        &filters[visibility][$eq]=true`
        );
        const total = data.meta.pagination.total;
        const pages = Math.ceil(total / 100);
        for (let i = 1; i <= pages; i++) {
          const { data } = await axios.get(
            `${URLconstants.fetchClinics()}?${
              userType === "1"
                ? `&filters[hospital][organisation][id]=${user.id}`
                : userType === "2"
                ? `&filters[hospital][id]=${user.id}`
                : userType === "3" && `&filters[id]=${user.id}`
            }
          &filters[visibility][$eq]=true&pagination[pageSize]=100&pagination[page]=${i}`
          );
          setItems((prev) => [...prev, ...data.data]);
        }
      } else if (section === "clinics") {
        const { data } = await axios.get(
          `${URLconstants.fetchDoctors()}?${
            userType == 1
              ? `&filters[hospital][organisation][id]=${user.id}`
              : userType == 2
              ? `&filters[hospital][id]=${user.id}`
              : ""
          }&filters[visibility][$eq]=true&fields[0] = name`
        );

        const total = data.meta.pagination.total;
        const pages = Math.ceil(total / 100);
        for (let i = 1; i <= pages; i++) {
          const { data } = await axios.get(
            `${URLconstants.fetchDoctors()}?${
              userType == 1
                ? `&filters[hospital][organisation][id]=${user.id}`
                : userType == 2
                ? `&filters[hospital][id]=${user.id}`
                : ""
            }&filters[visibility][$eq]=true&fields[0]=name&pagination[pageSize]=100&pagination[page]=${i}`
          );
          setItems((prev) => [...prev, ...data.data]);
        }
      } else if (section === "nurses" || section === "staffs") {
        const { data } = await axios.get(
          `${URLconstants.fetchWorkers()}${section}?${
            userType == 1
              ? `&filters[clinic][hospital][organisation][id]=${user.id}`
              : userType == 2
              ? `&filters[clinic][hospital][id]=${user.id}`
              : userType == 3
              ? `&filters[clinic][id]=${user.id}`
              : ""
          }&filters[visibility]=true&fields[0]=email`
        );
        const total = data.meta.pagination.total;
        const pages = Math.ceil(total / 100);
        for (let i = 1; i <= pages; i++) {
          const { data } = await axios.get(
            `${URLconstants.fetchWorkers()}${section}?${
              userType == 1
                ? `&filters[clinic][hospital][organisation][id]=${user.id}`
                : userType == 2
                ? `&filters[clinic][hospital][id]=${user.id}`
                : userType == 3
                ? `&filters[clinic][id]=${user.id}`
                : ""
            }&filters[visibility]=true&fields[0]=email&filters[email][$ne]=null&pagination[pageSize]=100&pagination[page]=${i}`
          );
          setItems((prev) => [...prev, ...data.data]);
        }
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [section]);

  const name = section[0].toUpperCase() + section.slice(1, section.length - 1);
  const names2 = subs[section][0].toUpperCase() + subs[section].slice(1);
  return (
    <div className="lg:mt-3 md:mt-4 mt-2 pb-8  lg:w-full md:w-4/5 ">
      <div className="border-[2px] lg:hidden md:hidden block border-dashed border-slate-600 mb-12 "></div>
      <form
        action=""
        onSubmit={(e) => {
          setFilterOn(true);
          handleFilter(e);
        }}
        className="flex lg:flex-row md:flex-col flex-col lg:items-center  items-start gap-4  font-poppins"
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4 items-center">
          <div className="flex flex-col gap-3">
            <label htmlFor="" className="text-slate-600 text-[18px] ">
              {name}
            </label>
            <input
              type="text"
              className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400"
              value={fieldName}
              onChange={(e) => setName(e.target.value)}
              placeholder={name}
            />
          </div>

          <div className="font-poppins flex flex-col gap-3 w-full">
            <label htmlFor="" className="text-slate-600 text-[18px] ">
              {names2}
            </label>
            <Autocomplete
              disablePortal
              id="doctor-select-demo"
              sx={{
                height: "100%",

                fontFamily: "poppins",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                    background: "rgb(148,163,184)",

                    borderRadius: "1.2rem",
                    padding: "0px",
                  },
                  "&:hover fieldset": {
                    padding: "0px",
                    borderColor: "#1976D2",
                  },
                  "&.Mui-focused fieldset": {
                    padding: "0px",
                    borderColor: "#1976D2",
                  },
                },
                "& .MuiIconButton-root ": {
                  color: "whitesmoke",
                  position: "relative",
                  zIndex: "100",
                },
              }}
              options={items}
              getOptionLabel={(option) => option.name || option.email}
              onChange={(event, value) => {
                event.stopPropagation();
                if (value) {
                  setSelected(value.name || value.email);
                } else {
                  setSelected("");
                }
              }}
              renderOption={(props, option) => (
                <Box {...props}>
                  <div className="flex gap-2 ">
                    {icons[subs[section]]}
                    <p className="font-poppins m-0 text-[14px] text-slate-700">
                      {option.name || option.email}
                    </p>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={names2}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      fontFamily: "poppins",
                      background: "rgb(148,163,184)",
                      color: "rgb(51,65,85)",

                      position: "relative",
                      zIndex: "100",
                      padding: "0.25rem",
                      borderRadius: "1.2rem",
                    },
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-3">
            <label htmlFor="" className="text-slate-600 text-[18px] ">
              Date
            </label>
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    zIndexPopup: 1100,
                    fontSize: "16px",
                    colorPrimaryHover: "none",
                    colorTextDisabled: "whitesmoke",
                    colorTextDescription: "rgb(100,116,139)",
                    colorText: "rgb(51,65,85)",
                    colorTextPlaceholder: "rgb(100,116,139)",
                  },
                },
                token: {
                  colorIcon: "whitesmoke",
                },
              }}
            >
              <DatePicker
                style={{
                  padding: "12px",
                  borderRadius: "1.2rem",
                  fontFamily: "Poppins",
                  background: "rgb(143,168,184)",
                }}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="lg:mt-10 md:mt-10 mt-8">
          <Button
            style={{
              border: "1px solid rgb(203,213,225)",
              borderRadius: "1.3rem",
              padding: "10px 1.5rem",
              color: "",
              textTransform: "none",
              boxShadow: "inset -2px 1px 10px rgb(203,213,225)",
            }}
            type="submit"
          >
            <IoMdSearch size={22} className="text-slate-700" />
            <p className="font-poppins text-slate-700 text-[16px] mx-1">Find</p>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FilterForm;
