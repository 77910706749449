import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../context/StateContext";
import axios from "axios";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Grid } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import { URLconstants } from "../../api/constants";

const SignatureUpload = () => {
  const {
    loading,
    setLoading,
    setSection,
    user,
    userType,
    fetchHospital,
    hospitalList,
    fetchDoctor,
    doctorList,
  } = useContext(Context);
  const [file, setFile] = useState("");
  const [userDoctor, setDoctor] = useState("");
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("signature", file);
      formData.append(
        "destination",
        `signatures/${
          userType === "0" ? userDoctor?.name : user?.name
        }/signature.png`
      );
      formData.append("id", `${userType === "0" ? userDoctor?.id : user?.id}`);

      const { data, status } = await axios.post(
        URLconstants.uploadSignatures(),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (status === 200) {
        console.log(data);
        toast.success(
          <p className="font-poppins text-slate-700">Signature Uploaded!!</p>,
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(
        <p className="font-poppins text-slate-700">{error.message}</p>,
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAndToggleLoading = async () => {
      try {
        await fetchHospital();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndToggleLoading();
  }, []);

  return (
    <>
      <Navbar />
      <section className="relative">
        {loading && (
          <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
            <Grid
              visible={true}
              height="80"
              width="80"
              color="#4A57EC"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="grid-wrapper"
            />
          </div>
        )}
        <div
          className={` ${
            loading && "opacity-[0.4]"
          } px-8  overflow-x-clip overflow-y-scroll scrollbar-hide`}
        >
          <div className="flex gap-4 pt-10 pb-10 mt-[4.5rem] md:items-center lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
            <div>
              <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                Upload Signature
              </p>
              <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
            </div>

            <Link to="/home">
              <Button
                onClick={() => setSection("dash")}
                sx={{
                  textTransform: "none",
                }}
              >
                <p className="lg:text-[18px] text-slate-500 font-poppins">
                  Home
                </p>
              </Button>
            </Link>
          </div>

          <div className={`bg-slate-800 rounded-[2rem] py-10 px-6`}>
            <form
              action=""
              onSubmit={(e) => handleSubmit(e)}
              className="font-poppins grid lg:grid-cols-2  grid-cols-1 gap-x-4 gap-y-4 w-full items-end "
            >
              <div className="flex flex-col gap-4 w-full">
                <label htmlFor="" className="text-slate-200 text-[18px] w-full">
                  Upload Signature &#40;png*&#41;
                </label>
                <input
                  type="file"
                  accept="image/png"
                  multiple
                  required
                  className="px-4 py-2 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 flex justify-center w-full"
                  placeholder="Id"
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
              {userType === "0" && (
                <div className="flex  gap-4 lg:flex-row flex-col w-full">
                  <div className="flex flex-col gap-4 w-full">
                    <label
                      for="file"
                      className="text-slate-300 tracking-wide text-[18px]"
                    >
                      Select Hospital
                    </label>

                    <Autocomplete
                      disablePortal
                      id="doctor-select-demo"
                      sx={{
                        fontFamily: "poppins",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "rgb(71,85,105)",
                            borderRadius: "1.2rem",
                            padding: "1rem 0.75rem",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1976D2",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1976D2",
                          },
                        },
                        "& .MuiIconButton-root ": {
                          color: "whitesmoke",
                          position: "relative",
                          zIndex: "100",
                        },
                      }}
                      options={hospitalList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          fetchDoctor(value.id);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box {...props}>
                          <p className=" font-poppins text-slate-700 indent-2">
                            {option.name}
                          </p>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                              Hospitals
                            </p>
                          }
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontFamily: "poppins",

                              color: "#f0f0f0d2",
                            },
                            autoComplete: "off",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-4  w-full">
                    <label
                      for="file"
                      className="text-slate-300 tracking-wide text-[18px]"
                    >
                      Select Doctor
                    </label>

                    <Autocomplete
                      disablePortal
                      id="doctor-select-demo"
                      sx={{
                        fontFamily: "poppins",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "rgb(71,85,105)",
                            borderRadius: "1.2rem",
                            padding: "1rem 0.75rem",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1976D2",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1976D2",
                          },
                        },
                        "& .MuiIconButton-root ": {
                          color: "whitesmoke",
                          position: "relative",
                          zIndex: "100",
                        },
                      }}
                      options={doctorList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setDoctor(value);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box {...props}>
                          <p className=" font-poppins text-slate-700 indent-2">
                            {option.name}
                          </p>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                              Doctors
                            </p>
                          }
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontFamily: "poppins",

                              color: "#f0f0f0d2",
                            },
                            autoComplete: "off",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              <Button
                sx={{
                  borderRadius: "1.3rem",
                  padding: "15px 2rem",
                  color: "#f0f0f0fe",
                  marginBottom: "4px",
                  width: "160px",

                  boxShadow: "inset -2px 1px 10px #f0f0f0fe",
                }}
                type="submit"
              >
                <p className="font-poppins text-[16px]">Submit</p>
              </Button>
            </form>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default SignatureUpload;
