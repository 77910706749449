import React, { useContext, useState } from "react";
import { Context } from "../../context/StateContext";
import axios from "axios";
import { Input } from "antd";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Autocomplete,
  Box,
  TextField,
  Checkbox,
  Button,
  Chip,
} from "@mui/material";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { URLconstants } from "../../api/constants";
import { Grid } from "react-loader-spinner";
import { toast } from "react-toastify";
import { GiHighFive } from "react-icons/gi";
const MedicalTranscribe = () => {
  const { setOpen, loading, setLoading } = useContext(Context);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const [value, setValue] = useState([]);
  const [fields, setFields] = useState([]);
  const hideFields = [
    {
      key: 1,
      name: "select all",
    },
    {
      key: 2,
      name: "doctor",
    },
    {
      key: 3,
      name: "clinic",
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const form = new FormData();

      form.append("name", e.target[0].value);
      form.append("email", e.target[1].value);
      form.append("mobile_number", e.target[2].value);
      form.append("email_id", e.target[3].value);
      form.append("address", e.target[4].value);
      form.append("showFields", fields);
      form.append("visibleFiles", e.target[7].value);
      form.append("password", e.target[8].value);
      const { data, status } = await axios.post(
        URLconstants.transcripter(),
        form,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      if (status === 200) {
        toast.success("Transcripter Created", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(<p className=" font-poppins">{error.message}</p>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };
  console.log(fields);
  return (
    <section className="relative">
      {loading && (
        <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
          <Grid
            visible={true}
            height="80"
            width="80"
            color="#4A57EC"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-wrapper"
          />
        </div>
      )}

      <div
        className={`lg:w-[55vw] mb-4 py-10 px-8 bg-slate-800 rounded-[2rem] ${
          loading && "opacity-30"
        } `}
      >
        <form
          action=""
          onSubmit={(e) => handleSubmit(e)}
          className="flex flex-col gap-4 font-poppins"
        >
          <div className="flex  flex-col gap-3">
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Name
              </label>
              <input
                required
                type="text"
                name="Doctor"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 focus:border-blue-600 "
                placeholder="Doctor Name"
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Email
              </label>
              <input
                type="email"
                name="Email"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 focus:border-blue-600 "
                placeholder="Email"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Mobile Number
              </label>
              <input
                type="text"
                name="contact"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 dark:[color-scheme:dark] focus:border-blue-600 "
                placeholder="Contact_number"
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Username
              </label>
              <input
                required
                type="text"
                name="number"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 focus:border-blue-600 "
                placeholder="username"
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Address
              </label>
              <input
                type="text"
                name="number"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 focus:border-blue-600 "
                placeholder="Address"
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Hide Fields
              </label>
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  if (newValue.includes("select all")) {
                    const arr = hideFields.map((i) => {
                      if (i.name !== "select all") {
                        return i.name;
                      }
                    });

                    setValue(arr.slice(1));
                    setFields(arr.slice(1));
                  } else {
                    const ids = [];
                    for (const fields of newValue) {
                      ids.push(fields);
                    }
                    setFields(ids);
                    setValue(newValue);
                  }
                }}
                sx={{
                  width: "100%",
                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgb(71,85,105)",
                      borderRadius: "1.2rem",
                      padding: "0px",
                      // background: "rgb(36,49,67)",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    "& fieldset": {
                      color: "whitesmoke",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    color: "whitesmoke",
                    position: "relative",
                    zIndex: "100",
                  },
                  "& .MuiAutocomplete-tag": {
                    // Style for text in tags
                    color: "whitesmoke", // Change the color here
                  },
                }}
                multiple
                id="tags-filled"
                options={hideFields.map((option) => option.name)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Fields"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",
                        background: "rgb(36,49,67)",
                        color: "#f0f0f0d2",
                        position: "relative",
                        zIndex: "100",
                        margin: "0px",
                        padding: "0.5rem",
                        borderRadius: "0.75rem",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Visible Files
              </label>
              <input
                type="number"
                name="number"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 focus:border-blue-600 "
                placeholder="visible files"
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Password
              </label>
              <div className="flex">
                <Input
                  required
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 focus:border-blue-600 font-poppins placeholder:text-slate-400 text-[16px]"
                  placeholder="Password"
                />
                <Button
                  onClick={handleTogglePasswordVisibility}
                  className="text-slate-300 hover:text-slate-500"
                  type="button"
                >
                  {passwordVisible ? (
                    <FaEye className="text-[#f0f0f0d2]" size={20} />
                  ) : (
                    <FaEyeSlash size={20} className="text-[#f0f0f0d2] " />
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex gap-3 lg:flex-row md:flex-col flex-col lg:items-end    items-start ">
            <Button
              sx={{
                borderRadius: "1.3rem",
                padding: "10px 2rem",
                color: "#f0f0f0fe",
                marginBottom: "4px",
                boxShadow: "inset -2px 1px 10px #f0f0f0fe",
              }}
              type="submit"
            >
              <p className="font-poppins text-[16px]">Submit</p>
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default MedicalTranscribe;
