import React from 'react'
import { Link } from 'react-router-dom';
import { Fab } from '@mui/material';
import noise from '../assets/noise.png'
import { FaHome } from "react-icons/fa";

const Terms = () => {
  return (
    <>
    <section className='bg-[#f0f0f0ea] font-poppins w-full h-screen overflow-y-scroll overflow-x-clip  '>
      <div className='relative bg-[url(https://images.pexels.com/photos/4152508/pexels-photo-4152508.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)] w-full h-[65vh]   bg-no-repeat bg-center bg-cover'>
            <div className='absolute h-[65vh] w-full'>
              <img src={noise} alt="" className='w-full h-full' />
            </div>
            <div className='relative lg:top-[30%] w-full py-2 px-3 bg-[#0000002b] lg:left-[55%] md:left-[25%] md:top-[45%] left-[10%] top-[10%]'>
              <p className='lg:text-[94px] text-slate-100  font-black md:text-[80px] text-[48px]'>Terms of Use</p>
              <div className='w-[15%] border-[2px] rounded-[3rem]  border-slate-200  ml-2 mb-2'/>
            </div>
      </div>

      <div className='flex justify-center py-6 bg-[#868B83]'>
        <p className='text-[32px] font-medium text-slate-100'>Terms and Privacy Notice</p>
      </div>

      <div className='w-4/5 mx-auto my-16 flex flex-col gap-4'>
         <div className='flex flex-col gap-4 text-slate-700 tracking-wide text-[15px]'>
            <p>The <Link to='/' className='text-blue-500'>MDofficeManager.com</Link> website, however accessed, and mobile applications ( collectively, the “site” ) are provided by MDofficemanager, LLC. (“MDofficemanager” or “we”), a company organized under the laws of Georgia, U.S.A.</p>
            <p>This privacy notice discloses the privacy practices for www.MDofficeManager.com. This privacy notice applies solely to information collected by this website. It will notify you of the following:</p>
            <ol className='flex flex-col gap-2'>
              <li>1. What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.</li>
              <li>2. What choices are available to you regarding the use of your data.</li>
              <li>3. The security procedures in place to protect the misuse of your information.</li>
              <li>4. How you can correct any inaccuracies in the information.</li>
            </ol>
         </div>
         <div>
            <p className='text-slate-800 text-[28px]' >Information Collection, Use, and Sharing</p>
            <div className='flex flex-col gap-4 text-slate-700 mt-4 tracking-wide text-[15px]'>
           
              <p>We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g. to ship an order.</p>
              <p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this <Link to="/policy" className='text-blue-500'>privacy policy</Link>.</p>
              <p>In order to use this website, a user must first complete the registration form. During registration a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest. At your option, you may also provide demographic information (such as gender or age) about yourself, but it is not required.</p>
              <p>We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>
              <p>Some of our business partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.</p>
              <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
            </div>
         </div>
          <div>
            <p className='text-slate-800 text-[28px]'>Your Access to and Control Over Information</p>
            <div className=' tracking-wide text-[15px] flex flex-col gap-4 text-slate-700 mt-4'>
                <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:</p>
                <ul className='flex flex-col gap-2'>
                  <li>1. See what data we have about you, if any.</li>
                  <li>2. Change/correct any data we have about you.</li>
                  <li>3. Have us delete any data we have about you.</li>
                  <li>4. Express any concern you have about our use of your data.</li>
                </ul>
            </div>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>1. Terms and Conditions of use</p>
            <p className='mt-4 tracking-wide text-[15px] text-slate-700'>By accessing the site, you accept the following Terms and Conditions. You are only authorized to use the Site and its services if you agree to abide by all applicable laws and to these Terms and Conditions. We may revise these Terms and Conditions from time to time by posting changes to the Site and indicating the date of the last revision. Any such revisions shall be effective when posted. Your continued use of the Site following such changes constitutes your agreement to the modified terms.</p>
          </div>
          <div>
          <p className='text-slate-800 text-[28px]'>2. Copyright</p>
            <p className=' tracking-wide mt-4 text-slate-700 text-[15px]'>Permission to use, copy and distribute the data, information or other content published by MDofficeManager on the site (collectively, the “Documentation”) is hereby granted on the condition that no part of the Documentation is used for commercial purposes and is strictly restricted to use for informational purposes. Some of the Documentation may be subject to copyright or other intellectual property rights.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>3. Content Disclaimer</p>
            <div className='text-[15px] tracking-wide mt-4 flex flex-col gap-4 text-slate-700'>
              <p>This site and All CONTENT published on this site is provided “AS IS” and “as available” without any representation or warranty of any kind wither express or implied. MDOFFICEMANAGER hereby specifically disclaim any and all representations and warranties, express or implied, regarding site content, functionality, or materials or information provided by us through the site, including, without limitation, any warranty regarding  infringement, ownership, accuracy, timeliness, completeness and availability and any implied warranties for merchantability, fitness for a particular purpose. Any MDofficeManager  documentation may include technical inaccuracies or typographical errors. Changes and additions may be made by MDofficeManager from time to time to any documentation contained on the site.</p>
              <p>Where we provide or enable links to third-party sites, we do so for information purposes only and we accept no liability for your use of the same.</p>
            </div>
          </div>
          <div>
          <p className='text-slate-800 text-[28px]'>4. Site user engagement</p>
          <div className='flex flex-col text-slate-700 mt-4 text-[15px] tracking-wide gap-4'>
             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>4. 1 . MDofficeManager's Responsibility for user-submitted information.</p>
             </div>
             <p>The Site acts, in part, as a passive channel for online distribution of user-submitted information. Certain parts of the Site contain information submitted by visitors of the Site. Examples may be neighborhood reports, discussion forums, and comments on blog postings. MDofficeManager will not screen information in advance and is not responsible for screening or monitoring posted material. The user-submitted information may not reflect MDofficeManager’s view and MDofficeManager does not have any obligation to monitor, edit, or review any user-submitted information. MDofficeManager assumes no responsibility or liability arising from the user-submitted information.</p>
             <p>
             The information submitted by users on out Site may be collected, transferred to, and stored by us in the U.S. and by our affiliated in other countries where we operate, including the Asia. Therefore, data submitted by users located in the Asia may be processed outside the Asia. In this event, we will ensure that such recipient offers an adequate level of protection.
             </p>
             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>4. 2. Appropriate Conduct When Submitting Information OR Using OR Accessing The Site</p>
             </div>
             <p>
             You represent and warrant that information submitted by you on the Site is true and correct and that you have the right to submit any such information. You agree that you will not use language and content which may be reasonably regarded as inappropriate by users. Your use of the Site and your submission of information on the Site must comply with any and all applicable laws.
             </p>
             <p>
             You are responsible for your own conduct and communications relating to this Site and for any consequences thereof. You will be responsible for all obligations incurred, liabilities for harm caused, and actions taken through your use of or access to the Site. You agree to access and use the Site only to send and receive messages and material that are legal and are related to the lawful content on the Site.
             </p>
             <p>
             By way of example, and not as a limitation, you agree that when using the Site, you will not:
             </p>
             <p>(a) defame, abuse, harass, stalk, threaten or otherwise violate the legal rights ( such as rights of privacy and publicity ) of others;</p>
             <p>(b) upload, post or otherwise make available any inappropriate, defamatory, obscene, or unlawful content;</p>
             <p>(c) upload, post or otherwise make available any content that infringes any patent, trademark, copyright, trade secret or other proprietary right of any party, unless you are the owner of the rights, or have the permission of the owner or other legal justification to use such content;</p>
             <p>(d) upload, post or otherwise make available any other content, message, or communication prohibited by applicable law. the Terms and Conditions or any other applicable policies or guidelines;</p>
             <p>(e) download any file posted by another that you know, or reasonably should know, cannot be legally distributed in such manner;</p>
             <p>(f) impersonate another person or entity, or falsify or delete any author attributions or proprietary designations or labels of the origin or source of Content, software or other material;</p>
             <p>(g) promote or provide instructional information about illegal activities;</p>
             <p>
             (h) promote physical harm or injury against any group or individual;
             </p>
             <p>
             (i) transmit any viruses, worms, defects, Trojan horses, or any items of a destructive nature.
             </p>
             <p>
             (j) download, share, access, or otherwise use the Documentation and/or any other content on the Site for commercial purposes;
             </p>
             <p>
             (k) rent, lease, loan, trade, or sell/re-sell the Documentation or access to the services or Documentation;
             </p>
             <p>
             (l) remove any copyright, trademark, or other proprietary rights notices contained in or on the Site or Documentation;
             </p>
             <p>
             (m) use bots, scripts, or other automated methods to access the Siote or any of the services or Documentation;
             </p>
             <p>
             (n) engage in “framing”, “mirroring”, or otherwise simulating the appearance of function of the Site or any Documentation; OR
             </p>
             <p>
             (o) access the services and/or Documentation through interfaces not expressly provided or authorized by MDofficeManager.
             </p>
             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>4.3 Abusive User-Submitted Information</p>
             </div>
             <p>
             MDofficeManager reserves the right to remove or change user-submitted information which it determines, in its sole discretion, violates these Terms and Conditions without any liability to you or any user. MDofficeManager reserves the right to terminate your ability to access or use the Site or any Documentation and prevent your further access to the service or Documentation, if it, in its sole discretion, believes that you are violating or have violated the Terms and Conditions or any applicable law relating to the Site or Documentation.
             </p>
             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>4. 4. Your Rights To user-submitted Information</p>
             </div>
             <p>
             MDofficeManager claims no ownership or control over any the information submitted by you. You or a third-party licensor, as appropriate, retain all patent, trademark and copyright, if any, to any information you submit and you are responsible for protecting those rights. By submitting information, you grant MDofficeManager a worldwide, non-exclusive, royalty-free license to use, reproduce, adapt, publish and create derivative works from the information you submit to the site for the purpose of display and distribution. This licence terminates when such information is deleted from the Site.
             </p>
          </div>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>5. Privacy</p>
            <p className='text-[15px] text-slate-700 mt-4'>Please refer to our <Link to="/policy" className='text-blue-500'>Privacy Policy</Link> for more Information.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>6. Indemnification</p>
            <p className='text-[15px] text-slate-700 mt-4'>You agree to indemnify and hold MDofficeManager harmless from and against all losses, expenses, damages and costs, including reasonable attorneys fees, resulting from your use of the Site and/or Documentation, including but not limited to, any violation of these Terms and Conditions.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>7. Limitation of Liability</p>
            <p className='text-[15px] text-slate-700 mt-4'>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, WE DISCLAIM LIABILITY, AND YOU AGREE THAT WE ARE NOT LIABLE, FOR ANY LOSSES AND EXPENSES OF WHATEVER NATURE AND HOWSOEVER ARISING OUT OF YOUR USE OF THE DOCUMENTATION AND/OR SITE, OR ANY WEBSITE OR APPS WITH WHICH EITHER IS LINKED, INCLUDING WITHOUT LIMITATION ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGED, LOSS OF USE, LOSS OF DATA, LOSS CAUSED BY A VIRUS, CLAIMS OF THIRD PARTIES, OR OTHER LOSSES OF ANY KIND OR CHARACTER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF US, OUR LICENSORS, EMPLOYEES OR AGENTS TO YOU, OR ANY OTHER PARTY EXCEED, IN THE AGGREGATE THE AMOUNT YOU PAID MDOFFICEMANAGER TO USE THIS SITE. YOUR CLAIM FOR SUCH DAMAGES IS OUR SOLE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN THE EVENT OF THE BREACH BY US OF THIS AGREEMENT. THE FORGOING SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY YOU MIGHT HAVE.  No action, regardless of form, arising out of this agreement may be brought by you more than twelve (12) months after the cause of action first arose.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>9. Enforceability</p>
            <p className='text-[15px] text-slate-700 mt-4'>Should any part of the Terms and Conditions be declared invalid or unenforceable by a court of competent jurisdiction, this shall not affect the validity of any remaining portion and such remaining portion shall remain and such remaining portion shall remain in full force and effect as if the invalid portion of the Terms and Conditions had been eliminated.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>8. Choice of Law</p>
            <p className='text-[15px] text-slate-700 mt-4'>The Site, and these Terms and Conditions are made in Georgia. These Terms and Conditions are subject to Georgia law, without regard to its choice of law provisions, and suit, must be brought exclusively within the courts of the state of Georgia, Cobb Country. You agree to submit to the jurisdiction of the courts located in the state of Georgia.</p>
          </div>
           <div>
           <p className='text-slate-800 text-[28px]'>Security</p>
           <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>
             <p>Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for “https” at the beginning of the address of the Web page.</p>
             <p>
             While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
             </p>
           </div>
           </div>
      </div>
       
      <div className='flex flex-col items-center w-11/12 mx-auto gap-2 my-10'>
        <div className='flex items-center justify-center w-full gap-4'>
        <div className='border border-slate-700 w-[20%]'></div>
        <p className='text-slate-700 text-[15px] text-center'>If you feel that we are not abiding by this terms</p>
        <div className='border border-slate-700 w-[20%]'></div>
        </div>
        <div className="text-center">
          <p className='text-slate-700 text-[15px]'>You should contact us immediately via telephone at 812-248-9206 or via  <a className='text-blue-400 ' href='mailto:Sales@MDofficeManager.com'>Sales@MDofficeManager.com</a></p>
         
        </div>
      </div> 
    </section>    
    <Link to="/home">
    <Fab  sx={{
           position:"absolute",
           bottom:"1.5rem",
           right:"2rem",
           background:"#27284A"
          }}><FaHome color='whitesmoke' size={25}/></Fab>
    </Link>
    </>
  )
}

export default Terms