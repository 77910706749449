import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "./authAction";

const userToken = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    userToken,
    loading: false,
    error: null,
  },
  reducers: {
    addToken: (state, action) => {
      state.userToken = localStorage.getItem("userToken");
    },
    login: (state, action) => {
      state.user = action.payload["user"];
    },
    logOut: (state) => {
      state.userToken = null;
      state.user = null;
      localStorage.clear();
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },

    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload["user"];
      state.userToken = payload["jwt"];
      localStorage.setItem("userToken", payload["jwt"]);
      localStorage.setItem("isAuth", true);
      localStorage.setItem("user", JSON.stringify(payload["user"]));
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { addToken, login, logOut } = authSlice.actions;
export default authSlice.reducer;
