import React, { useState, useEffect, useContext } from "react";
import Navbar from "../navbar";
import SideBarFloat from "../SideBarFloat";
import bg from "../../assets/D2.png";
import { ToastContainer, toast } from "react-toastify";
import { Button, ConfigProvider, Table, Dropdown } from "antd";
import {
  IoIosAdd,
  IoMdAdd,
  IoMdClose,
  IoMdCreate,
  IoMdPersonAdd,
  IoMdSave,
  IoMdSearch,
} from "react-icons/io";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { CiEdit } from "react-icons/ci";
import { CgErase } from "react-icons/cg";
import axios from "axios";
import { URLconstants } from "../../api/constants";
import { Context } from "../../context/StateContext";
import { Autocomplete, Box, TextField, Checkbox } from "@mui/material";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Grid } from "react-loader-spinner";
import { IoChevronDown } from "react-icons/io5";
import EditWorkPool from "./EditWorkpool";
const Workpool = () => {
  const { fetchHospital, hospitalList, loading, setLoading } =
    useContext(Context);
  const [workpoolData, setWorpools] = useState([]);
  const [editPool, setEditPool] = useState(false);
  const [open, setOpen] = useState(true);
  const [mapMt, setMapMt] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [currClinics, setClinics] = useState([]);
  const [currDoctors, setDoctors] = useState([]);
  const [workTypes, setWorktypes] = useState([]);
  const [dictators, setDictators] = useState([]);
  const [createPool, setCreatePool] = useState(false);
  const [id, setId] = useState(null);
  const [groupFilter, setGroupFilter] = useState({
    name: "",
    doctor: "",
    transcripter: "",
  });
  const [groupInfo, setGroupINfo] = useState({
    name: "",
    doctors: [],
    clinics: [],
    work_types: [],
    transcripters: [],
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Doctors",
      dataIndex: "doctors",
      key: "doctors",
      render: (item, record) => {
        const items = [];
        if (item) {
          for (let i = 0; i < item.length; i++) {
            items.push({
              key: i + 1,
              label: <p className=" font-poppins">{item[i].name}</p>,
            });
          }
        }
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button className=" font-poppins">
              <div className="flex gap-1 items-center">
                <p>Doctors</p>

                <IoChevronDown />
              </div>
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "Clinics",
      dataIndex: "clinics",
      key: "clinics",
      render: (item, record) => {
        const items = [];
        if (item) {
          for (let i = 0; i < item.length; i++) {
            items.push({
              key: i + 1,
              label: <p className=" font-poppins">{item[i].name}</p>,
            });
          }
        }
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button className=" font-poppins">
              <div className="flex gap-1 items-center">
                <p>Clinics</p>

                <IoChevronDown />
              </div>
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "MT",
      dataIndex: "transcripters",
      key: "transcripters",
      render: (item, record) => {
        const items = [];
        if (item) {
          for (let i = 0; i < item.length; i++) {
            items.push({
              key: i + 1,
              label: <p className=" font-poppins">{item[i].name}</p>,
            });
          }
        }
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button className=" font-poppins">
              <div className="flex gap-1 items-center">
                <p>MT</p>

                <IoChevronDown />
              </div>
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "Worktypes",
      dataIndex: "work_types",
      key: "work_types",
      render: (item, record) => {
        const items = [];
        if (item) {
          for (let i = 0; i < item.length; i++) {
            items.push({
              key: i + 1,
              label: <p className=" font-poppins">{item[i].name}</p>,
            });
          }
        }
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button className=" font-poppins">
              <div className="flex gap-1 items-center">
                <p>Worktypes</p>

                <IoChevronDown />
              </div>
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "Created-At",
      dataIndex: "createdAt",
      key: "createdAt",

      render: (item) => item.split("T")[0],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    // {
    //   title: "Map",
    //   key: "map",
    //   dataIndex: "map",
    //   render: (_, record) => (
    //     <Button className="" onClick={() => setMapMt(true)}>
    //       <IoMdPersonAdd size={20} />
    //     </Button>
    //   ),
    // },
    {
      title: "Edit",
      key: "edit",
      dataIndex: "edit",
      render: (_, record) => (
        <Button
          className=""
          onClick={() => {
            setEditPool(true);
            setId(record);
          }}
        >
          <CiEdit size={20} />
        </Button>
      ),
    },
  ];
  const fetchDoctor = async () => {
    try {
      setDoctorList([]);
      const { data } = await axios.get(
        `${URLconstants.fetchDoctors()}?&populate=hospital,clinics&filters[visibility][$eq]=true`
      );
      const total = data.meta.pagination.total;
      const pages = Math.ceil(total / 100);
      for (let i = 1; i <= pages; i++) {
        const { data } = await axios.get(
          `${URLconstants.fetchDoctors()}?&populate=hospital,clinics&filters[visibility][$eq]=true&pagination[pageSize]=100&pagination[page]=${i}`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setDoctorList((prev) => [...prev, ...rowWithKeys]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPools = async () => {
    try {
      const { data } = await axios.get(
        `${URLconstants.BASE_URL}/api/groups?&populate=*${
          groupFilter.name && `&filters[name][$containsi]=${groupFilter.name}`
        }${
          groupFilter.doctor && `&filters[doctors][name]=${groupFilter.doctor}`
        }${
          groupFilter.transcripter &&
          `&filters[transcripters][name]=${groupFilter.transcripter}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      const rowWithKeys = data.data.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      setWorpools(rowWithKeys);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchWorkTypes = async () => {
    const { data } = await axios.get(`${URLconstants.BASE_URL}/api/work-types`);
    const rowWithKeys = data.data.map((item, index) => {
      return {
        ...item,
        key: index,
      };
    });
    setWorktypes(rowWithKeys);
  };
  const fetchTranscripters = async () => {
    const { data } = await axios.get(
      `${URLconstants.BASE_URL}/api/transcripters`
    );
    const rowWithKeys = data.data.map((item, index) => {
      return {
        ...item,
        key: index,
      };
    });
    setDictators(rowWithKeys);
  };
  const createGroup = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data, status } = await axios.post(
        `${URLconstants.BASE_URL}/api/groups`,
        {
          data: groupInfo,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (status === 200) {
        toast.success(<p className=" font-poppins">Group Created!</p>, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchPools();
        setCreatePool(false);
      }
    } catch (error) {
      console.log(error);

      toast.error(<p className=" font-poppins">{error.message}</p>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPools();
    fetchHospital();
    fetchDoctor();
    fetchWorkTypes();
    fetchTranscripters();
    setGroupINfo({
      doctors: [],
      clinics: [],
      transcripters: [],
      work_types: [],
    });

    setValue([]);
    setValue2([]);
    setValue3([]);
    setValue4([]);
    setValue5([]);
    setClinics([]);
    setDoctors([]);
  }, [editPool]);
  const [value, setValue] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value5, setValue5] = useState([]);

  return (
    <section>
      <Navbar />
      <SideBarFloat />

      {!editPool && (
        <div className="h-screen w-full flex  bg-[#f0f0f0ea] font-poppins relative overflow-clip ">
          <div>
            <img
              src={bg}
              alt="bg"
              className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
            />
          </div>
          {loading && (
            <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
              <Grid
                visible={true}
                height="80"
                width="80"
                color="#4A57EC"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass="grid-wrapper"
              />
            </div>
          )}
          {!createPool && (
            <div
              className={` px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100] flex flex-col ${
                mapMt && "opacity-40"
              } `}
            >
              <div className="mt-28 flex lg:flex-row flex-col  lg:items-end items-start  justify-between w-full gap-y-4">
                <div className=" grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 w-full">
                  <div className="font-poppins flex flex-col gap-3 w-full ">
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      Name
                    </label>
                    <input
                      type="text"
                      className="px-4 py-3 rounded-xl outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400 w-full"
                      placeholder="Min"
                      onChange={(e) =>
                        setGroupFilter((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="font-poppins flex flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      Doctor
                    </label>
                    <Autocomplete
                      disablePortal
                      id="doctor-select-demo"
                      sx={{
                        height: "100%",

                        fontFamily: "poppins",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            background: "rgb(148,163,184)",

                            borderRadius: "0.75rem",
                            padding: "0px",
                          },
                          "&:hover fieldset": {
                            padding: "0px",
                            borderColor: "#1976D2",
                          },
                          "&.Mui-focused fieldset": {
                            padding: "0px",
                            borderColor: "#1976D2",
                          },
                        },
                        "& .MuiIconButton-root ": {
                          color: "whitesmoke",
                          position: "relative",
                          zIndex: "100",
                        },
                      }}
                      options={doctorList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setGroupFilter((prev) => ({
                            ...prev,
                            doctor: value.name,
                          }));
                        } else {
                          setGroupFilter((prev) => ({ ...prev, doctor: "" }));
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box {...props}>
                          <p className="font-poppins m-0 text-[14px] text-slate-700">
                            {option.name}
                          </p>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontFamily: "poppins",
                              background: "rgb(148,163,184)",
                              color: "rgb(51,65,85)",

                              position: "relative",
                              zIndex: "100",
                              padding: "0.25rem",
                              borderRadius: "1.2rem",
                            },
                            autoComplete: "off",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="font-poppins flex flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      MT
                    </label>
                    <Autocomplete
                      disablePortal
                      id="doctor-select-demo"
                      sx={{
                        height: "100%",

                        fontFamily: "poppins",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            background: "rgb(148,163,184)",

                            borderRadius: "0.75rem",
                            padding: "0px",
                          },
                          "&:hover fieldset": {
                            padding: "0px",
                            borderColor: "#1976D2",
                          },
                          "&.Mui-focused fieldset": {
                            padding: "0px",
                            borderColor: "#1976D2",
                          },
                        },
                        "& .MuiIconButton-root ": {
                          color: "whitesmoke",
                          position: "relative",
                          zIndex: "100",
                        },
                      }}
                      options={dictators}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setGroupFilter((prev) => ({
                            ...prev,
                            transcripter: value.name,
                          }));
                        } else {
                          setGroupFilter((prev) => ({
                            ...prev,
                            transcripter: "",
                          }));
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box {...props}>
                          <p className="font-poppins m-0 text-[14px] text-slate-700">
                            {option.name}
                          </p>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontFamily: "poppins",
                              background: "rgb(148,163,184)",
                              color: "rgb(51,65,85)",

                              position: "relative",
                              zIndex: "100",
                              padding: "0.25rem",
                              borderRadius: "1.2rem",
                            },
                            autoComplete: "off",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex gap-2 lg:flex-row md:flex-row flex-col pr-16 justify-end">
                  <Button
                    onClick={() => fetchPools()}
                    className=" shadow-[inset_-2px_1px_10px_rgb(203,213,225)] flex items-center justify-center py-6 rounded-[1.2rem] "
                  >
                    <div className="flex gap-1 items-center text-slate-700 font-poppins ">
                      <IoMdSearch size={22} className="" />
                      <p className="text-[16px]">Search</p>
                    </div>
                  </Button>
                  {/* <Button
                    onClick={() => {
                      setGroupFilter({
                        doctor: "",
                        name: "",
                        transcripter: "",
                      });
                      fetchPools();
                    }}
                    className="shadow-[inset_-2px_1px_15px_#f21d61]  flex items-center justify-center py-6 rounded-[1.2rem] "
                  >
                    <div className="flex gap-1 items-center text-slate-700 font-poppins ">
                      <CgErase size={22} className="" />
                      <p className="text-[16px]">Clear</p>
                    </div>
                  </Button> */}
                  <Button
                    onClick={() => setCreatePool(true)}
                    className="bg-slate-900 flex items-center justify-center py-6 rounded-[1.2rem]  "
                  >
                    <div className="flex gap-1 items-center text-slate-200 font-poppins ">
                      <IoMdAdd size={22} className="" />
                      <p className="text-[16px]">Create Pool</p>
                    </div>
                  </Button>
                </div>
              </div>
              <div className="py-8 mt-4">
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerBorderRadius: 20,
                        headerBg: "#cbd5e1",
                        headerSortHoverBg: "",
                      },
                    },
                  }}
                >
                  <Table
                    dataSource={workpoolData}
                    columns={columns}
                    className="rounded-[1.5rem]  text-slate-800 shadow-lg shadow-slate-300  "
                    scroll={{ y: 600, x: "calc(600px + 45%)" }}
                  />
                </ConfigProvider>
              </div>
            </div>
          )}
          {mapMt && (
            <div className="flex flex-col absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100]  w-fit bg-slate-800 gap-4 items-start py-8 px-6 rounded-lg  ">
              <p className="text-[20px] text-slate-300 font-medium tracking-wide">
                Manage User List
              </p>
              <div className=" w-full bg-slate-200 border border-slate-600 mb-4 rounded-full" />
              <div className=" lg:flex-row md:flex flex flex-col gap-4 ">
                <div>
                  <label htmlFor="" className="text-slate-200 text-[18px]">
                    Map Users
                  </label>
                  <div className="bg-slate-600 flex justify-center items-center px-4 py-3 rounded-t-lg mt-3">
                    <input
                      type="text"
                      className="px-4 py-2 rounded-tl-xl rounded-bl-xl outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-300 w-full"
                      placeholder="Search "
                    />
                    <Button className="flex items-center gap-2 rounded-tl-none rounded-bl-none text-slate-200 py-[1.2rem] text-[16px] font-poppins">
                      <IoMdSearch size={25} className=" font-medium" />
                    </Button>
                  </div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBorderRadius: 0,
                          headerBg: "#cbd5e1",
                          headerSortHoverBg: "",
                        },
                      },
                    }}
                  >
                    <Table dataSource={[]} className="rounded-none" />
                  </ConfigProvider>
                </div>
                <div>
                  <label htmlFor="" className="text-slate-200 text-[18px]">
                    Mapped Users
                  </label>
                  <div className="bg-slate-600 flex justify-center items-center px-4 py-3 rounded-t-lg mt-3">
                    <input
                      type="text"
                      className="px-4 py-2 rounded-tl-xl rounded-bl-xl outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-300 w-full"
                      placeholder="Search "
                    />
                    <Button className="flex items-center gap-2 rounded-tl-none rounded-bl-none text-slate-200 py-[1.2rem] text-[16px] font-poppins">
                      <IoMdSearch size={25} className=" font-medium" />
                    </Button>
                  </div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBorderRadius: 0,
                          headerBg: "#cbd5e1",
                          headerSortHoverBg: "",
                        },
                      },
                    }}
                  >
                    <Table dataSource={[]} className="rounded-none" />
                  </ConfigProvider>
                </div>
              </div>

              <div className=" w-full bg-slate-200 border border-slate-600 my-4 rounded-full" />
              <div className="flex gap-2 w-full  justify-end pb-4">
                <Button className="bg-green-700 flex items-center justify-center py-5 ">
                  <div className="flex gap-1 items-center text-slate-200 font-poppins ">
                    <IoMdSave size={22} className="" />
                    <p className="text-[16px]">Save</p>
                  </div>
                </Button>
                <Button
                  className="bg-red-500 flex items-center justify-center py-5 "
                  onClick={() => setMapMt(false)}
                >
                  <div className="flex gap-1 items-center text-slate-200 font-poppins ">
                    <IoMdClose size={22} className="" />
                    <p className="text-[16px]">Close</p>
                  </div>
                </Button>
              </div>
            </div>
          )}
          {createPool && (
            <div
              className={`px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide  relative z-[100] flex flex-col gap-4 ${
                loading && "opacity-30"
              }`}
            >
              <div className="font-poppins flex flex-col gap-3 mt-28">
                <label htmlFor="" className="text-slate-600 text-[18px] ">
                  Group Name
                </label>
                <input
                  required
                  type="text"
                  className="px-4 py-3 rounded-xl outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400 w-full"
                  placeholder="Enter Group Name "
                  onChange={(e) =>
                    setGroupINfo((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
              </div>
              <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 w-full gap-y-6 gap-x-4">
                <div className="w-full h-[300px]">
                  <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                    <p className="text-[18px] text-slate-300">Hospitals</p>
                    <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                      <IoIosAdd size={25} className=" font-medium" />
                      Add
                    </Button>
                  </div>
                  <Autocomplete
                    id="doctor-select-demo"
                    disablePortal={true}
                    multiple
                    value={value}
                    filterOptions={(options, params) => {
                      // <<<--- inject the Select All option
                      const filter = createFilterOptions();
                      const filtered = filter(options, params);
                      return [
                        { name: "Select All...", all: true },
                        ...filtered,
                      ];
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false,
                          },
                          {
                            name: "preventOverflow",
                            enabled: false,
                          },
                        ],
                      },
                    }}
                    sx={{
                      width: "100%",
                      height: "100px",
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",

                          padding: "0px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        "& fieldset": {
                          color: "whitesmoke",
                        },
                      },
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    options={hospitalList}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      if (newValue.find((option) => option.all)) {
                        if (value.length !== hospitalList.length) {
                          setValue3([]);
                          setValue2([]);
                          for (const hospital of hospitalList) {
                            setClinics((prev) => [
                              ...prev,
                              ...hospital.clinics,
                            ]);
                            setDoctors((prev) => [
                              ...prev,
                              ...hospital.doctors,
                            ]);
                          }
                        } else {
                          setClinics([]);
                          setDoctors([]);
                          setValue2([]);
                          setValue3([]);
                          setGroupINfo((prev) => ({ ...prev, doctors: [] }));
                          setGroupINfo((prev) => ({ ...prev, clinics: [] }));
                        }
                        return setValue(
                          value.length === hospitalList.length
                            ? []
                            : hospitalList
                        );
                      }

                      setValue(newValue);
                      setClinics([]);
                      setDoctors([]);
                      for (const hospital of newValue) {
                        setClinics((prev) => [...prev, ...hospital.clinics]);
                        setDoctors((prev) => [...prev, ...hospital.doctors]);
                      }
                      const selClinics = [];
                      const selDoctors = [];
                      const cIds = [];
                      const dIds = [];
                      for (const hospital of newValue) {
                        for (const clinic of hospital.clinics)
                          if (value3.includes(clinic)) {
                            selClinics.push(clinic);
                            cIds.push(clinic.id);
                          }
                        for (const doctor of hospital.doctors)
                          if (value2.includes(doctor)) {
                            selDoctors.push(doctor);
                            dIds.push(doctor.id);
                          }
                      }
                      setGroupINfo((prev) => ({ ...prev, doctors: dIds }));
                      setGroupINfo((prev) => ({ ...prev, clinics: cIds }));
                      setValue3(selClinics);
                      setValue2(selDoctors);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} className=" font-poppins">
                        <Checkbox
                          icon={<CheckBoxIcon fontSize="small" />}
                          checkedIcon={
                            <CheckBoxOutlineBlank fontSize="small" />
                          }
                          style={{ marginRight: 8 }}
                          // checked={selected} <<<--- OLD
                          checked={
                            option.all
                              ? !(value.length === hospitalList.length)
                              : !selected
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Search"}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",

                            color: "rgb(51 65 85)",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full h-[300px]">
                  <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                    <p className="text-[18px] text-slate-300">Doctors</p>
                    <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                      <IoIosAdd size={25} className=" font-medium" />
                      Add
                    </Button>
                  </div>
                  <Autocomplete
                    id="doctor-select-demo"
                    disablePortal={true}
                    multiple
                    value={value2}
                    filterOptions={(options, params) => {
                      // <<<--- inject the Select All option
                      const filter = createFilterOptions();
                      const filtered = filter(options, params);
                      return [
                        { name: "Select All...", all: true },
                        ...filtered,
                      ];
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false,
                          },
                          {
                            name: "preventOverflow",
                            enabled: false,
                          },
                        ],
                      },
                    }}
                    sx={{
                      width: "100%",
                      height: "100px",
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",

                          padding: "0px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        "& fieldset": {
                          color: "whitesmoke",
                        },
                      },
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    options={currDoctors}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      if (newValue.find((option) => option.all)) {
                        if (value2.length !== currDoctors.length) {
                          setValue2([]);
                          const ids = [];
                          for (const doctor of currDoctors) {
                            ids.push(doctor.id);
                          }
                          setGroupINfo((prev) => ({ ...prev, doctors: ids }));
                        } else {
                          setGroupINfo((prev) => ({ ...prev, doctors: [] }));
                        }
                        return setValue2(
                          value2.length === currDoctors.length
                            ? []
                            : currDoctors
                        );
                      }
                      setValue2(newValue);
                      const ids = [];
                      for (const doctor of newValue) {
                        ids.push(doctor.id);
                      }
                      setGroupINfo((prev) => ({ ...prev, doctors: ids }));
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} className=" font-poppins">
                        <Checkbox
                          icon={<CheckBoxIcon fontSize="small" />}
                          checkedIcon={
                            <CheckBoxOutlineBlank fontSize="small" />
                          }
                          style={{ marginRight: 8 }}
                          // checked={selected} <<<--- OLD
                          checked={
                            option.all
                              ? !(value2.length === currDoctors.length)
                              : !selected
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Search"}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",

                            color: "rgb(51 65 85)",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full h-[300px]">
                  <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                    <p className="text-[18px] text-slate-300">Clinics</p>
                    <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                      <IoIosAdd size={25} className=" font-medium" />
                      Add
                    </Button>
                  </div>
                  <Autocomplete
                    id="doctor-select-demo"
                    disablePortal={true}
                    multiple
                    value={value3}
                    filterOptions={(options, params) => {
                      // <<<--- inject the Select All option
                      const filter = createFilterOptions();
                      const filtered = filter(options, params);
                      return [
                        { name: "Select All...", all: true },
                        ...filtered,
                      ];
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false,
                          },
                          {
                            name: "preventOverflow",
                            enabled: false,
                          },
                        ],
                      },
                    }}
                    sx={{
                      width: "100%",
                      height: "100px",
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",

                          padding: "0px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        "& fieldset": {
                          color: "whitesmoke",
                        },
                      },
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    options={currClinics}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      if (newValue.find((option) => option.all)) {
                        if (value3.length !== currClinics.length) {
                          setValue3([]);
                          const ids = [];
                          for (const clinics of currClinics) {
                            ids.push(clinics.id);
                          }
                          setGroupINfo((prev) => ({ ...prev, clinics: ids }));
                        } else {
                          setGroupINfo((prev) => ({ ...prev, clinics: [] }));
                        }
                        return setValue3(
                          value3.length === currClinics.length
                            ? []
                            : currClinics
                        );
                      }
                      setValue3(newValue);
                      const ids = [];
                      for (const clinics of newValue) {
                        ids.push(clinics.id);
                      }
                      setGroupINfo((prev) => ({ ...prev, clinics: ids }));
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} className=" font-poppins">
                        <Checkbox
                          icon={<CheckBoxIcon fontSize="small" />}
                          checkedIcon={
                            <CheckBoxOutlineBlank fontSize="small" />
                          }
                          style={{ marginRight: 8 }}
                          // checked={selected} <<<--- OLD
                          checked={
                            option.all
                              ? !(value3.length === currClinics.length)
                              : !selected
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Search"}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",

                            color: "rgb(51 65 85)",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full h-[300px]">
                  <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                    <p className="text-[18px] text-slate-300">Dictator</p>
                    <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                      <IoIosAdd size={25} className=" font-medium" />
                      Add
                    </Button>
                  </div>
                  <Autocomplete
                    id="doctor-select-demo"
                    disablePortal={true}
                    multiple
                    value={value4}
                    filterOptions={(options, params) => {
                      // <<<--- inject the Select All option
                      const filter = createFilterOptions();
                      const filtered = filter(options, params);
                      return [
                        { title: "Select All...", all: true },
                        ...filtered,
                      ];
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false,
                          },
                          {
                            name: "preventOverflow",
                            enabled: false,
                          },
                        ],
                      },
                    }}
                    sx={{
                      width: "100%",
                      height: "100px",
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",

                          padding: "0px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        "& fieldset": {
                          color: "whitesmoke",
                        },
                      },
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    options={dictators}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      if (newValue.find((option) => option.all)) {
                        if (value4.length !== dictators.length) {
                          const ids = [];
                          for (const dictator of dictators) {
                            ids.push(dictator.id);
                          }
                          setGroupINfo((prev) => ({
                            ...prev,
                            transcripters: ids,
                          }));
                        } else {
                          setGroupINfo((prev) => ({
                            ...prev,
                            transcripters: [],
                          }));
                        }

                        return setValue4(
                          value4.length === dictators.length ? [] : dictators
                        );
                      }
                      const ids = [];
                      for (const dictator of newValue) {
                        ids.push(dictator.id);
                      }
                      setGroupINfo((prev) => ({ ...prev, transcripters: ids }));
                      setValue4(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} className=" font-poppins">
                        <Checkbox
                          icon={<CheckBoxIcon fontSize="small" />}
                          checkedIcon={
                            <CheckBoxOutlineBlank fontSize="small" />
                          }
                          style={{ marginRight: 8 }}
                          // checked={selected} <<<--- OLD
                          checked={
                            option.all
                              ? !(value4.length === dictators.length)
                              : !selected
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Search"}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",

                            color: "rgb(51 65 85)",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full h-[300px]">
                  <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                    <p className="text-[18px] text-slate-300">Worktypes</p>
                    <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                      <IoIosAdd size={25} className=" font-medium" />
                      Add
                    </Button>
                  </div>
                  <Autocomplete
                    id="doctor-select-demo"
                    disablePortal={true}
                    multiple
                    value={value5}
                    filterOptions={(options, params) => {
                      // <<<--- inject the Select All option
                      const filter = createFilterOptions();
                      const filtered = filter(options, params);
                      return [
                        { title: "Select All...", all: true },
                        ...filtered,
                      ];
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false,
                          },
                          {
                            name: "preventOverflow",
                            enabled: false,
                          },
                        ],
                      },
                    }}
                    sx={{
                      width: "100%",
                      height: "100px",
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",

                          padding: "0px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        "& fieldset": {
                          color: "whitesmoke",
                        },
                      },
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    options={workTypes}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      if (newValue.find((option) => option.all)) {
                        if (value5.length !== workTypes.length) {
                          const ids = [];
                          for (const types of newValue) {
                            ids.push(types.id);
                          }
                          setGroupINfo((prev) => ({
                            ...prev,
                            work_types: ids,
                          }));
                        } else {
                          setGroupINfo((prev) => ({ ...prev, work_types: [] }));
                        }
                        return setValue5(
                          value5.length === workTypes.length ? [] : workTypes
                        );
                      }
                      const ids = [];
                      for (const types of workTypes) {
                        ids.push(types.id);
                      }
                      console.log(ids);
                      setGroupINfo((prev) => ({ ...prev, work_types: ids }));
                      setValue5(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} className=" font-poppins">
                        <Checkbox
                          icon={<CheckBoxIcon fontSize="small" />}
                          checkedIcon={
                            <CheckBoxOutlineBlank fontSize="small" />
                          }
                          style={{ marginRight: 8 }}
                          // checked={selected} <<<--- OLD
                          checked={
                            option.all
                              ? !(value5.length === workTypes.length)
                              : !selected
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Search"}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",

                            color: "rgb(51 65 85)",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex gap-2 lg:flex-row md:flex-row flex-col justify-end mt-4">
                <Button
                  onClick={(e) => createGroup(e)}
                  className=" shadow-[inset_-2px_1px_10px_rgb(203,213,225)] flex items-center justify-center py-6 rounded-[1.2rem] "
                >
                  <div className="flex gap-1 items-center text-slate-700 font-poppins ">
                    <IoMdCreate size={22} className="" />
                    <p className="text-[16px]">Create</p>
                  </div>
                </Button>
                <Button
                  onClick={() => setCreatePool(false)}
                  className="shadow-[inset_-2px_1px_15px_#f21d61]  flex items-center justify-center py-6 rounded-[1.2rem] "
                >
                  <div className="flex gap-1 items-center text-slate-700 font-poppins ">
                    <IoMdClose size={22} className="" />
                    <p className="text-[16px]">Close</p>
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {editPool && (
        <EditWorkPool
          record={id}
          workTypes={workTypes}
          dictators={dictators}
          hospitalList={hospitalList}
          setEditPool={setEditPool}
        />
      )}

      <ToastContainer />
    </section>
  );
};

export default Workpool;
