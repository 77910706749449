import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/StateContext";
import { Button } from "@mui/material";
import { ConfigProvider, Dropdown, Form, Table } from "antd";
import { ColorRing, Grid } from "react-loader-spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import bg from "../../assets/D2.png";
import Navbar from "../navbar";
import logo from "../../assets/MDLogoFull.png";
import SideBarFloat from "../SideBarFloat";
import { Link } from "react-router-dom";
import { URLconstants } from "../../api/constants";
import { IoMenu } from "react-icons/io5";
const TranscripterJobs = () => {
  const {
    setSection,
    open,
    section,
    loading,
    setLoading,
    downloadFile,
    currentPage,
    setCurrentPage,

    user,
  } = useContext(Context);

  const [form] = Form.useForm();
  const [assignedJobs, setAssigned] = useState([]);
  const [acceptingJobs, setAcceptJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);

  const column = (add, options) => {
    const columns = [
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        with: "8%",
        editable: true,
        render: (text, record) => (
          <p className=" font-poppins text-slate-700">{text}</p>
        ),
      },
      {
        title: "Tat",
        dataIndex: "tat",
        key: "tat",
        width: "",
        render: (item, record) => {
          const hour = record.tatElapsed;
          return record.transcript ? (
            <p
              className={`text-slate-200 p-3  text-center rounded-lg  ${
                hour < 8
                  ? "bg-red-600"
                  : hour >= 8 && hour < 16
                  ? "bg-yellow-400 text-slate-600"
                  : "bg-green-600"
              }`}
            >
              {record.transcript.tat}
            </p>
          ) : (
            <p className=" text-slate-700">N.A</p>
          );
        },
      },
      {
        title: "Tat Left",
        dataIndex: "tat_left",
        key: "tat_left",
        width: "",
        render: (item, record) => {
          const hours = Math.floor(record.tatElapsed);
          const millisecs = record.tatElapsed * 60 * 60 * 1000;
          const minutes = Math.floor(
            (millisecs % (60 * 60 * 1000)) / (1000 * 60)
          );
          return record.tatElapsed ? (
            <p className="text-slate-700 p-3 bg-slate-300  text-center rounded-lg">
              {record.tatElapsed <= 0 ? "00:00" : `${hours}:${minutes}`}
            </p>
          ) : (
            <p className=" text-slate-700">N.A</p>
          );
        },
      },

      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
        width: "",
        align: "center",
        render: (_, record) => {
          if (!record?.transcript?.duration) {
            return <p>--:--</p>;
          } else {
            const duration = record?.transcript?.duration;
            if (duration.includes("m")) {
              return <p>{duration}</p>;
            } else {
              const secs = Math.floor(duration / 1000);
              const minutes = Math.floor(secs / 60);
              const remsecs = secs % 60;
              return <p>{`${minutes}m${remsecs}s`}</p>;
            }
          }
        },
      },

      {
        title: "Job_ID",
        dataIndex: "job_id",
        key: "job_id",
        width: "",
        align: "center",
        render: (_, record) => (
          <p className=" text-slate-700">{record.transcript?.job_id}</p>
        ),
      },
      {
        title: "Doctor",
        dataIndex: "doctor_name",
        key: "doctor_name",
        width: "",
        align: "center",
        render: (_, record) => (
          <p className=" text-slate-700">{record.transcript?.doctor_name}</p>
        ),
      },
      {
        title: "File Name",
        dataIndex: "counter_id",
        key: "counter_id",
        width: "",
        align: "center",
        render: (_, record) => (
          <p className=" text-slate-700">{record.transcript?.counter_id}</p>
        ),
      },

      {
        title: "Assigned",
        dataIndex: "asssigned_by_admin",
        key: "asssigned_by_admin",
        width: "",
        align: "center",
        render: (item, record) => (
          <p className=" text-slate-700">{item ? "True" : "False"}</p>
        ),
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        with: "8%",
        render: (item) => item.split("T")[0],
      },
    ];
    add && columns.push(add);
    options && columns.splice(0, 0, options);

    return columns;
  };
  function getTATHours(hourVal) {
    const hourArr = hourVal.split(":");

    let finalValue =
      hourArr.length > 0 && Number(hourArr[0]) + Number(hourArr[1]) / 60;
    return finalValue;
  }
  const fetchCompletedJobs = async () => {
    try {
      setLoading(true);
      const { data, status } = await axios.get(
        `${URLconstants.BASE_URL}/api/jobs?populate=*&filters[assigned_transcripter][id]=${user.id}&filters[status][$eq]=IN_REVIEW`
      );
      if (status === 200) {
        const bodyWithKeys = data.data?.map((item, index) => {
          if (item.transcript) {
            let extraTime = getTATHours(item.transcript.tat);

            const currentTime = new Date().getTime();
            const createdTime = new Date(item.transcript.createdAt).getTime();

            const newTime =
              createdTime + extraTime * 1000 * 60 * 60 - currentTime;
            return {
              ...item,
              key: index,
              tatElapsed: newTime / (60 * 60 * 1000),
            };
          } else {
            return {
              ...item,
              key: index,
            };
          }
        });
        let finalOrder = bodyWithKeys.sort(
          (a, b) => a.tatElapsed - b.tatElapsed
        );
        setCompletedJobs(finalOrder);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(
        <p className=" font-poppins text-slate-700">Error Fetching Data!!!</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const fetchAssignedJobs = async () => {
    try {
      setLoading(true);
      const { data, status } = await axios.get(
        `${URLconstants.BASE_URL}/api/jobs?populate=*&filters[assigned_transcripter][id]=${user.id}&filters[status][$ne]=IN_REVIEW`
      );
      if (status === 200) {
        const bodyWithKeys = data.data?.map((item, index) => {
          if (item.transcript) {
            let extraTime = getTATHours(item.transcript.tat);

            const currentTime = new Date().getTime();
            const createdTime = new Date(item.transcript.createdAt).getTime();

            const newTime =
              createdTime + extraTime * 1000 * 60 * 60 - currentTime;
            return {
              ...item,
              key: index,
              tatElapsed: newTime / (60 * 60 * 1000),
            };
          } else {
            return {
              ...item,
              key: index,
            };
          }
        });
        let finalOrder = bodyWithKeys.sort(
          (a, b) => a.tatElapsed - b.tatElapsed
        );
        setAssigned(finalOrder);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(
        <p className=" font-poppins text-slate-700">Error Fetching Data!!!</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const fetchTranscripterJobs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${URLconstants.BASE_URL}/api/transcriber/jobs?&populate=*`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      console.log(data);
      const bodyWithKeys = data.transcriber_jobs?.auction_jobs?.map(
        (item, index) => {
          if (item.transcript) {
            let extraTime = getTATHours(item.transcript.tat);

            const currentTime = new Date().getTime();
            const createdTime = new Date(item.transcript.createdAt).getTime();

            const newTime =
              createdTime + extraTime * 1000 * 60 * 60 - currentTime;
            return {
              ...item,
              key: index,
              tatElapsed: newTime / (60 * 60 * 1000),
            };
          } else {
            return {
              ...item,
              key: index,
            };
          }
        }
      );
      let finalOrder = bodyWithKeys.sort((a, b) => a.tatElapsed - b.tatElapsed);

      setAcceptJobs(finalOrder);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(
        <p className=" font-poppins text-slate-700">Error Fetching Data!!!</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const handleListenRecording = (recrd) => {
    const newTab = window.open("", "_blank");
    newTab.document.write(`
      <html>
        <head>
          <title>Audio Player</title>
        </head>
        <style>
        body {
          font-family: 'Arial', sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          margin: 0;
          background-color:rgb(30,41,59);
        }
        audio {
          width: 40%;
        }
        div{
          display:flex;
          flex-direction:column;
          gap:5rem;
          align-items:center
        }
        img{
          width:40%
        }
      </style>
        <body>
          <div>
          <img src="${logo}" alt="" />
          <audio controls autoplay>
          <source src="${recrd}" type="audio/ogg">
          Your browser does not support the audio element.
          </audio>
          </div>
        </body>
      </html>
    `);
  };

  const acceptJobs = async (e, id) => {
    e.preventDefault();
    try {
      const { data, status } = await axios.post(
        `${URLconstants.BASE_URL}/api/transcriber/accept-job`,
        {
          job_id: Number(id),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (status === 200) {
        toast.success(
          <p className=" font-poppins text-slate-700">Accepted</p>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        fetchAssignedJobs();
        fetchTranscripterJobs();
      }
    } catch (error) {
      console.log(error);

      toast.error(
        <p className=" font-poppins text-slate-700">
          {error.response.data.error.message}
        </p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const completeJobs = async (e, id) => {
    e.preventDefault();
    try {
      const { data, status } = await axios.post(
        `${URLconstants.BASE_URL}/api/transcriber/complete-job`,
        {
          job_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (status === 200) {
        toast.success(<p className=" font-poppins text-slate-700">{data}</p>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchAssignedJobs();
        fetchTranscripterJobs();
        fetchCompletedJobs();
      }
    } catch (error) {
      console.log(error);

      toast.error(
        <p className=" font-poppins text-slate-700">{error.message}</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTranscripterJobs(page);
  };
  useEffect(() => {
    const fetchDataAndToggleLoading = async () => {
      try {
        await fetchAssignedJobs();
        await fetchTranscripterJobs();
        await fetchCompletedJobs();
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAndToggleLoading();
  }, [section]);
  // console.log(acceptingJobs);
  return (
    <>
      <Navbar />
      <SideBarFloat />
      <section className="scroll-smooth h-screen  bg-[#f0f0f0ea] font-poppins relative overflow-clip">
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>

        <div
          className={` px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100]`}
        >
          <div
            className={`flex pt-10 pb-10 mt-[4.5rem] ${
              open ? "lg:w-7/12 md:w-7/12" : ""
            } justify-between lg:items-center md:items-end items-start lg:flex-row md:flex-row flex-col relative`}
          >
            <div className="flex gap-4 md:items-center lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
              <div>
                <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                  Jobs
                </p>
                <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
              </div>
              <Link to="/home">
                <Button
                  onClick={() => setSection("dash")}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <p className="lg:text-[18px] text-slate-500 font-poppins">
                    Home
                  </p>
                </Button>
              </Link>
            </div>
          </div>

          <div className=" flex flex-col">
            <div className="flex gap-2">
              <p className="text-[24px] m-0">Accept Jobs :</p>
              <Button
                onClick={() => fetchTranscripterJobs()}
                sx={{
                  textTransform: "none",
                  border: "0",
                }}
              >
                <p className=" font-poppins text-slate-700 text-[16px]">
                  Reload
                </p>
              </Button>
            </div>

            <div className="lg:py-8 md:py-8 pt-2 pb-[6rem] flex items-center justify-center">
              {loading ? (
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#27284a",
                    "#3c4a74",
                    "#4f6ea0",
                    "#5f96cd",
                    "#6ec0fa",
                  ]}
                />
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerBorderRadius: 20,
                        headerBg: "#cbd5e1",
                        headerSortHoverBg: "",
                      },
                    },
                  }}
                >
                  <Form form={form} component={false}>
                    <Table
                      dataSource={acceptingJobs}
                      columns={column(
                        {
                          title: "Accept",
                          dataIndex: "accept",
                          key: "accept",
                          render: (_, record) => (
                            <div>
                              <Button
                                variant="outlined"
                                sx={{
                                  textTransform: "none",
                                  width: "100%",
                                  display: "flex",
                                  padding: "8px 0",
                                  borderRadius: "12px",
                                  justifyContent: "center",
                                }}
                                onClick={(e) =>
                                  acceptJobs(e, record?.transcript?.job_id)
                                }
                              >
                                <p className="font-poppins">Accept</p>
                              </Button>
                            </div>
                          ),
                        },
                        null
                      )}
                      className="rounded-[1.5rem]   text-slate-800 shadow-lg shadow-slate-300  "
                      pagination={{
                        current: currentPage,
                        pageSize: acceptingJobs?.length,
                        showSizeChanger: false,
                        total: acceptingJobs?.length,
                        // onChange: handlePageChange,
                        style: {
                          padding: "0 12px",
                          fontFamily: "Poppins",
                        },
                      }}
                      scroll={{ y: 450, x: "calc(650px + 50%)" }}
                    />
                  </Form>
                </ConfigProvider>
              )}
            </div>
          </div>

          <div className=" flex flex-col">
            <p className="text-[24px] m-0">Assigned Jobs :</p>

            <div className="lg:py-8 md:py-8 pt-2 pb-[6rem] flex items-center justify-center">
              {loading ? (
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#27284a",
                    "#3c4a74",
                    "#4f6ea0",
                    "#5f96cd",
                    "#6ec0fa",
                  ]}
                />
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerBorderRadius: 20,
                        headerBg: "#cbd5e1",
                        headerSortHoverBg: "",
                      },
                    },
                  }}
                >
                  <Form form={form} component={false}>
                    <Table
                      dataSource={assignedJobs}
                      columns={column(
                        {
                          title: "Complete",
                          dataIndex: "complete",
                          key: "complete",
                          render: (_, record) => (
                            <div>
                              <Button
                                variant="outlined"
                                sx={{
                                  textTransform: "none",
                                  width: "100%",
                                  display: "flex",
                                  padding: "8px 0",
                                  borderRadius: "12px",
                                  justifyContent: "center",
                                }}
                                onClick={(e) =>
                                  completeJobs(e, record?.transcript?.job_id)
                                }
                              >
                                <p className="font-poppins">Complete</p>
                              </Button>
                            </div>
                          ),
                        },
                        {
                          title: "",
                          dataIndex: "options",
                          key: "options",
                          width: "70px",
                          align: "center",
                          render: (item, records) => {
                            const items = [
                              {
                                key: "1",
                                label: (
                                  <div
                                    className="py-2 px-2"
                                    onClick={async () => {
                                      if (records.transcript.recording_url) {
                                        try {
                                          const { data, status } =
                                            await axios.post(
                                              `${URLconstants.BASE_URL_FARM}/doctor/change-audio-format`,
                                              {
                                                audio_url:
                                                  records.transcript
                                                    .recording_url,
                                                job_id:
                                                  records.transcript.job_id,
                                                audio_format: "ogg",
                                              },
                                              {
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                },
                                              }
                                            );
                                          if (status === 200) {
                                            handleListenRecording(
                                              data?.audio_url
                                            );
                                          }
                                        } catch (error) {
                                          console.log(error);
                                          toast.error(
                                            <p className=" text-slate-700 font-poppins">
                                              {error.message}
                                            </p>,
                                            {
                                              position: "bottom-center",
                                              autoClose: 2000,
                                              hideProgressBar: false,
                                              closeOnClick: true,
                                              pauseOnHover: true,
                                              draggable: true,
                                              progress: undefined,
                                            }
                                          );
                                        }
                                      } else {
                                        toast.info(
                                          <p className=" text-slate-700 font-poppins">
                                            No recording present!!
                                          </p>,
                                          {
                                            position: "bottom-center",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <p className="font-poppins text-slate-700">
                                      Listen Recording
                                    </p>
                                  </div>
                                ),
                              },

                              {
                                key: "2",
                                label: (
                                  <div
                                    className="py-2 px-2"
                                    onClick={async (e) => {
                                      if (records.transcript.recording_url) {
                                        const url = decodeURIComponent(
                                          records.recording_url
                                        );
                                        const fileName = url.split("/").pop(); // Get the last segment after the last '/'
                                        const desiredPortion =
                                          fileName.split(".m4a")[0]; // Remove the '.m4a' extension
                                        const finalName = `${
                                          records.transcript?.doctor?.hospital
                                            ?.name
                                        }_${
                                          records.transcript?.recording_time.split(
                                            "T"
                                          )[0]
                                        }_${desiredPortion}`;

                                        try {
                                          const { data, status } =
                                            await axios.post(
                                              `${URLconstants.BASE_URL_FARM}/doctor/change-audio-format`,
                                              {
                                                audio_url:
                                                  records.transcript
                                                    .recording_url,
                                                job_id:
                                                  records.transcript.job_id,
                                                audio_format: "ogg",
                                              },
                                              {
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                },
                                              }
                                            );
                                          if (status === 200) {
                                            downloadFile(
                                              e,
                                              data.audio_url,
                                              `${finalName}.ogg`
                                            );
                                          }
                                        } catch (error) {
                                          console.log(error);
                                          toast.error(
                                            <p className=" text-slate-700 font-poppins">
                                              {error.message}
                                            </p>,
                                            {
                                              position: "bottom-center",
                                              autoClose: 2000,
                                              hideProgressBar: false,
                                              closeOnClick: true,
                                              pauseOnHover: true,
                                              draggable: true,
                                              progress: undefined,
                                            }
                                          );
                                        }
                                      } else {
                                        toast.info(
                                          <p className=" text-slate-700 font-poppins">
                                            No recording present!!
                                          </p>,
                                          {
                                            position: "bottom-center",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <p className=" font-poppins text-slate-700">
                                      Download Recording
                                    </p>
                                  </div>
                                ),
                              },
                            ];
                            return (
                              <Dropdown
                                menu={{
                                  items,
                                }}
                              >
                                <Button
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IoMenu size={20} className="" />
                                </Button>
                              </Dropdown>
                            );
                          },
                        }
                      )}
                      className="rounded-[1.5rem]   text-slate-800 shadow-lg shadow-slate-300  "
                      pagination={{
                        current: currentPage,
                        pageSize: assignedJobs?.length,
                        showSizeChanger: false,
                        total: assignedJobs?.length,
                        // onChange: handlePageChange,
                        style: {
                          padding: "0 12px",
                          fontFamily: "Poppins",
                        },
                      }}
                      scroll={{ y: 450, x: "calc(700px + 50%)" }}
                    />
                  </Form>
                </ConfigProvider>
              )}
            </div>
          </div>
          <div className=" flex flex-col">
            <p className="text-[24px] m-0">Completed Jobs :</p>

            <div className="lg:py-8 md:py-8 pt-2 pb-[6rem] flex items-center justify-center">
              {loading ? (
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#27284a",
                    "#3c4a74",
                    "#4f6ea0",
                    "#5f96cd",
                    "#6ec0fa",
                  ]}
                />
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerBorderRadius: 20,
                        headerBg: "#cbd5e1",
                        headerSortHoverBg: "",
                      },
                    },
                  }}
                >
                  <Form form={form} component={false}>
                    <Table
                      dataSource={completedJobs}
                      columns={column(null, null)}
                      className="rounded-[1.5rem]   text-slate-800 shadow-lg shadow-slate-300  "
                      pagination={{
                        current: currentPage,
                        pageSize: completedJobs?.length,
                        showSizeChanger: false,
                        total: completedJobs?.length,
                        // onChange: handlePageChange,
                        style: {
                          padding: "0 12px",
                          fontFamily: "Poppins",
                        },
                      }}
                      scroll={{ y: 450, x: "calc(700px + 50%)" }}
                    />
                  </Form>
                </ConfigProvider>
              )}
            </div>
          </div>
        </div>

        <ToastContainer />
      </section>
    </>
  );
};

export default TranscripterJobs;
