import React, { useContext, useState, useRef } from "react";
import { Context } from "../../context/StateContext";
import { Button } from "antd";
import { FaFileAlt } from "react-icons/fa";
import { FaFileDownload } from "react-icons/fa";
import AudioPlayer from "react-audio-player";

const AudioVizualize = () => {
  const { pdf, recrd } = useContext(Context);
  const [isDownloading, setDownloading] = useState(false);

  const handleDownload = () => {
    setDownloading(true);
    fetch(recrd)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "audio-file.mp3"; // Change the filename as needed
        document.body.appendChild(a);
        a.click();
        a.remove();
        setDownloading(false);
      })
      .catch((error) => {
        console.error("Error downloading audio:", error);
        setDownloading(false);
      });
  };

  return (
    <div className="font-poppins flex flex-col gap-8 ">
      <div className="border border-slate-500 px-6 flex flex-col gap-4 py-8 rounded-[1.5rem]">
        <div className="flex items-center gap-2">
          <p className="font-medium text-[18px]">Click to View Report :</p>
          {pdf ? (
            <small className="text-blue-600">
              {pdf.toString().substring(0, 25)}...
            </small>
          ) : (
            <small>No file selected</small>
          )}
        </div>
        <Button
          className="bg-[#27284A] h-[60px] rounded-[1.2rem] text-slate-200 font-poppins text-[16px] tracking-wide"
          onClick={() => (pdf ? window.open(pdf, "_blank") : {})}
        >
          <div className="flex gap-2 justify-center">
            <p>View Report</p>
            <FaFileAlt className="text-slate-200" size={20} />
          </div>
        </Button>
      </div>

      <div className="flex gap-4 items-center ">
        <AudioPlayer
          className="border h-[60px] py-4 w-full border-slate-400 rounded-[1.5rem]"
          src={recrd}
          autoPlay
          controls
        />

        <Button
          className="p-4 flex items-center justify-center rounded-[50%] h-[60px] w-[60px]"
          onClick={handleDownload}
          disabled={isDownloading}
        >
          <FaFileDownload color="#27284A" size={25} />
        </Button>
      </div>
    </div>
  );
};

export default AudioVizualize;
