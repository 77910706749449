import React from 'react'
import noise from '../assets/noise.png'
import { SiGooglemaps } from "react-icons/si";
import { FaHome } from "react-icons/fa";
import { IoMail ,IoCall} from "react-icons/io5";
import {Button} from 'antd'
import { Link } from 'react-router-dom';
import { Fab } from '@mui/material';
const Contact = () => {
  return (
    <>
  
    <section className='bg-[#f0f0f0ea] font-poppins w-full h-screen overflow-y-scroll overflow-x-clip  '>
        <div className='relative bg-[url(https://images.pexels.com/photos/789822/pexels-photo-789822.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)] w-full h-[65vh]   bg-no-repeat bg-center bg-cover'>
            <div className='absolute h-[65vh] w-full'>
              <img src={noise} alt="" className='w-full h-full' />
            </div>
            <div className='relative lg:top-[25%] w-full py-2 px-3 bg-[#0000002b] lg:left-[60%] md:left-[35%] md:top-[45%] left-[10%] top-[10%]'>
              <p className='lg:text-[94px] text-slate-100  font-black md:text-[80px] text-[48px]'>Contact Us</p>
              <div className='w-[15%] border-[2px] rounded-[3rem]  border-slate-200  ml-2 mb-2'/>
            </div>
        </div>
        <div className='flex lg:flex-row flex-col  lg:gap-2 gap-10 my-12  mx-auto h-full lg:w-9/12 md:w-10/12 w-11/12 '>
                  <div className='bg-[#575a8c] lg:w-4/5 w-full rounded-xl text-slate-300 px-8 lg:py-0 py-20 h-full flex flex-col justify-center gap-6'>
                      <div className='flex gap-2 items-center'>
                        <SiGooglemaps size={30} className='text-slate-300'/>
                        <p className='text-[32px] tracking-wide font-medium'>Map Our Office</p>
                      </div>
                      <p className='text-[24px] font-bold'>MDofficeManager</p>
                      <div>
                        <p>Address</p>
                      </div>
                      <div className='flex flex-col gap-4'>
                          <div className='flex gap-3 items-center'>
                              <IoMail size={28} className='text-slate-300'/>
                              <p className='text-slate-300 text-[18px] md:text-[24px] lg:text-[26px]'>Info@MDofficeManager.com</p>
                          </div>
                          <div className='flex gap-3 items-center'>
                            <IoCall size={28} className='text-slate-300'/>
                            <p className='text-slate-300 text-[18px] md:text-[24px] lg:text-[26px]'>123-456-7890</p>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col  px-6 lg:w-4/5 w-full'>
                  <div className='flex flex-col'>
                    <p className='text-slate-800 text-[36px] font-extrabold'>Get in touch</p>
                    <small className='text-slate-600 text-[18px]'>Leave your message and we'll get back to you shortly</small>
                  </div>  
                  <form action="" className='flex flex-col gap-4 py-2 mt-6 '>
                    <div className='flex lg:flex-row flex-col gap-4 w-full'>
                      <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor="" className='text-slate-800 font-semibold text-[18px]'>Name</label>
                        <input type="text" className='outline-none text-slate-800 font-medium indent-3 py-3 rounded-lg' placeholder='Name' required/>
                      </div>
                      <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor="" className='text-slate-800 font-semibold text-[18px]'>Email</label>
                        <input type="text" className='outline-none text-slate-800 font-medium indent-3 py-3 rounded-lg' placeholder='abc@gmail.com' required/>
                      </div>
                      

                    </div>
                    <div className='flex lg:flex-row flex-col gap-4 w-full '>
                      <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor="" className='text-slate-800 font-semibold text-[18px]'>Phone</label>
                        <input type="text" className='outline-none text-slate-800 font-medium indent-3 py-3 rounded-lg' placeholder='xxx-xxx-xxxx' required/>
                      </div>
                      <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor="" className='text-slate-800 font-semibold text-[18px]'>Facility</label>
                        <input type="text" className='outline-none text-slate-800 font-medium indent-3 py-3 rounded-lg' placeholder='Name' required/>
                      </div>
                      

                    </div>
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="" className='text-slate-800 font-semibold text-[18px]'>Message</label>
                        <textarea type="text" rows="" className='outline-none text-slate-800 font-medium indent-3 py-4 rounded-lg' placeholder='Type your message' required/>
                      </div>

                      <div className='w-full flex justify-center'>
                      <Button className='font-poppins w-[150px] px-4 h-[45px] text-[18px] bg-slate-600 text-slate-200'>Submit</Button>
                      </div>
                  </form>   
                  
                  <div className='mt-8'>
                    <ul className='flex flex-col gap-1 '>
                      <li className='text-[16px]'>In order to ensure you receive a quick reply, please send an email to <a className='text-blue-500' href="mailto:Sales@MDofficeManager.com">Sales@MDofficeManager.com</a>.</li>
                      <li className='text-[16px]'>For partnerships, and general inquiries, please email us at:<a className='text-blue-500'  href="mailto:Info@MDofficeManager.com">Info@MDofficeManager.com</a>.</li>
                      <li className='text-[16px]'>Interested in joining our team? Email us at<a className='text-blue-500' href="mailto:jobs@MDofficeManager.com">jobs@MDofficeManager.com</a>.</li>
                    </ul>
                  </div>
                  <div className='mt-4 mb-8'>
                    <small>***By submitting my email address above, I acknowledge that MDofficeManager may use my information as described in its Privacy Policy.</small>
                  </div>

                 </div>
        </div>
    </section>
    <Link to="/home">
    <Fab  sx={{
           position:"absolute",
           bottom:"1.5rem",
           right:"2rem",
           background:"#27284A"
          }}><FaHome color='whitesmoke' size={25}/></Fab>
    </Link>

    </>
  )
}

export default Contact