import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../context/StateContext";
import axios from "axios";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Grid } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URLconstants } from "../../api/constants";

const AppointmentForm = () => {
  const {
    setOpen,
    fetchData,
    loading,
    setLoading,
    fetchHospital,
    hospitalList,
    fetchDoctor,
    doctorList,
  } = useContext(Context);
  const [file, setFile] = useState("");
  const [clinicid, setClinicId] = useState("");
  const [docid, setDocId] = useState("");
  const [clinicList, setClinicList] = useState([]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const requestBody = {
      data: Object.fromEntries(formData),
    };
    requestBody.data["doctor"] = [docid];
    requestBody.data["clinic"] = [clinicid];
    requestBody.data["patient_dob"] = new Date(
      requestBody.data["patient_dob"]
    ).toISOString();
    requestBody.data["start_date"] = new Date(
      requestBody.data["start_date"]
    ).toISOString();
    requestBody.data["time"] = requestBody.data["time"] + ":00";
    console.log(requestBody);
    try {
      setLoading(true);
      const response = await axios.post(
        `${URLconstants.BASE_URL}/api/appoints`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      if (response.status === 200) {
        await fetchData(1);
      }
      console.log(response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("appointmentDetail", file);
      formData.append("doctor_id", docid);
      const response = await axios.post(
        `${URLconstants.BASE_URL}/api/upload-appointment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      if (response.status === 200) {
        await fetchData(1);
      }
    } catch (error) {
      console.log(error.response.data.error.message);
      toast.error(
        <p className="font-poppins text-slate-700">
          {error.response.data.error.message}
        </p>,
        {
          position: "bottom-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoading(false);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchHospital();
  }, []);

  return (
    <section className="relative">
      {loading && (
        <div className="absolute top-[50%] left-[50%]   -translate-x-1/2 -translate-y-1/2 z-[1100]">
          <Grid
            visible={true}
            height="80"
            width="80"
            color="#4A57EC"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-wrapper"
          />
        </div>
      )}

      <div
        className={` w-full py-8 mb-4 px-8 bg-slate-800 rounded-[2rem] ${
          loading && "opacity-[0.4]"
        }`}
      >
        <form
          action=""
          onSubmit={(e) => handleFileSubmit(e)}
          className="flex flex-col gap-4 font-poppins"
        >
          <p className="text-[24px] text-slate-200">Upload File</p>
          <div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4 ">
              <div className="flex flex-col gap-4 w-full">
                <label
                  for="file"
                  className="text-slate-300 tracking-wide text-[18px]"
                >
                  File
                </label>
                <input
                  type="file"
                  required
                  className="px-4 py-2 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 flex justify-center w-full"
                  placeholder="Id"
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
              <div className="flex flex-col gap-4 w-full">
                <label
                  for="file"
                  className="text-slate-300 tracking-wide text-[18px]"
                >
                  Select Hospital
                </label>

                <Autocomplete
                  disablePortal
                  id="doctor-select-demo"
                  sx={{
                    fontFamily: "poppins",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgb(71,85,105)",
                        borderRadius: "1.2rem",
                        padding: "1rem 0.75rem",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976D2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2",
                      },
                    },
                    "& .MuiIconButton-root ": {
                      color: "whitesmoke",
                      position: "relative",
                      zIndex: "100",
                    },
                  }}
                  options={hospitalList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value) {
                      fetchDoctor(value.id);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <p className=" font-poppins text-slate-700 indent-2">
                        {option.name}
                      </p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                          Hospitals
                        </p>
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontFamily: "poppins",

                          color: "#f0f0f0d2",
                        },
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-4  w-full">
                <label
                  for="file"
                  className="text-slate-300 tracking-wide text-[18px]"
                >
                  Select Doctor
                </label>

                <Autocomplete
                  disablePortal
                  id="doctor-select-demo"
                  sx={{
                    fontFamily: "poppins",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgb(71,85,105)",
                        borderRadius: "1.2rem",
                        padding: "1rem 0.75rem",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976D2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2",
                      },
                    },
                    "& .MuiIconButton-root ": {
                      color: "whitesmoke",
                      position: "relative",
                      zIndex: "100",
                    },
                  }}
                  options={doctorList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value) {
                      setDocId(value.id);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <p className=" font-poppins text-slate-700 indent-2">
                        {option.name}
                      </p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                          Doctors
                        </p>
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontFamily: "poppins",

                          color: "#f0f0f0d2",
                        },
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <Button
              sx={{
                borderRadius: "1.3rem",
                padding: "10px 2rem",
                color: "#f0f0f0fe",
                marginTop: "10px",
                boxShadow: "inset -2px 1px 10px #f0f0f0fe",
                height: "50px",
                width: "150px",
              }}
              type="submit"
            >
              <p className="font-poppins text-[16px]">Submit</p>
            </Button>
          </div>
        </form>
        <div className="flex items-center justify-center gap-3 my-8">
          <div className=" border-dashed border border-slate-200 w-[40%]"></div>
          <p className="font-poppins text-slate-200 text-[16px]">or</p>
          <div className=" border-dashed border border-slate-200 w-[40%]"></div>
        </div>
        <form
          action=""
          onSubmit={(e) => handleSubmit(e)}
          className="flex flex-col gap-4 font-poppins"
        >
          <div className="flex gap-2">
            <p className="text-[24px] text-slate-200">Patient Info</p>
            <div className="border my-6 border-slate-200 rounded-[50%] w-[5%]"></div>
          </div>

          <div className="flex  flex-col gap-3 lg:w-4/5 ">
            <div className="flex lg:flex-row md:flex-row flex-col items-center gap-4 w-full">
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Unique_Id
                </label>
                <input
                  type="text"
                  required
                  name="unique_id"
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                  placeholder="Id"
                />
              </div>
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Patient Name
                </label>
                <input
                  type="text"
                  required
                  name="patient_name"
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                  placeholder="Patient Name"
                />
              </div>
            </div>
            <div className="flex  lg:flex-row md:flex-row flex-col items-center gap-4">
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Date-of-Birth
                </label>
                <input
                  type="date"
                  required
                  name="patient_dob"
                  className="px-4 py-3 rounded-[1.2rem] outline-none font-poppins text-[#f0f0f0d2] bg-slate-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                  placeholder="Date-of-Birth"
                />
              </div>
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Description
                </label>
                <input
                  type="text"
                  required
                  name="description"
                  className="px-4 py-3 rounded-[1.2rem] outline-none font-poppins text-[#f0f0f0d2] bg-slate-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                  placeholder="Description"
                />
              </div>
            </div>
          </div>

          <div className="flex gap-2 mt-2">
            <p className="text-[24px] text-slate-200">Doctor Info</p>
            <div className="border my-6 border-slate-200 rounded-[50%] w-[5%]"></div>
          </div>

          <div className="flex  flex-col gap-3 lg:w-4/5">
            <div className="flex  lg:flex-row md:flex-row flex-col items-center gap-4">
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Start Date
                </label>
                <input
                  type="date"
                  required
                  name="start_date"
                  className="px-4 py-3 rounded-[1.2rem] outline-none font-poppins text-[#f0f0f0d2] bg-slate-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600"
                  placeholder="Date-of-Birth"
                />
              </div>
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Time
                </label>
                <input
                  type="time"
                  required
                  name="time"
                  className="px-4 py-3 rounded-[1.2rem] outline-none font-poppins text-[#f0f0f0d2] bg-slate-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                  placeholder="Description"
                />
              </div>
            </div>

            <div className="flex lg:flex-row md:flex-row flex-col items-center gap-4 w-full">
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Hospital
                </label>
                <Autocomplete
                  disablePortal
                  id="doctor-select-demo"
                  sx={{
                    fontFamily: "poppins",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgb(71,85,105)",
                        borderRadius: "1.2rem",
                        padding: "1rem 0.75rem",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976D2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2",
                      },
                    },
                    "& .MuiIconButton-root ": {
                      color: "whitesmoke",
                      position: "relative",
                      zIndex: "100",
                    },
                  }}
                  options={hospitalList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value) {
                      fetchDoctor(value.id);
                      setClinicList(value.clinics);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <p className=" font-poppins text-slate-700 indent-2">
                        {option.name}
                      </p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                          Hospitals
                        </p>
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontFamily: "poppins",

                          color: "#f0f0f0d2",
                        },
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Doctor
                </label>

                <Autocomplete
                  disablePortal
                  id="doctor-select-demo"
                  sx={{
                    fontFamily: "poppins",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgb(71,85,105)",
                        borderRadius: "1.2rem",
                        padding: "1rem 0.75rem",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976D2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2",
                      },
                    },
                    "& .MuiIconButton-root ": {
                      color: "whitesmoke",
                      position: "relative",
                      zIndex: "100",
                    },
                  }}
                  options={doctorList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value) {
                      setDocId(value.id);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <p className=" font-poppins text-slate-700 indent-2">
                        {option.name}
                      </p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                          Doctors
                        </p>
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontFamily: "poppins",

                          color: "#f0f0f0d2",
                        },
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Clinic
                </label>

                <Autocomplete
                  disablePortal
                  id="doctor-select-demo"
                  sx={{
                    fontFamily: "poppins",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgb(71,85,105)",
                        borderRadius: "1.2rem",
                        padding: "1rem 0.75rem",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976D2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2",
                      },
                    },
                    "& .MuiIconButton-root ": {
                      color: "whitesmoke",
                      position: "relative",
                      zIndex: "100",
                    },
                  }}
                  options={clinicList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value) {
                      setClinicId(value.id);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <p className=" font-poppins text-slate-700 indent-2">
                        {option.name}
                      </p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                          Clinics
                        </p>
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontFamily: "poppins",

                          color: "#f0f0f0d2",
                        },
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-3  w-1/2">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Status
              </label>
              <input
                type="text"
                required
                name="status"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                placeholder="Status"
              />
            </div>
          </div>

          <div className="flex gap-3 lg:flex-row md:flex-col flex-col lg:items-end  items-start mt-2">
            <Button
              sx={{
                borderRadius: "1.3rem",
                padding: "10px 2rem",
                color: "#f0f0f0fe",
                marginBottom: "4px",
                boxShadow: "inset -2px 1px 10px #f0f0f0fe",
              }}
              type="submit"
            >
              <p className="font-poppins text-[16px]">Submit</p>
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AppointmentForm;
