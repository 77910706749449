import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../context/StateContext";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { Grid } from "react-loader-spinner";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { URLconstants } from "../../api/constants";

const AddForm = () => {
  const {
    setOpen,
    section,
    user_type,
    icons,
    abvs,
    fetchData,
    loading,
    setLoading,
    setCurrentPage,
  } = useContext(Context);
  const user_token = localStorage.getItem("userToken").toString();
  const [showPassword, setShowPassword] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [id, setId] = useState("");
  const [itemList, setList] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let fields = {
        name: e.target[0].value,
        email_id: e.target[1].value,
        address: e.target[2].value,
        contact_number: e.target[3].value,
        email: e.target[4].value,
        orgId: user_type === 0 ? id : user?.id,
        password: user_type === 0 ? e.target[9].value : e.target[5].value,
      };
      if (section === "clinics") {
        fields = {
          name: e.target[0].value,
          email_id: e.target[1].value,
          address: e.target[2].value,
          contact_number: e.target[3].value,
          email: e.target[4].value,
          hospitalId: user_type <= 1 ? id : user_type === 2 && user?.id,
          password: user_type <= 1 ? e.target[9].value : e.target[5].value,
        };
      }
      if (section === "doctors") {
        fields = {
          name: e.target[0].value,
          email_id: e.target[1].value,
          address: e.target[2].value,
          contact_number: e.target[3].value,
          email: e.target[4].value,
          hospitalId: user_type <= 1 ? id : user_type === 2 && user?.id,
          qualifications:
            user_type <= 1 ? e.target[9].value : e.target[5].value,
          password: user_type <= 1 ? e.target[10].value : e.target[6].value,
        };
      }
      if (section === "staffs") {
        fields = {
          name: e.target[0].value,
          email_id: e.target[1].value,
          address: e.target[2].value,
          contact_number: e.target[3].value,
          email: e.target[4].value,
          hospitalId: user_type <= 2 ? id : user_type === 3 && user?.id,
          password: user_type <= 2 ? e.target[9].value : e.target[5].value,
        };
        console.log(fields);
      }
      if (section === "nurses") {
        fields = {
          name: e.target[0].value,
          email_id: e.target[1].value,
          address: e.target[2].value,
          contact_number: e.target[3].value,
          email: e.target[4].value,
          hospitalId:
            user_type < 2 ? id : user_type === "2" && user?.hospital_id,
          password: user_type <= 2 ? e.target[9].value : e.target[5].value,
        };
        console.log(fields);
      }

      if (e.target[3].value && fields.contact_number.length != 10) {
        toast.error("Contact Number not valid!!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(true);
        const response = await axios.post(
          `${URLconstants.BASE_URL}/api/${section}`,
          JSON.stringify(fields),
          {
            headers: {
              Authorization: `Bearer ${user_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setCurrentPage(1);
          await fetchData(1);
        }
        setOpen(false);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchOptions = async () => {
    try {
      const { data } = await axios.get(
        `${URLconstants.BASE_URL}/api/${abvs[section]}?&populate=*&filters[visibility]=true`
      );
      const total = data.meta.pagination.total;
      const pages = Math.ceil(total / 100);
      for (let i = 1; i <= pages; i++) {
        const { data } = await axios.get(
          `${URLconstants.BASE_URL}/api/${abvs[section]}?&populate=*&filters[visibility]=true&pagination[pageSize]=100&pagination[page]=${i}`
        );
        setList((prev) => [...prev, ...data.data]);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    if (section !== "organisations") {
      fetchOptions();
    }
  }, []);

  const name =
    abvs[section][0].toUpperCase() +
    abvs[section].slice(1, abvs[section].length - 1);
  const names2 = abvs[section][0].toUpperCase() + abvs[section].slice(1);

  return (
    <section className="relative">
      {loading && (
        <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
          <Grid
            visible={true}
            height="80"
            width="80"
            color="#4A57EC"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-wrapper"
          />
        </div>
      )}

      <div
        className={`h-fit  mb-4 lg:w-[55vw] md:w-[75vw] w-fit py-10 px-8 bg-slate-800 rounded-[2rem] ${
          loading && "opacity-[0.4]"
        }`}
      >
        <form
          action=""
          onSubmit={(e) => handleSubmit(e)}
          className="flex flex-col gap-4 font-poppins"
        >
          <div className="flex  flex-col gap-3">
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Name
              </label>
              <input
                type="text"
                name="name"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600"
                required
                placeholder="Name "
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                UserName
              </label>
              <input
                type="text"
                name="email_id"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600"
                required
                placeholder="UserName "
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Address
              </label>
              <input
                type="text"
                name="address"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                placeholder="Address"
              />
            </div>

            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Contact_No.
              </label>
              <input
                type="number"
                name="number"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 "
                placeholder="Number "
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 dark:[color-scheme:dark]"
                placeholder="Email"
              />
            </div>
            {((user_type === 0 && section === "hospitals") ||
              (user_type <= 1 &&
                (section === "clinics" || section === "doctors")) ||
              (user_type <= 2 &&
                (section === "nurses" || section === "staffs"))) && (
              <div className="flex  flex-col gap-3">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  {name} Id
                </label>
                <Autocomplete
                  disablePortal
                  id="doctor-select-demo"
                  sx={{
                    width: "100%",
                    fontFamily: "poppins",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgb(71,85,105)",
                        borderRadius: "1.2rem",
                        padding: "0px",
                        background: "rgb(36,49,67)",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976D2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2",
                      },
                    },
                    "& .MuiIconButton-root ": {
                      "& fieldset": {
                        color: "whitesmoke",
                      },
                    },
                    "& .MuiIconButton-root ": {
                      color: "whitesmoke",
                      position: "relative",
                      zIndex: "100",
                    },
                  }}
                  options={itemList ? itemList : []}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, value) => {
                    event.stopPropagation();
                    if (value) {
                      if (section === "nurses") {
                        setId(value.hospital_id);
                      } else {
                        setId(value.id);
                      }
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <div className="flex gap-1 items-center">
                        {section === "doctors"
                          ? icons["hospitals"]
                          : icons[abvs[section]]}
                        <p className="font-poppins text-[16px] text-slate-700">
                          {option?.name}
                        </p>
                      </div>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={names2}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontFamily: "poppins",
                          background: "rgb(36,49,67)",
                          color: "#f0f0f0d2",
                          position: "relative",
                          zIndex: "100",
                          margin: "0px",
                          padding: "0.5rem",
                          borderRadius: "0.75rem",
                        },
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
            )}
            {section === "doctors" && (
              <div className="flex flex-col gap-2">
                <label htmlFor="" className="text-slate-300 text-[18px] ">
                  Qualifications
                </label>
                <input
                  type="text"
                  name="password"
                  required
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 dark:[color-scheme:dark] "
                  placeholder="Qualifications"
                />
              </div>
            )}

            <div className="flex flex-col gap-2">
              <label htmlFor="" className="text-slate-300 text-[18px] ">
                Password
              </label>
              <div className="flex w-full gap-3">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  required
                  className="px-4 py-3 w-full rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 dark:[color-scheme:dark] "
                  placeholder="Password"
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff className="text-slate-200" />
                  ) : (
                    <Visibility className="text-slate-200" />
                  )}
                </IconButton>
              </div>
            </div>
          </div>
          <Button
            sx={{
              borderRadius: "1.3rem",
              padding: "10px 2rem",
              color: "#f0f0f0fe",
              width: "150px",
              marginBottom: "4px",
              boxShadow: "inset -2px 1px 10px #f0f0f0fe",
            }}
            type="submit"
          >
            <p className="font-poppins text-[16px]">Submit</p>
          </Button>
        </form>
      </div>
    </section>
  );
};

export default AddForm;
