import React, { useContext, useEffect, useState } from "react";
import { Button, Tree } from "antd";
import { v4 as uuid } from "uuid";
import { Context } from "../../context/StateContext";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { URLconstants } from "../../api/constants";
const { DirectoryTree } = Tree;

const FileViewer = ({ orgNames, doc }) => {
  const { setPdf, setRecrd, loading, setLoading } = useContext(Context);
  const [body, setBody] = useState({});
  const [scripts, setScript] = useState([]);
  const [docName, setNames] = useState([]);

  const fetchDates = async () => {
    try {
      const response = await axios.post(
        `${URLconstants.BASE_URL}/api/transcription/dates/`
      );
      setBody(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchScript = async (date) => {
    try {
      const [year, month, day] = date.split("-");

      // Add leading zeros to month and day if they are single digits
      const formattedMonth = month.length === 1 ? `0${month}` : month;
      const formattedDay = day.length === 1 ? `0${day}` : day;

      // Form the formatted date
      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

      const createdAtFilterStart = `${formattedDate}T00:00:00.000Z`; // Start of the day
      const createdAtFilterEnd = `${formattedDate}T23:59:59.999Z`; // End of the day
      const response = await axios.get(
        `${URLconstants.fetchTranscripts()}?&filters[createdAt][$gte]=${createdAtFilterStart}&filters[createdAt][$lte]=${createdAtFilterEnd}&populate=*`
      );

      const modifiedData = response.data.data.map((item) => ({
        id: item.id,
        pdf_url: item.word_url,
        recording_url: item.recording_url,
        createdAt: item.createdAt.slice(8, 10),
        doctor: item.doctor,
        job_id: item.job_id,
      }));

      const doctorsData = [];
      const uniqueDoctorNames = [
        ...new Set(
          modifiedData.map((item) => `${item.createdAt}${item.doctor?.name}`)
        ),
      ];

      setNames(uniqueDoctorNames);

      modifiedData.forEach((item) => {
        const doctorName = item.doctor?.name;

        if (doctorName) {
          if (!doctorsData[doctorName]) {
            doctorsData[doctorName] = [];
          }

          doctorsData[doctorName].push({
            id: item.id,
            createdAt: item.createdAt,
            pdf_url: item.word_url,
            recording_url: item.recording_url,
          });
        }
      });

      setScript(doctorsData);
    } catch (error) {
      console.log(error);
    }
  };

  function generateTreeData() {
    try {
      const response = [];
      for (const org of orgNames) {
        let node = { title: org.name, key: `${org.id}`, children: [] };

        body.forEach((item) => {
          if (item.org_id === org.org_id) {
            const years = item.data.years;
            const months = item.data.months;
            const dates = item.data.dates;
            years.forEach((yr) => {
              node.children.push({ title: yr, key: `${yr}`, children: [] });
              for (const year of node.children) {
                months.forEach((mt) => {
                  year.children.push({
                    title: mt,
                    key: `${mt}-${yr}`,
                    children: [],
                  });
                  for (const month of year.children) {
                    dates.forEach((dt) => {
                      month.children.push({
                        title: (
                          <Button
                            className="font-poppins font-medium text-[16px] border-none pb-2 pt-0 pl-[0.5px] pr-0"
                            onClick={() => fetchScript(`${yr}-${mt}-${dt}`)}
                          >
                            {dt}
                          </Button>
                        ),
                        key: `${dt}-${uuid()}`,
                        children: [],
                      });
                    });
                  }
                });
              }
            });
          }
        });

        for (const year of node.children) {
          for (const month of year.children) {
            for (const dates of month.children) {
              docName.forEach((item) => {
                const setdate = item.slice(0, 2);
                const name = item.slice(2);
                const uniqueDate = dates.key.split("-")[0];
                const fileDate =
                  uniqueDate.length === 1 ? `0${uniqueDate}` : uniqueDate;
                if (
                  fileDate === setdate &&
                  name !== "undefined" &&
                  doc[org.name].includes(name)
                ) {
                  dates.children.push({
                    title: name,
                    key: `${uuid()}`,
                    children: [],
                  });
                }
              });
              for (const docs of dates.children) {
                const data = scripts[docs.title];
                data.forEach((itemD) => {
                  docs.children.push({
                    title: itemD.id,
                    key: `${uuid()}`,
                    children: [
                      {
                        title: (
                          <Button onClick={() => setPdf(itemD.word_url)}>
                            Pdf
                          </Button>
                        ),
                        key: `${uuid()}`,
                        isLeaf: true,
                      },
                      {
                        title: (
                          <Button onClick={() => setRecrd(itemD.recording_url)}>
                            Recording
                          </Button>
                        ),
                        key: `${uuid()}`,
                        isLeaf: true,
                      },
                    ],
                  });
                });
              }
            }
          }
        }

        response.push(node);
      }

      return response;
    } catch (error) {
      console.error("Error during tree data generation:", error);
      return [];
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await fetchDates();
      } catch (error) {
        console.error("Error during component initialization:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="">
      <p className="font-poppins text-[19px] font-medium">Files :</p>
      {loading ? (
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#27284a", "#3c4a74", "#4f6ea0", "#5f96cd", "#6ec0fa"]}
        />
      ) : (
        <DirectoryTree
          style={{
            fontFamily: "poppins",
            background: "#f0f0f0ea",
            fontSize: "16px",
            padding: "12px 0",
            width: "full",
            height: "full",
            fontWeight: "500",
          }}
          multiple
          defaultExpandAll
          treeData={generateTreeData()}
        />
      )}
    </div>
  );
};

export default FileViewer;
