import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/StateContext";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { FaClinicMedical } from "react-icons/fa";

import { ColorRing } from "react-loader-spinner";
import AppointmentForm from "../forms/AppointmentForm";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import {
  ConfigProvider,
  Form,
  Input,
  Table,
  Typography,
  DatePicker,
} from "antd";
import bg from "../../assets/D2.png";
import Navbar from "../navbar";
import SideBarFloat from "../SideBarFloat";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CiEdit } from "react-icons/ci";
import { URLconstants } from "../../api/constants";
const Appointment = () => {
  const {
    setSection,
    open,
    setOpen,
    handleDownload,
    rows,
    fetchData,
    userType,
    setRows,
    total,
    pageSize,
    currentPage,
    setCurrentPage,
    setTotal,
    user,
    rowSelection,
    selectedRowKeys,
    handleRemoveAll,
    setSelectedRowKeys,
    removing,
    setFilter,
  } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const heading = [
    "Id",
    "Patient_Name",
    "Unique_Id",
    "Patient_DOB",
    "Description",
    "Start_Date",
    "Time",
    "Status",
    "CreatedAt",
    "Doctor",
    "Clinic",
  ];
  const [filterOn, setFilterOn] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [readMoreStates, setReadMoreStates] = useState({});
  const [value, onChange] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(0);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const isEditing = (record) => record.key === editingKey;

  const handleToggleReadMore = (recordId) => {
    setReadMoreStates((prevStates) => ({
      ...prevStates,
      [recordId]: !prevStates[recordId], // Toggle the readMore state for the specific recordId
    }));
  };
  const [filterInfo, setFilterInfo] = useState({
    patient_name: "",
    doctor_name: "",
    uid: "",
    status: "",
    clinic: "",
    date: "",
  });
  const navigate = useNavigate();
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      width: "12%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="patient_name"
              rules={[
                {
                  required: true,
                  message: "Please Enter your name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "UID",
      dataIndex: "unique_id",
      key: "unique_id",
      width: "8%",
    },
    {
      title: "DOB",
      dataIndex: "patient_dob",
      key: "patient_dob",
      width: "10%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="patient_dob"
              rules={[
                {
                  required: true,
                  message: "Please Enter your DOB",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "10%",
      render: (item, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please Enter your description",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return (
            <p>
              {item.length < 20 || readMoreStates[record.id]
                ? item
                : `${item.substring(0, 10)}...`}
              {item.length > 20 && (
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => handleToggleReadMore(record.id)}
                >
                  {readMoreStates[record.id] ? " read less " : " read more "}
                </span>
              )}
            </p>
          );
        }
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Please Enter your start_date",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: "8%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="time"
              rules={[
                {
                  required: true,
                  message: "Please Enter your time",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "8%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please Enter status",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      key: "doctor",
      width: "12%",
      render: (item) => <p>{item?.name}</p>,
    },
    {
      title: "Clinic",
      dataIndex: "clinic",
      key: "clinic",
      render: (item) => <p className="text-slate-700">{item?.name}</p>,
      width: "6%",
    },
    {
      title: "  Transcripts",
      dataIndex: "transcripts",
      key: "transcripts",
      render: (item) =>
        item && (
          <Autocomplete
            disablePortal
            id="doctor-select-demo"
            sx={{
              height: "100%",
              width: "120px",
              fontFamily: "poppins",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "1.2rem",
                  padding: "0px",
                },
                "&:hover fieldset": {
                  padding: "0px",
                  borderColor: "#1976D2",
                },
                "&.Mui-focused fieldset": {
                  padding: "0px",
                  borderColor: "#1976D2",
                },
              },
            }}
            options={item}
            getOptionLabel={(option) => option.counter_id}
            onChange={(event, value) => {
              // value && setFilter(value.counter_id);
              // navigate("/transcript", { replace: true });
            }}
            renderOption={(props, option) => (
              <Box {...props}>
                <p className="font-poppins m-0 text-[14px] text-slate-700">
                  {option.counter_id}
                </p>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Files"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontFamily: "poppins",
                    color: "rgb(51,65,85)",
                    position: "relative",
                    zIndex: "100",
                    padding: "0.25rem",
                    width: "150px",
                    borderRadius: "1.2rem",
                    cursor: "pointer",
                  },
                  autoComplete: "off",
                }}
              />
            )}
          />
        ),
      width: "130px",
    },
    {
      title: "Created-At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "10%",
      render: (item) => item.split("T")[0],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      width: `${editingKey !== "" ? "12%" : "6%"}`,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className=" flex gap-2">
            <Typography.Link
              onClick={() => save(record.id)}
              className=" font-poppins"
            >
              Save
            </Typography.Link>

            <Typography.Link
              title="Sure to cancel?"
              className=" font-poppins"
              onClick={() => cancel()}
            >
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link
            className=" font-poppins"
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            <CiEdit size={20} />
          </Typography.Link>
        );
      },
    },
  ];
  const edit = (record) => {
    form.setFieldsValue({
      patient_name: record.patient_name,
      description: record.description,
      patient_dob: record.patient_dob,
      start_date: record.start_date,
      time: record.time,
      status: record.status,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const { data } = await axios.put(
        `${URLconstants.fetchAppoints()}/${id}`,
        {
          data: row,
        }
      );
      await fetchData(currentPage);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setEditingKey("");
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedRowKeys([]);
    if (filterOn) {
      filterFunction(
        page,
        filterInfo.patient_name,
        filterInfo.doctor_name,
        filterInfo.uid,
        filterInfo.status,
        filterInfo.clinic,
        filterInfo.date
      );
    } else {
      fetchData(page);
    }
  };

  const handleFilter = async (e) => {
    e.preventDefault();

    try {
      setCurrentPage(1);
      setLoading(true);
      setReadMoreStates({});
      if (
        e.target[0].value ||
        e.target[1].value ||
        e.target[2].value ||
        e.target[3].value ||
        e.target[4].value ||
        e.target[5].value
      ) {
        setFilterInfo({
          patient_name: e.target[0].value,
          doctor_name: e.target[1].value,
          uid: e.target[2].value,
          status: e.target[3].value,
          clinic: e.target[4].value,
          date: e.target[5].value,
        });
        await filterFunction(
          1,
          e.target[0].value,
          e.target[1].value,
          e.target[2].value,
          e.target[3].value,
          e.target[4].value,
          e.target[5].value
        );
      } else {
        setFilterOn(false);
        setFilterInfo({
          patient_name: "",
          doctor_name: "",
          uid: "",
          status: "",
          clinic: "",
          date: "",
        });
        await fetchData(1);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const filterFunction = async (
    page,
    patient,
    doctor,
    uid,
    status,
    clinic,
    date
  ) => {
    const filters = [];
    if (patient) {
      filters.push(`&filters[patient_name][$containsi]=${patient}`);
    }
    if (doctor) {
      filters.push(`&filters[doctor][name][$containsi]=${doctor}`);
    }
    if (uid) {
      filters.push(`&filters[unique_id][$eq]=${uid}`);
    }
    if (status) {
      filters.push(`&filters[status][$containsi]=${status}`);
    }
    if (clinic) {
      filters.push(`&filters[clinic][name][$containsi]=${clinic}`);
    }
    if (date) {
      const createdAtFilterStart = `${date}T00:00:00.000Z`; // Start of the day
      const createdAtFilterEnd = `${date}T23:59:59.999Z`; // End of the day
      filters.push(
        `&filters[start_date][$gte]=${createdAtFilterStart}&filters[start_date][$lte]=${createdAtFilterEnd}`
      );
    }
    const { data } = await axios.get(
      `${URLconstants.BASE_URL}/api/appoints?${
        userType === "0"
          ? `populate=*`
          : userType === "1"
          ? `populate[doctor][populate][0]=clinics&filters[doctor][clinics][hospital][organisation][id]=${user.id}`
          : userType === "2"
          ? `populate[doctor][populate][0]=clinics&filters[doctor][clinics][hospital][id]=${user.id}`
          : userType === "3"
          ? `populate[doctor][populate][0]=clinics&filters[doctor][clinics][id]=${user.id}`
          : `populate[doctor][populate][0]=doctor&filters[doctor][id]=${user.id}`
      }&filters[visibility][$eq]=true&pagination[page]=${page}&pagination[pageSize]=${pageSize}${filters.join(
        ""
      )}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    const rowWithKeys = data.data.map((item, index) => {
      return {
        ...item,
        key: index,
      };
    });
    setRows(rowWithKeys);
    setTotal(data.meta.pagination.total);
  };
  const dataArray = excelData.map((item) => [
    item.id,
    item?.patient_name,
    item.unique_id,
    item.patient_dob,
    item.description,
    item.start_date,
    item.time,
    item.status,
    item.createdAt,
    item?.doctor?.name,
    item?.clinic?.name,
  ]);
  useEffect(() => {
    onChange(false);
    setSelectedIndex(null);
    setExcelData([]);
    localStorage.setItem("section", "appoints");
    const fetchDataAndToggleLoading = async () => {
      try {
        setLoading(true);
        setFilterInfo({
          patient_name: "",
          doctor_name: "",
          uid: "",
          status: "",
          clinic: "",
          date: "",
        });
        setCurrentPage(1);
        await fetchData(1);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndToggleLoading();
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleDropChange = (index) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  };

  const items = [
    {
      key: "1",
      label: (
        <div key="1">
          <p
            onClick={(e) => {
              handleDropChange(1);
            }}
            className="font-poppins text-[16px] cursor-pointer text-slate-700"
          >
            Date
          </p>
          {selectedIndex === 1 && (
            <form
              action=""
              onSubmit={async (e) => {
                setExcelData([]);
                e.target[0].value && (await fetchExcelData(e));
              }}
            >
              <div>
                <DatePicker />
                <Button
                  type="submit"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <p className="font-poppins">
                    {fetchedPages !== totalPages
                      ? "Downloading..."
                      : "Download"}
                  </p>
                </Button>
              </div>
            </form>
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <div key="2">
          <p
            onClick={(e) => {
              handleDropChange(2);
            }}
            className="font-poppins text-[16px] cursor-pointer text-slate-700"
          >
            Doctor
          </p>
          {selectedIndex === 2 && (
            <form
              action=""
              onSubmit={async (e) => {
                setExcelData([]);
                e.target[0].value && (await fetchExcelData(e));
              }}
            >
              <div>
                <Input type="text" />
                <Button
                  type="submit"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <p className="font-poppins">
                    {fetchedPages !== totalPages
                      ? "Downloading..."
                      : "Download"}
                  </p>
                </Button>
              </div>
            </form>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div key="3">
          <p
            onClick={(e) => {
              handleDropChange(3);
            }}
            className="font-poppins text-[16px] cursor-pointer text-slate-700"
          >
            Clinic
          </p>
          {selectedIndex === 3 && (
            <form
              action=""
              onSubmit={async (e) => {
                setExcelData([]);
                e.target[0].value && (await fetchExcelData(e));
              }}
            >
              <Input type="text" />
              <Button
                type="submit"
                sx={{
                  textTransform: "none",
                }}
              >
                <p className="font-poppins">
                  {fetchedPages !== totalPages ? "Downloading..." : "Download"}
                </p>
              </Button>
            </form>
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div key="4">
          <p
            onClick={(e) => {
              handleDropChange(4);
            }}
            className="font-poppins text-[16px] cursor-pointer text-slate-700"
          >
            Status
          </p>
          {selectedIndex === 4 && (
            <form
              action=""
              onSubmit={async (e) => {
                setExcelData([]);
                e.target[0].value && (await fetchExcelData(e));
              }}
            >
              <Input type="text" />
              <Button
                type="submit"
                sx={{
                  textTransform: "none",
                }}
              >
                <p className="font-poppins">
                  {fetchedPages !== totalPages ? "Downloading..." : "Download"}
                </p>
              </Button>
            </form>
          )}
        </div>
      ),
    },
  ];

  const fetchExcelData = async (e) => {
    e.preventDefault();
    const createdAtFilterStart = `${e.target[0].value}T00:00:00.000Z`;
    const createdAtFilterEnd = `${e.target[0].value}T23:59:59.999Z`;

    try {
      const response1 = await axios.get(
        `${URLconstants.BASE_URL}/api/appoints?${getFiltersQueryString(
          selectedIndex,
          createdAtFilterStart,
          createdAtFilterEnd,
          e
        )}`
      );
      const pageSize = response1.data.meta.pagination.pageSize;
      const pages = response1.data.meta.pagination.pageCount;

      setTotalPages(pages);
      setFetchedPages(0);
      for (let i = 1; i <= pages; i++) {
        const { data } = await axios.get(
          `${URLconstants.BASE_URL}/api/appoints?${
            userType === "0"
              ? `populate=*`
              : userType === "1"
              ? `populate[doctor][populate][0]=clinics&filters[doctor][clinics][hospital][organisation][id]=${user.id}`
              : userType === "2"
              ? `populate[doctor][populate][0]=clinics&filters[doctor][clinics][hospital][id]=${user.id}`
              : userType === "3"
              ? `populate[doctor][populate][0]=clinics&filters[doctor][clinics][id]=${user.id}`
              : `populate[doctor][populate][0]=doctor&filters[doctor][id]=${user.id}`
          }&filters[visibility][$eq]=true${getFiltersQueryString(
            selectedIndex,
            createdAtFilterStart,
            createdAtFilterEnd,
            e
          )}&pagination[pageSize]=${pageSize}&pagination[page]=${i}`
        );

        setExcelData((prevData) => [...prevData, ...data.data]);
        setFetchedPages((prevPages) => prevPages + 1);
      }
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    }
  };

  const getFiltersQueryString = (
    selectedIndex,
    createdAtFilterStart,
    createdAtFilterEnd,
    e
  ) => {
    let queryString = "";
    if (selectedIndex === 1) {
      queryString += `&filters[start_date][$gte]=${createdAtFilterStart}&filters[start_date][$lte]=${createdAtFilterEnd}`;
    } else if (selectedIndex === 2) {
      queryString += `&filters[doctor][name][$containsi]=${e.target[0].value}`;
    } else if (selectedIndex === 3) {
      queryString += `&filters[clinic][name][$containsi]=${e.target[0].value}`;
    } else if (selectedIndex === 4) {
      queryString += `&filters[status][$containsi]=${e.target[0].value}`;
    }
    return queryString;
  };

  useEffect(() => {
    if (fetchedPages === totalPages && fetchedPages !== 0 && totalPages !== 0) {
      handleDownload(dataArray, heading);
    }
  }, [fetchedPages, totalPages]);

  return (
    <>
      <Navbar />
      <SideBarFloat />
      <section className="scroll-smooth h-screen  bg-[#f0f0f0ea] font-poppins relative overflow-clip">
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>
        <div className="px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100]">
          <div className="flex pt-10 pb-10 mt-[4.5rem] justify-between lg:items-center md:items-end items-start lg:flex-row md:flex-row flex-col relative">
            <div className="flex gap-4 md:items-center lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
              <div>
                <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                  {open ? "Add Appointment" : "Appointment List"}
                </p>
                <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
              </div>

              {!open && (
                <Link to="/home">
                  <Button
                    onClick={() => setSection("dash")}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    <p className="lg:text-[18px] text-slate-500 font-poppins">
                      Home
                    </p>
                  </Button>
                </Link>
              )}
            </div>
            <div className="flex relative z-[100] items-center gap-4 lg:mt-0 mt-8">
              <Button
                sx={{ borderRadius: "1.5rem", textTransform: "none" }}
                onClick={() => setOpen((prev) => !prev)}
                className="w-[100px] h-[52px]  gap-2 border border-slate-400 rounded-[2.5rem] flex items-center justify-center shadow-[inset_-2px_1px_10px_#9de6f5]"
              >
                <p className="font-poppins text-[16px] text-slate-700">
                  {open ? "Close" : "Add"}
                </p>
                {open ? (
                  <IoClose size={25} className="text-slate-800" />
                ) : (
                  <FaClinicMedical size={20} className="text-slate-800" />
                )}
              </Button>
              {!open && (
                <div className=" rounded-[1.5rem] relative  bg-slate-900">
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1.5rem",
                      padding: "10px",
                    }}
                  >
                    <p
                      className="lg:text-[18px] font-poppins font-light text-slate-200  "
                      onClick={() => onChange((prev) => !prev)}
                    >
                      Download Excel
                    </p>
                  </Button>
                  {value && (
                    <div className="absolute w-auto bg-slate-100  h-auto p-4 rounded-lg flex flex-col gap-2 border-slate-700 border mt-2">
                      {items.map((i) => i.label)}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {!open && (
            <form
              action=""
              onSubmit={(e) => {
                setFilterOn(true);
                handleFilter(e);
              }}
              on
            >
              <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-x-4 gap-y-4 w-4/5">
                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Patient Name
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400"
                    placeholder="Patient Name"
                  />
                </div>
                <div
                  className={`${
                    userType === "4" ? "hidden" : "flex"
                  } flex-col gap-3 `}
                >
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Doctor Name
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400"
                    placeholder="Doctor Name"
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    UID
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400"
                    placeholder="UID"
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Status
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400"
                    placeholder="Status"
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Clinic
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400"
                    placeholder="Clinic"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Date
                  </label>
                  <ConfigProvider
                    theme={{
                      components: {
                        DatePicker: {
                          zIndexPopup: 1100,
                          fontSize: "16px",
                          colorPrimaryHover: "none",
                          colorTextDisabled: "rgb(226,232,240)",
                          colorTextDescription: "rgb(100,116,139)",
                          colorText: "rgb(51,65,85)",
                          colorTextPlaceholder: "rgb(226,232,240)",
                        },
                      },
                      token: {
                        colorIcon: "whitesmoke",
                      },
                    }}
                  >
                    <DatePicker
                      style={{
                        padding: "12px",
                        borderRadius: "1.2rem",
                        fontFamily: "Poppins",
                        background: "rgb(143,168,184)",
                        width: "",
                      }}
                    />
                  </ConfigProvider>
                </div>
                <div className="lg:mt-8 md:mt-8 mt-4 lg:ml-4 flex items-center">
                  <Button
                    style={{
                      border: "1px solid rgb(203,213,225)",
                      borderRadius: "1.3rem",
                      padding: "10px 2rem",
                      color: "",

                      boxShadow: "inset -2px 1px 10px rgb(203,213,225)",
                    }}
                    type="submit"
                  >
                    <p className="font-poppins text-slate-600 text-[16px]">
                      Find
                    </p>
                  </Button>
                </div>
              </div>{" "}
            </form>
          )}
          {open ? (
            <AppointmentForm />
          ) : (
            <div className="lg:py-8 md:py-8 pt-4 pb-[6rem] flex items-center justify-center ">
              {loading ? (
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#27284a",
                    "#3c4a74",
                    "#4f6ea0",
                    "#5f96cd",
                    "#6ec0fa",
                  ]}
                />
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerBorderRadius: 20,
                        headerBg: "#cbd5e1",
                        headerSortHoverBg: "",
                      },
                    },
                  }}
                >
                  <Form form={form} component={false}>
                    <Table
                      dataSource={rows}
                      columns={columns}
                      rowSelection={rowSelection}
                      className="rounded-[1.5rem]   text-slate-800 shadow-lg shadow-slate-300  "
                      pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: false,
                        total: total,
                        onChange: handlePageChange,
                        style: {
                          padding: "0 12px",
                          fontFamily: "Poppins",
                        },
                      }}
                      footer={() => (
                        <div className="">
                          {selectedRowKeys.length > 0 && (
                            <Button
                              onClick={(e) => handleRemoveAll(e)}
                              sx={{
                                textTransform: "none",
                                border: "1px solid lightgrey",
                                borderRadius: "0.8rem",
                              }}
                            >
                              <p className="font-poppins text-[16px] text-slate-700">
                                {removing ? "Removing..." : "Remove"}
                              </p>
                            </Button>
                          )}
                        </div>
                      )}
                      scroll={{ y: 600, x: "calc(800px + 45%)" }}
                    />
                  </Form>
                </ConfigProvider>
              )}
            </div>
          )}
        </div>

        <ToastContainer />
      </section>
    </>
  );
};

export default Appointment;
