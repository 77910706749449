import React, { useContext, useEffect } from "react";
import { CgOrganisation } from "react-icons/cg";
import { GiHospitalCross } from "react-icons/gi";
import { FaClinicMedical } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { FaUserNurse } from "react-icons/fa6";
import { FaUserDoctor } from "react-icons/fa6";
import { FaCalendarCheck } from "react-icons/fa";
import { MdOutlineDescription } from "react-icons/md";
import { TbReportMedical } from "react-icons/tb";
import { Button } from "@mui/material";
import { Context } from "../context/StateContext";
import bg from "../assets/D2.png";
import SideBarFloat from "../components/SideBarFloat";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsGrid3X3 } from "react-icons/bs";
import { PiSignature } from "react-icons/pi";
import Navbar from "../components/navbar";
import { MdAssignmentAdd } from "react-icons/md";
import { Link } from "react-router-dom";

const Home = () => {
  const { setSection, setLoading, setFilter, user, userType, setOpen } =
    useContext(Context);
  const lists = [
    <Link to="/organisation">
      <Button
        key={0}
        onClick={() => {
          setSection("organisations");
          setFilter("");
          setLoading(false);
          setOpen(false);
        }}
        sx={{
          textTransform: "none",

          borderRadius: "1.5rem",
        }}
      >
        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
          <CgOrganisation size={25} className="text-slate-700" />
          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
            Organization
          </p>
        </div>
      </Button>
    </Link>,
    <Link to="/hospital">
      <Button
        key={1}
        onClick={() => {
          setSection("hospitals");
          setFilter("");
          setLoading(false);
          setOpen(false);
        }}
        sx={{
          textTransform: "none",

          borderRadius: "1.5rem",
        }}
      >
        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
          <GiHospitalCross size={25} className="text-slate-700" />
          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600  font-poppins">
            Hospital
          </p>
        </div>
      </Button>
    </Link>,
    <Link to="/clinic">
      <Button
        key={2}
        onClick={() => {
          setSection("clinics");
          setFilter("");
          setLoading(false);
          setOpen(false);
        }}
        sx={{
          textTransform: "none",

          borderRadius: "1.5rem",
        }}
      >
        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
          <FaClinicMedical size={25} className="text-slate-700" />
          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600  font-poppins">
            Clinics
          </p>
        </div>
      </Button>
    </Link>,
    <Link to="/doctor">
      <Button
        key={3}
        onClick={() => {
          setSection("doctors");
          setFilter("");
          setLoading(false);
          setOpen(false);
        }}
        sx={{
          textTransform: "none",

          borderRadius: "1.5rem",
        }}
      >
        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
          <FaUserDoctor size={25} className="text-slate-700" />
          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600  font-poppins">
            Doctors
          </p>
        </div>
      </Button>
    </Link>,
    <Link to="/nurse">
      <Button
        key={4}
        onClick={() => {
          setSection("nurses");
          setFilter("");
          setLoading(false);
          setOpen(false);
        }}
        sx={{
          textTransform: "none",

          borderRadius: "1.5rem",
        }}
      >
        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
          <FaUserNurse size={25} className="text-slate-700" />
          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600  font-poppins">
            Nurse
          </p>
        </div>
      </Button>
    </Link>,
    <Link to="/staff">
      <Button
        key={5}
        onClick={() => {
          setSection("staffs");
          setFilter("");
          setLoading(false);
          setOpen(false);
        }}
        sx={{
          textTransform: "none",

          borderRadius: "1.5rem",
        }}
      >
        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
          <IoIosPeople size={25} className="text-slate-700" />
          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600  font-poppins">
            Staff
          </p>
        </div>
      </Button>
    </Link>,
  ];

  return (
    <section>
      <SideBarFloat />
      <Navbar />
      <div className="h-screen w-full flex  bg-[#f0f0f0ea] font-poppins relative overflow-clip ">
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>

        <div className="h-full overflow-x-clip overflow-y-scroll scrollbar-hide relative">
          <div className="h-full px-8 flex flex-col font-poppins  py-12 gap-12 mt-[4.5rem]">
            <div className="flex  lg:flex-row md:flex-col flex-col lg:items-center  lg:leading-none md:leading-[5rem] leading-[4rem]   gap-4">
              <p className="text-slate-800 lg:text-[76px] md:text-[70px] font-poppins font-bold text-[48px]">
                Welcome{" "}
              </p>
              <p className="text-slate-700 lg:text-[58px] md:text-[52px] text-[40px]  font-poppins font-bold  tracking-wide ">
                {user?.name
                  ? user.name.split(" ")[0]
                  : user.username.split(" ")[0]}
              </p>
            </div>
            <div className="flex flex-col gap-4 items-start">
              <div className="flex gap-4">
                <p className="text-[20px] font-medium tracking-wide">List's</p>
                <div className="border border-slate-700 my-4 rounded-[50%] w-[100px]" />
              </div>
              <div
                className={` grid lg:grid-cols-5
                     ${userType < 4 && "grid-rows-2"} 
                   md:grid-cols-3 grid-cols-2 gap-2`}
              >
                {userType < 4 && lists.slice(userType)}
                {userType < 4 && (
                  <Link to="/transcript">
                    <Button
                      onClick={() => {
                        setSection("transcript");
                        setFilter("");
                        setLoading(false);
                      }}
                      sx={{
                        textTransform: "none",

                        borderRadius: "1.5rem",
                      }}
                    >
                      <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
                        <MdOutlineDescription
                          size={25}
                          className="text-slate-700"
                        />
                        <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
                          Transcription
                        </p>
                      </div>
                    </Button>
                  </Link>
                )}

                {userType < 7 && (
                  <Link to="/manualTranscript">
                    <Button
                      onClick={() => setSection("manualTranscript")}
                      sx={{
                        textTransform: "none",

                        borderRadius: "1.5rem",
                      }}
                    >
                      <div className="flex  items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[185px] w-[165x] justify-center py-6 flex-col ">
                        <TbReportMedical size={25} className="text-slate-700" />
                        <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
                          {userType === "6"
                            ? "Transcripts"
                            : "Manual Transcript"}
                        </p>
                      </div>
                    </Button>
                  </Link>
                )}

                {userType < 4 && (
                  <Link to="/appointment">
                    <Button
                      onClick={() => {
                        setSection("appoints");
                        setFilter("");
                        setLoading(false);
                      }}
                      sx={{
                        textTransform: "none",

                        borderRadius: "1.5rem",
                      }}
                    >
                      <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
                        <FaCalendarCheck size={25} className="text-slate-700" />
                        <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600  font-poppins">
                          Appointment
                        </p>
                      </div>
                    </Button>
                  </Link>
                )}
                {userType === "7" && (
                  <Link to="/transcripterJobs">
                    <Button
                      onClick={() => setSection("jobs")}
                      sx={{
                        textTransform: "none",

                        borderRadius: "1.5rem",
                      }}
                    >
                      <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
                        <MdAssignmentAdd size={25} className="text-slate-700" />
                        <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
                          {userType === "0" ? "Assign Jobs" : "Accept Jobs"}
                        </p>
                      </div>
                    </Button>
                  </Link>
                )}
              </div>

              <div>
                <div className="flex gap-4">
                  <p className="text-[20px] font-medium tracking-wide">
                    Feature's
                  </p>
                  <div className="border border-slate-700 my-4 rounded-[50%] w-[100px]" />
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2">
                  {userType === "0" && (
                    <Link to="/addRecording">
                      <Button
                        onClick={() => setSection("addRecordings")}
                        sx={{
                          textTransform: "none",

                          borderRadius: "1.5rem",
                        }}
                      >
                        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
                          <MdOutlineDescription
                            size={25}
                            className="text-slate-700"
                          />
                          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
                            Add Recordings
                          </p>
                        </div>
                      </Button>
                    </Link>
                  )}
                  {userType === "0" && (
                    <Link to="/transcriptUpload">
                      <Button
                        onClick={() => setSection("uploadTranscript")}
                        sx={{
                          textTransform: "none",

                          borderRadius: "1.5rem",
                        }}
                      >
                        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
                          <TbReportMedical
                            size={25}
                            className="text-slate-700"
                          />
                          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
                            MT Transcript
                          </p>
                        </div>
                      </Button>
                    </Link>
                  )}
                  {(userType === "4" || userType === "0") && (
                    <Link to="/signatureUpload">
                      <Button
                        onClick={() => setSection("signatureUpload")}
                        sx={{
                          textTransform: "none",

                          borderRadius: "1.5rem",
                        }}
                      >
                        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
                          <PiSignature size={25} className="text-slate-700" />
                          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
                            Signature
                          </p>
                        </div>
                      </Button>
                    </Link>
                  )}
                  {/* {userType === "0" && (
                    <Link to="/workpool">
                      <Button
                        onClick={() => setSection("workpool")}
                        sx={{
                          textTransform: "none",

                          borderRadius: "1.5rem",
                        }}
                      >
                        <div className="flex items-center gap-2 rounded-3xl shadow-[inset_-2px_1px_12px_#9de6f5] lg:w-[180px] w-[160px] justify-center py-6 flex-col ">
                          <BsGrid3X3 size={25} className="text-slate-700" />
                          <p className="lg:text-[18px] md:text-[18px] text-[16px] text-slate-600 font-poppins">
                            Workpool
                          </p>
                        </div>
                      </Button>
                    </Link>
                  )} */}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </section>
  );
};

export default Home;
