import React from 'react'
import { Link } from 'react-router-dom';
import { Fab } from '@mui/material';
import noise from '../assets/noise.png'
import { FaHome } from "react-icons/fa";

const Policy = () => {
  return (
    <>
    <section className='bg-[#f0f0f0ea] font-poppins w-full h-screen overflow-y-scroll overflow-x-clip  '>
      <div className='relative bg-[url(https://images.pexels.com/photos/4160125/pexels-photo-4160125.jpeg?auto=compress&cs=tinysrgb&w=600)] w-full h-[65vh]   bg-no-repeat bg-center bg-cover'>
            <div className='absolute h-[65vh] w-full'>
              <img src={noise} alt="" className='w-full h-full' />
            </div>
            <div className='relative lg:top-[30%] w-full py-2 px-3 bg-[#0000002b] lg:left-[55%] md:left-[25%] md:top-[45%] left-[10%] top-[10%]'>
              <p className='lg:text-[94px] text-slate-100  font-black md:text-[80px] text-[48px]'>Privacy Policy</p>
              <div className='w-[15%] border-[2px] rounded-[3rem]  border-slate-200  ml-2 mb-2'/>
            </div>
      </div>

      <div className='flex justify-center py-6 bg-[#868B83]'>
        <p className='text-[32px] font-medium text-slate-100'>MDofficeManager, LLC</p>
      </div>

      <div className='w-4/5 mx-auto my-16 flex flex-col gap-4'>
         <div className='flex flex-col gap-4 text-slate-700 tracking-wide text-[15px]'>
            <p className='text-[14px]'>Privacy Policy of <Link to='/' className='text-blue-500'>MDofficeManager.com</Link></p>
            <p className='text-slate-800 text-[28px]'>Effective Date: November 20, 2018</p>
            <p className='tracking-wide'>MDofficeManager, LLC (“us”, “we”, or “our”) operates the www.MDofficeManager.com website (the “Service”).</p>
            <p className='tracking-wide'>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from <a className='text-blue-500' href='www.MDofficeManager.com'>www.MDofficeManager.com</a></p>
         </div>
          <div>
              <p className='text-slate-800 text-[28px]' >1. Our Commitment to Privacy</p>
              <div className='flex flex-col gap-4 text-slate-700 mt-4 tracking-wide text-[15px]'>
            
                <p>Privacy is highly important to us. This Privacy Policy (this “Policy”) sets out the key elements of how we address the privacy and security of information entrusted to us by our customers through their access and use of the MDofficeManager, LLC electronic health record platform (the “Services”), as well as the privacy of information entrusted to us by business partners, prospects and others who seek information and/or contact us through <a className='text-blue-500' href='www.MDofficeManager.com'>www.MDofficeManager.com</a>  (individually, collectively, and including any subdomains, the “Site”). The Site can be used and accessed by the public as a source of general information about MDofficeManager, LLC.</p>

                <p>Our customers and business partners also can access the Services via the Site.</p>

                <p>As privacy laws and practices evolve, we will amend this Policy from time to time. While we will endeavor to give reasonable notice of such changes, we do reserve the right, where necessary, to do so without prior notice.</p>
              </div>
          </div>
          <div>
            <p className='text-slate-800 text-[28px] italic'>What is considered private?</p>
            <div className=' tracking-wide text-[15px] flex flex-col gap-4 text-slate-700 mt-4'>
                <p>Information that is used by a government authority, financial institution or insurance carrier to distinguish a person from other individuals ( e.g., social security number, social insurance number, credit card information, or insurance policy number) is private. Such information can be used to identify an individual (e.g., a person who works at a healthcare facility, or a resident or patient in a healthcare facility). Certain information may be used to contact a person directly (e.g., an email address, home mailing address or telephone number). Depending on the jurisdiction, the above identifiers are considered to be Personal Information (“PI”), Personally Identifiable Information (“PII”), Sensitive Personal Information (“SPI”) or a similar term, and it is private. An individual’s business contact information and business title generally are exempt from privacy laws. Information about an individual’s health, including insurance and billing information, is also considered – depending on the jurisdiction – to be PI, Protected Health Information (“PHI”), Personal Health Information (also known as “PHI”), Individually Identifiable Health Information (“IIHI”) or a similar term, and it also is private. In  and the United States, the laws that primarily govern how we deal with the PI, PII, SPI, PHI and IIHI which you provide to  us in relation to the Services are listed in Table 1.</p>
                <p>
                For the remainder of this Policy, we will refer to all PI, PII, SPI, PHI, IIHI, and “Health Information” as “Personal Information” unless we specifically note otherwise. If we wish to refer only to information about a specific individual’s health but not to other forms of Personal Information, we will refer to “PHI.”
                </p>
                <p>
                This Policy also will apply to non-personal information if such information can be used in combination with other Personal Information or non-personal information to identify an individual.
                </p>
                <p>
                Please be aware that this Policy only covers information manually submitted to, or automatically collected by, us through use of the Site and/or the Services. If you contact or exchange information with another MDofficeManager, LLC customer or business partner in person or through a means other than through the Site or Services, such activity is not covered by this Policy. Additionally, if you are not a customer or a business partner of MDofficeManager, LLC by way of written agreement, and are contacting us out of interest in the Services, a business partnership or a job opportunity, please be aware that the information that you share with us is not covered by this Policy, unless required by law.
                </p>
            </div>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>2. Personal Information Collection & Use</p>
            <p className='mt-4 tracking-wide text-[15px] text-slate-700'>There are two ways Personal Information can be submitted to us. The first is through direct submission or what we call ‘Manual Submission’ and the second is by way of ‘Automatic Submission’ triggered by any interaction with the Site through a computer, Point of Care station, mobile device or tablet.</p>
          </div>
          <div>
          <p className='text-slate-800 text-[28px] italic'>Manual Submission</p>
            <p className=' tracking-wide mt-4 text-slate-700 text-[15px]'>Personal Information can be submitted to us directly when you communicate with us offline (in person or by telephone), via email or via the Site (by entering data or uploading files) or when you authorize MDofficeManager, LLC to access, retrieve and/or import Personal Information from another user or third party on your behalf. Additionally, if you become a customer of MDofficeManager, LLC, you will be required to register by submitting Personal Information via the Services, email or offline. This could include name, email address, mailing address, telephone number(s) and other contact and billing information.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px] italic'>Automatic Submission</p>
            <div className='text-[15px] tracking-wide mt-4 flex flex-col gap-4 text-slate-700'>
              <p>Whenever your computer, mobile device or tablet visits, logs in or otherwise interacts with the Site, we gather data from your device and the operating software of your device transmits a ‘request’ to us. That request includes non-personal information that is necessary to identify and route the information your device is requesting. This communication is necessary for all website and Internet services.</p>
              <p>
              We also use cookies (sometimes referred to as “web beacons” or “server logs”). Cookies are files that web browsers place on a computer’s hard drive that tell us whether customers or visitors have been to the Site previously, and they often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit www.allaboutcookies.org. We collect several different types of information for various purposes to provide and improve our Service to you.
              </p>
            </div>
          </div>
          <div>
            <p className='text-slate-800 text-[28px] italic'>Types of Data Collected using cookies can include but not limited to:</p>
            <div className='text-[15px] tracking-wide mt-4 flex flex-col gap-4 text-slate-700'>
            <ul className='ml-4'>
              <li> Date and time a ‘request’ is transmitted through the Site</li>
              <li className='ml-6 leading-7'>
                • The model of the device making the request<br />
                • The type and version of the operating software running on the device<br />
                • The web browser used on the device and making the request<br />
                • IP address<br />
                • Geographic location<br />
                • Time zone<br />
                • Search terms used<br />
                • URLs visited<br />
                • Information about some of the cookies that are installed on your computer, mobile device, or tablet<br />
                • Internet service provider<br />
                • Previous activity on the Site
              </li>
            </ul>
            <p>
            to notify you about changes to our Service
            </p>
            <p>to allow you to participate in interactive features of our Service when you choose to do so</p>
            <p>to provide customer care and support</p>
            <p>to provide analysis or valuable information so that we can improve the Service</p>
            <p>to monitor the usage of the Service </p>
            <p>to detect, prevent and address technical issues </p>
            <p>to register customer accounts </p>
            <p>• to contact customers to discuss their experience with the Services, current and future needs as a customer, or to communicate future promotions or special events which might benefit them </p>
            <p>• to contact a prospective customer </p>
            <p>• to provide our cloud-hosted SaaS Services </p>
            <p>• to operate, maintain, manage and administer the Services, including processing registrations and payments, and diagnosing technical problems </p>
            <p>• to respond to questions and communications </p>
            <p>•to make service or administrative announcements to customers about unscheduled downtime or new features, services, products, functionality, terms, or other aspects of the Services </p>
            <p>• to perform audits, research, measurements and analyses in an effort to maintain, administer, support, enhance and protect the Services, including determining usage trends and patterns and measuring the effectiveness of content, advertising, features or services </p>
            <p>• to create new features, products or services </p>
            <p>• to contribute to certain health and medical research (only non-personal
information will be used)* </p>
            <p>• to provide bench-marking and performance tracking solutions* </p>
            <p>*We may track and analyze non-identifying, aggregate usage, and volume statistical information from our visitors and customers and may provide such information to third parties. We are committed to ensuring privacy and protecting Personal Information. We also are committed to providing valuable insights and analytics to enable better performance and quality.</p>
            <p>Additionally, we use Google Analytics to track and analyze page usage behavior to improve performance in the use of the Services and the Site. We use this to track only what page you are clicking on, and do not use it to track any Personal Information. You can read more about how Google uses your Personal Information&nbsp;<a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">here</a>. You can also opt-out of Google Analytics&nbsp;<a className='text-blue-500' href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">here</a>.</p>
            </div>
          </div>
          <div>
          <p className='text-slate-800 text-[28px]'>3. Purpose</p>
          <div className='flex flex-col text-slate-700 mt-4 text-[15px] tracking-wide gap-4'>
            <p>
            MDofficeManager is a cloud-based Software-as-a-Service (SaaS) platform designed to help health care providers manage both clinical and financial aspects  of residents and patients in their care and to connect MDofficeManager are customers with a variety of related healthcare networks and service providers. We primarily collect Personal Information as necessary to communicate with you and/or to provide the Services. Some Personal Information (but not PHI) also may be collected for marketing and sales purposes (e.g., if you complete a form to register for a webinar or download content, or if you visit a part of our Site where we deploy cookies from LinkedIn, Facebook, Twitter, etc. [in which case, their privacy policies will apply]).
            </p>
            <p>Personal Information and non-personal information may be used for the following reasons:</p>

            <p>We may also collect information how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

            <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>

            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

            <p>Examples of Cookies we use:</p>

            <p>Session Cookies. We use Session Cookies to operate our Service.</p>
            <p>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</p>

            <p>Security Cookies.</p>
            <p>Cookies and purpose:</p>

            <p><a className='text-blue-500' href='www.MDofficeManager.com'>MDofficeManager</a> uses a persistent cookie to help save and retrieve org codes for a user who has accessed the Services. We issue a session cookie [1] only to record encrypted authentication information for the duration of a specific session. The session cookie does not include the username or password of a customer. For user convenience, in relation to touchscreen logins, we also use a non-session-based cookie to store a user’s ID; however, this is configurable. We do not store passwords in session cookies, persistent cookies or headers. If a cookie is rejected, access to and usage of the Services will be denied. Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>

            <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. For information on how to turn your cookies off,<a className='text-blue-500' rel='noopener noreferrer' href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en" target="_blank" >&nbsp;please click here</a>.&nbsp;The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.&nbsp;Google Analytics&nbsp;For more information on the privacy practices of&nbsp;Google, please visit the Google Privacy &amp; Terms web page:&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://policies.google.com/privacy?hl=en" target="_blank" >https://policies.google.com/privacy?hl=en</a>&nbsp;or For information on the types of cookies we use, please access<a className='text-blue-500' rel='noopener noreferrer'  href="https://www.marketo.com/company/trust/gdpr/">&nbsp;</a><a className='text-blue-500' rel='noopener noreferrer'  href="https://privacy.google.com/businesses/compliance/#?modal_active=none" target="_blank" >Google’s&nbsp;</a>privacy policy.</p>
            <p>≈&nbsp;Piwik or Matomo</p>
            <p>Piwik or Matomo is a web analytics service. You can visit their Privacy Policy page here:&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://matomo.org/privacy-policy" target="_blank" >https://matomo.org/privacy-policy</a></p>
             
            <p>≈&nbsp;Clicky</p>
            <p>Clicky is a web analytics service. Read the Privacy Policy for Clicky here:&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://clicky.com/terms" target="_blank" >https://clicky.com/terms</a></p>

          
           <p>≈&nbsp;Statcounter</p>
           <p>Statcounter is a web traffic analysis tool. You can read the Privacy Policy for Statcounter here:&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mixpanel.com/optout/" target="_blank" >https://statcounter.com/about/legal/</a></p>

           <p>≈&nbsp;Mixpanel</p>
           <p>Mixpanel is provided by Mixpanel Inc</p>
           <p>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page:&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mixpanel.com/optout/" target="_blank" >https://mixpanel.com/optout/</a></p>

           <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of&nbsp;Mixpanel:&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mixpanel.com/terms/" target="_blank">https://mixpanel.com/terms/</a></p>
 
            <p>We use cookies to enhance the quality of the Services by, for example:</p>
            <p>•&nbsp;saving user preferences</p>
            <p>•&nbsp;preserving session settings and activities</p>
            <p>•&nbsp;providing limited auto-fill functionality for those who use the Services frequently</p>
            <p>•&nbsp;analyzing various features and content of the Services</p>
            <p>We will never sell your Personal Information (or non-personal information if it can be used in any way to identify you).</p>
            <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for operational, statutory/regulatory or other legal reasons.</p>

            <p><em>Support, Education Services and Purchase Orders</em></p>
            <p>Personal Information collected through the Site may be accessed and used by&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager</a>&nbsp;to respond to customer requests for support, to provide education or consulting services and/or to confirm customer compliance with the terms of its purchase (as set forth in signed orders). This may include testing and applying new product or system versions, patches, updates and upgrades; monitoring and testing system usage and performance; and, resolving bugs and other issues which a customer reports to&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager.</a></p>

            <p>Personal Information collected for these purposes is only used for time periods relevant to fulfill such purposes.</p>

            <p><em>Provider Directories&nbsp;and Communication Services</em></p>

            <p>As a customer of<a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >&nbsp;MDofficeManager, LLC,</a>&nbsp;and depending on the Services you subscribe to or enroll in, your contact and directory information may be listed in one or more public or professional directories. These directories may include profile information such as contact information or name.</p>
            <p>We also offer services that facilitate communications, including secure and encrypted transmission of data between users and non-users through in-product instant messaging services, service-branded emails, short message service (“SMS”) and other electronic communication channels. The identity of the sender and the receiver always will be evident with every communication transmitted&nbsp;<em>via</em>&nbsp;the Services. The information you choose to share with such parties is not the responsibility of&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager, LLC.</a>&nbsp;We cannot take responsibility for the actions of other users or persons with whom you share information, including Personal Information.</p>

            <p><em><a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager, LLC</a>&nbsp;Annual SUMMIT, Community Forums and Surveys</em></p>

            <p>Many&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager, LLC</a>&nbsp;customers share their experiences of the Services, either at our annual SUMMIT, Convention, or online&nbsp;<em>via</em>&nbsp;customer-driven Community Forums. During our annual SUMMIT, we may solicit testimonies of the Services or your relationship with&nbsp;<a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager, LLC</a>, either as a customer or business partner. We will never use any such testimony, or video or audio footage, in conjunction with information that identifies you (or the organization you represent) without your express consent.</p>

            <p>Community Forums are public and allow customers to communicate between each other and, possibly, with the general public. Any information posted within Community Forums is public and we recommend against any disclosure of Personal Information or other sensitive information that could be traced, directly or indirectly, to an individual.</p>
            <p>From time to time, we may ask customers to complete surveys or ratings about the provision of the Services or of their own health care practices and operations. You should assume that the content of any Personal Information you provide would not be maintained in confidence. We will, however, tell you why we are collecting your responses and how they will be used. In completing such surveys, be mindful of what Personal Information is disclosed. We recommend against sharing any&nbsp;PI, PHI&nbsp;or other sensitive information that could be traced, directly or indirectly, to any individual.</p> 

             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>Transfer of Data</p>
             </div>
             <p>Your information including Personal Data, may be transferred to  — and maintained on  — computers located outside of your state,province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
             <p>
             If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United Stated and process it there.
             </p>
             <p>Your consent to this Privacy Police followed by your submission of such information represents your agreement to that transfer.</p>
             <p><a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager, LLC</a> will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to and organization or a country unless there are adequate controls in place including the security of your data and other personal information. </p>

             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>Disclosure of Data</p>
             </div>
             <p>
             Legal Requirement
             </p>
             <p>
             MDofficeManager, LLC may disclose your Personal Data in the good faith belief that such action is necessary to:
             </p>
             <p>Θ&nbsp;To comply with a legal obligation</p>

            <p>Θ&nbsp;To protect and defend the rights or property of&nbsp;<a href="https://mdofficemanager.com/" target="_blank" rel="noopener noreferrer">MDofficeManager, LLC&nbsp;</a></p>

            <p>Θ&nbsp;To prevent or investigate possible wrongdoing in connection with the Service</p>

            <p>Θ&nbsp;To Protect the personal safety of users of the Service or the public.</p>

            <p>Θ&nbsp;To protect against legal liability</p>
             
            
          </div>
          </div>

          <div>
            <p className='text-slate-800 text-[28px]'>4. Consent and Authorization</p>
            <p className='text-[15px] text-slate-700 mt-4'>By visiting the Site, you are consenting to the use of your Personal Information for the aforementioned purposes. On occasion, we may request additional consent in connection with the use or sharing of Personal Information for a purpose not stated in this Policy or because the law requires such consent.</p>
            <p className='text-[15px] text-slate-700 mt-4' >If you are a customer or business partner of <a className='text-blue-500' rel='noopener noreferrer'  href="https://mdofficemanager.com/" target="_blank" >MDofficeManager, LLC</a>, we will never use your Personal Information in a manner not otherwise provided for in our written contracts with you, authorization forms you provide to us, or this Policy.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>5. Protecting Health Information</p>
            <p className='mt-4 text-slate-700 text-[15px]'>As a provider of hosted, electronic health record solutions,&nbsp;<a href="https://mdofficemanager.com/" target="_blank" rel="noopener noreferrer" className='text-blue-500'>MDofficeManager, LLC</a>&nbsp;customers are health care providers and subject to laws and regulations governing the use and disclosure of PHI. In the United States, the Health Insurance Portability and Accountability&nbsp;Act of 1996 (“HIPAA”), the Health Information Technology for Economic and Clinical Health of 2009 (“HITECH”), along with the regulations adopted under those statutes, and similar state laws (where those laws are more stringent than HIPAA) govern the handling of PHI. Other laws may apply with&nbsp;respect to specific customers, as set forth in our contracts with those customers. Health care providers are considered to be Covered Entities under HIPAA and are subject to its rules regarding PHI. If a provider delegates some of its work to a third party, and that party must access PHI in order to perform the work, then such party is considered by HIPAA to be a Business Associate and is subject to the same rules regarding the protection of PHI as the Covered Entity. To enforce protection, HIPAA requires Covered Entities to execute a “Business Associate Agreement” or ”BAA” with each of its Business Associates. Our U.S.-based customers are required to sign a BAA with us. As a Business Associate, we are required to use reasonable and appropriate measures to safeguard the confidentiality, integrity and accessibility of PHI that is stored and processed on behalf of Covered Entities. From time to time, the terms of&nbsp;<a className='text-blue-500' href="https://mdofficemanager.com/" target="_blank" rel="noopener noreferrer">MDofficeManager, LLC’s</a>&nbsp;standard BAA, and/or similar agreements may be posted on the Site.</p>
          </div>

          <div>
            <p className='text-slate-800 text-[28px]'>6. Sharing Your Personal Information</p>
            <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'><p><em>Third-Party Websites, Software and Services</em></p>
            <p>Our Site contains links to third-party websites, software and services. Customers and visitors who access a linked website&nbsp;<em>via</em>&nbsp;the Site may be disclosing Personal Information. It is the responsibility of the user to keep Personal Information private and confidential. Additionally, we allow third-parties to offer services to our customers through integration with the&nbsp;MDofficeManager&nbsp;software platform (“Connected Services”). Customers’ use of Connected Services is optional. Customers that choose to use a Connected Service acknowledge and authorize the transmission of Personal Information to a third party. We are not responsible for, nor can we control, the privacy practices of third parties. A third party’s use, storage and sharing of your Personal Information is subject to its own privacy policies and not this Policy.The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
            <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>Service Providers</p>
             </div>
             <p>
             We may employ third party companies and individuals to facilitate our service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
             </p>
             <p>
             We may use third-party Service Providers to monitor and analyze the use of our Service.
             </p>
             <p>
             Link To other Sites
             </p>
             <p>
             Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
             </p>
             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>Legal Procedures</p>
             </div>
             <p>
             We may need to preserve, use or disclose your Personal Information in response to a court order, subpoena, search warrant, judicial proceeding or other legal process, if we have a good faith belief that the law requires us to do so, or to otherwise protect our rights. Some legal procedures may prohibit or prevent us from notifying users, other individuals or entities identified in such procedures or may compel us to take measures otherwise in violation of this Policy or a written agreement you have with us.
             </p>
             <p>
             Personal Information preserved as a result of legal procedures can be maintained for an indefinite period of time and for as long as we have a good faith belief that it is necessary and appropriate under the circumstances.
             </p>
             <p>
             These procedures may also involve your information; for example, if you’re contractual relationship with us has been terminated or disabled.
             </p>
             <div className='px-4 py-6 w-full border border-slate-200 bg-[#00000008]'>
                <p>Children's Privacy</p>
             </div>
             <p>
             Our Service does not address anyone under the age of 18 (“Children”).
             </p>
             <p>
             We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
             </p>
             <p>
             Business Reorganizations or New Management
             </p>
             <p>
             There are two situations where we will need to share your Personal Information with a third party as a result of a business reorganization. The first situation concerns the acquisition of MDofficeManager, LLC by a third party, and the second concerns the acquisition of our customers. A reorganization involves a sale, merger, transfer, exchange or other disposition of all or part of a business.
             </p>
             <p>
             If such a transaction occurs, be aware that your Personal Information may be made available to the acquiring party. If the reorganization concerns one of our customers, MDofficeManager, LLC requires the parties participating in the sale to show written evidence of the completed transaction, or some alternate form of written authorization (by both the buyer and the seller), to transfer Personal Information hosted by the Services from the seller to the buyer. A change in management of a customer facility could involve similar authorization requirements, if data must be transferred from the prior management company to the new management company (or to the owner).
             </p>
             <p>
             We will not disclose your Personal Information to a party without sufficient and proper authorization from you, unless required by law.
             </p>
            </div>
          </div>

          <div>
            <p className='text-slate-800 text-[28px]'>7. Security, Threats and Breach Notification</p>
            <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>Our Services have physical, administrative and technical security measures in place to protect against the loss, misuse, unauthorized access and alteration of data and Personal Information under our direct control. When the Services are accessed using current browser technology, Secure Socket Layer (“SSL”) technology protects information using both server authentication and data encryption to help ensure that data is safe, secure, and available only to you. MDofficeManager, LLC also implements an advanced security methodology based on dynamic data and encoded session identifications, and hosts the Services in a secure server environment which uses a firewall and other advanced technology to prevent interference or access from outside intruders. Unique user names and passwords also are required and must be entered each time a customer logs into the Services.</p>
             <p>
             We are committed to educating our staff about the protection of Personal Information, and the importance of compliance with relevant privacy legislation and company policies. Employees and contractors are required to sign confidentiality agreements.
             </p>
             <p>
             These safeguards help prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of Personal Information; however, it is important to remember that no system can guarantee 100% security at all times. In the event that we detect a threat to security or a security vulnerability, we may attempt to contact you to recommend protective measures. Additionally, incidents of suspected or actual unauthorized handling of Personal Information are always directed
             </p>
             <p>
             to MDofficeManager, LLC’s Legal & Compliance team, which is responsible for determining escalation and response procedures, depending on the severity and nature of the incident. Incidents involving unauthorized handling of PHI will be governed by relevant legislation and, where applicable, the provisions of a BAA or similar agreement with a customer. If MDofficeManager, LLC determines that Personal Information has been misappropriated or otherwise wrongly acquired, MDofficeManager, LLC will report such misappropriation or acquisition to you promptly.
             </p>
             <p>
             For customers who purchase Connected Services, it is important to note that the third-party vendors that provide Connected Services to you may have different procedures in place to protect your Personal Information than the standards MDofficeManager, LLC has implemented. We cannot be responsible for their policies or their compliance with them, regardless of whether we have integrated their solutions with our Services and/or made them available to you.
             </p>
           </div>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>8. Openness, Transparency and Access to Personal Information</p>
            <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>Upon written request by an authorized individual, MDofficeManager, LLC will allow access to any PHI collected and stored about such individual, unless providing access could reasonably be expected to interfere with the administration or enforcement of the law or it is impracticable or impossible for MDofficeManager, LLC to retrieve the PHI. However, MDofficeManager, LLC will first direct the individual to the applicable customer with the request that the customer provide such access to the individual as the majority of our contracts make the customer the appropriate party to respond to access requests. When provided with reliable evidence of an error in PHI data, MDofficeManager, LLC will correct any inaccurate PHI, unless to do so would interfere with the administration or enforcement of the law. However, where such a request comes from an individual whose PHI allegedly contains an error, MDofficeManager, LLC will first direct the individual to the applicable customer, with the request that the customer review the request and inform MDofficeManager, LLC whether there is, in fact, any clinical merit to the claim that an error exists (and, if so, will require the customer’s written authorization and instruction to correct such error). Unless otherwise prohibited or restricted by the applicable customer, MDofficeManager, LLC may transmit any corrected PHI to third parties that have had access to the erroneous PHI. Please note that any deletions performed by MDofficeManager, LLC to correct an error in PHI will only be “soft” deletes (i.e., the data will no longer be viewable from the front end of the platform). In order to be able to address any concerns about fraud which may be raised in the future by, for example, a resident or a government agency, we will retain evidence of: (i) the deletion; (ii) your authorization to make the deletion; and, (iii) the prior version of the data.</p>
             <p>
             If customers or their users need to update or change their Personal Information stored by us, they may do so by editing the organization or user record via the Services.
             </p>
            
           </div>
          </div>          
          <div>
            <p className='text-slate-800 text-[28px]'>9. Retention and Deletion</p>
            <p className='text-[15px] text-slate-700 mt-4'>MDofficeManager, LLC will retain Personal Information: as necessary for the purposes outlined in this Policy; for as long as a customer account remains active; as required to manage and administer the Services; as required to carry out legal responsibilities (e.g., legal holds and other legal procedures); to resolve a dispute (including enforcement of a contract); or, as communicated to you at the time of collection. After all applicable retention periods have expired, we will delete or destroy your Personal Information in a manner designed to ensure that it cannot be reconstructed or read. If, at any time, it is not feasible for us to delete or destroy your Personal Information, we will continue using the same safeguards of protection and security outlined in this Policy and related subordinate policies, for as long as it cannot be destroyed.</p>
          </div>
          <div>
            <p className='text-slate-800 text-[28px]'>10. Cross-Border Transfers</p>
            <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>
             Unless otherwise specified, MDofficeManager, LLC provides the Services from its headquarters in Marietta, Georgia, and hosts customer’s production database in the customer’s country of residence. In the case of American customers, MDofficeManager, LLC may access a customer’s data from for purposes of, for example: responding to support requests; fixing software issues; or, providing services to a customer on the back end of the platform (e.g., correcting errors in a resident record [subject to the conditions set forth in Section 8 of this policy], adding/removing a facility’s data to/from a customer’s database in the event of a purchase/sale/change in management, or performing simulation testing of our disaster recovery plan).
             </p>
             <p>
             In the event of a disaster affecting MDofficeManager, LLC’s data center, we will host American customers’ data in India until the disaster is addressed.
             </p>
            </div>
          </div>
           <div>
           <p className='text-slate-800 text-[28px]'>11. Opt-Out Policy</p>
           <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>We offer visitors to the Site and our customers using the Services a means to choose how we may use the information they provide to us. If, at any time, you change your mind about:</p>
             <div className='flex flex-col gap-2 ml-6'>
                <p>1. Our use of Personal Information submitted to the Site;</p>
                <p>2. Our use of Personal Information submitted viathe Services;</p>
                <p>3. Receiving notices from us (including automatic notifications about updates to the Services and the frequency with which we send you such messages); or</p>
                <p>
                4. Receiving marketing or sales notices from us, including special offers, product enhancement details, event information, etc.;
                </p>
                <p>
                5. Sharing your non-personal information with third parties (as described in this Policy), send us a request specifying your choice or change of permission by contacting us.
                </p>
             </div>
           <p>
           Please note that, if you choose to impose certain restrictions on our use of your Personal Information – e.g., if we may no longer access your database to perform any necessary quality testing or disaster recovery testing – you may no longer be able to use the Services. Similarly, if you choose to unsubscribe from receiving notifications or messages from us, your customer experience in using the Services may be compromised. If complying with your request would result in termination of the Services, we will make that clear to you and confirm that this is what you want before proceeding.
           </p>
           </div>
           </div>
           <div>
            <p className='text-slate-800 text-[28px]'>12. The MDofficeManager, LLC Vendor</p>
            <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>
             The MDofficeManager, LLC Vendor (the “Vendor”) is a directory of products and services that are integrated with MDofficeManager, LLC services. MDofficeManager, LLC customers may use the Vendor to browse, locate, or request information for integrated products and services. These products and services are offered by third parties not affiliated with MDofficeManager, LLC. While MDofficeManager, LLC may integrate third-party products and services into MDofficeManager, LLC services, you understand and agree that MDofficeManager, LLC in no way controls or is responsible for any third-party product or service on the Vendor. MDofficeManager, LLC will not be liable for your interactions with any organizations or individuals found on the Vendor. You will need to contract separately for the integrated products and services offered by those third parties, and those dealings are solely between you and such organizations or individuals. Your use of any third-party product or service will not affect your relationship with MDofficeManager, LLC. MDofficeManager, LLC will require further consent or authorization prior to sending any of your or your employer’s or patients’ information or data to any third party with which you have a contract.
             </p>
           
            </div>
          </div>
           <div>
            <p className='text-slate-800 text-[28px]'>13. Changes to this Privacy Policy</p>
            <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>
             We may update our Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, statutory/regulatory or other legal reasons, We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.
             </p>
            <p>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
            </div>
          </div>
           <div>
            <p className='text-slate-800 text-[28px]'>14. Contact Us</p>
            <div className='text-[15px] text-slate-700 mt-4 flex flex-col gap-4'>
             <p>
             If you believe your Personal Information has been used in a way that is inconsistent with this Policy or your specified preferences, or if you have further questions related to our privacy practices, please contact us by mail at the address below:
             </p>
             <p>
             MDofficeManager, LLC. <br />
             1410 S Clark Blvd, Suite 2100 <br />
            Clarksville, IN 47129 <br />

            Table 1: Privacy Laws Applicable to the Services
              </p>

              <table id="tablepress-2" className="tablepress tablepress-id-2 dataTable no-footer">
      <thead>
        <tr className="bg-[#D8EDF6] px-2">
          <th className="py-6 px-2 text-center" rowSpan="1" colSpan="1" style={{ width: '364.65px' }}>
            Applicable Law<br /><br />
            (includes any amendments and implementing regulations)
          </th>
          <th className="py-6 px-2 text-center" rowSpan="1" colSpan="1" style={{ width: '750.075px' }}>
            Type of Personal Information Governed by the Law
          </th>
          <th className="py-6 px-2 text-center" rowSpan="1" colSpan="1" style={{ width: '94.55px' }}>
            Jurisdiction
          </th>
        </tr>
      </thead>
      <tbody className="row-hover">
        <tr className="bg-[#FEFEFF] ">
          <td className="py-4 px-2">Health Insurance Portability and Accountability Act of 1996, P.L. 104-191</td>
          <td className="py-4 px-2">Protected Health Information</td>
          <td className="py-4 px-2">United States</td>
        </tr>
        <tr className="bg-[#F8F8F9]">
          <td className="py-4 px-2">Health Information Technology for Economic and Clinical Health Act of 2009, P.L. 111-5, Title XIII (Amends HIPAA)</td>
          <td className="py-4 px-2">Health Information and Individually Identifiable Health Information</td>
          <td className="py-4 px-2">United States</td>
        </tr>
        <tr className="bg-[#FEFEFF]">
          <td className="py-4 px-2">Personal Information Protection and Electronic Documents Act, SC 2000, c. 5</td>
          <td className="py-4 px-2">
            “An Act to support and promote electronic commerce by protecting personal information that is collected, used or disclosed in certain circumstances, by providing for the use of electronic means to communicate or record information or transactions….”
            <br /><br /><br />
            Personal Health Information is expressly excluded from Part 1 (“Protection of Personal Information in the Private Sector”).
          </td>
          <td className="column-3"></td>
        </tr>
        <tr className="bg-[#F8F8F9]">
          <td className="py-4 px-2">Digital Privacy Act, SC 2015, c. 32 (Amends PIPEDA) including email, text, or computer data</td>
          <td className="py-4 px-2">Personal Information</td>
          <td className="py-4 px-2"></td>
        </tr>
      </tbody>
    </table>
           
            </div>
          </div>
      </div>
    
    </section>    
    <Link to="/home">
    <Fab  sx={{
           position:"absolute",
           bottom:"1.5rem",
           right:"2rem",
           background:"#27284A"
          }}><FaHome color='whitesmoke' size={25}/></Fab>
    </Link>
    </>
  )
}

export default Policy