import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/StateContext";
import { Button } from "@mui/material";
import AddForm from "../forms/AddForm";
import { FaUserNurse } from "react-icons/fa6";
import {
  ConfigProvider,
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import FilterForm from "../forms/FilterForm";
import { ColorRing } from "react-loader-spinner";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import bg from "../../assets/D2.png";
import SideBarFloat from "../SideBarFloat";
import Navbar from "../navbar";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { URLconstants } from "../../api/constants";
const Nurse = () => {
  const {
    setSection,
    open,
    setOpen,
    handleDownload,
    section,
    fetchData,
    rows,
    handleFilterTable,
    filter,
    total,
    pageSize,
    currentPage,
    setCurrentPage,
    filterFunction,
    filterInfo,
    setFilterInfo,
    visibilityFunction,
  } = useContext(Context);
  const name = section[0].toUpperCase() + section.slice(1, section.length - 1);
  const [loading, setLoading] = useState(true);
  const [filterOn, setFilterOn] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const isEditing = (record) => record.key === editingKey;

  const heading = [
    "Id",
    "Name",
    "Nurse_Id",
    "Email",
    "Contact No.",
    "Created At",
  ];
  const dataArray = rows.map((item) => [
    item.id,
    item?.name,
    item.nurse_id,
    item.email,
    item.contact_number,
    item.createdAt,
  ]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "8%",
      align: "center",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter your name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Nurse_id",
      dataIndex: "nurse_id",
      key: "nurse_id",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="nurse_id"
              rules={[
                {
                  required: true,
                  message: "Please Enter your nurse_id",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Username",
      dataIndex: "email_id",
      key: "email_id",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="email_id"
              rules={[
                {
                  required: true,
                  message: "Please Enter your username",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter your email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Contact_No.",
      dataIndex: "contact_number",
      key: "contact_number",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="contact_number"
              rules={[
                {
                  required: true,
                  message: "Please Enter your contact",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Clinic",
      dataIndex: "clinic",
      key: "clinic",
      render: (item) => item && <p>{item.name}</p>,
      width: "120px",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "12%",
      render: (item) => item.split("T")[0],
    },
    {
      title: "Remove",
      dataIndex: "",
      key: "remove",
      width: "",
      render: (item, record) => (
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            width: "100%",
            display: "flex",
            padding: "8px 0",
            borderRadius: "12px",
            justifyContent: "center",
          }}
          onClick={async () => await visibilityFunction(record.id)}
        >
          <p className="font-poppins">Remove</p>
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      width: `${editingKey !== "" ? "12%" : "6%"}`,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className=" flex gap-2">
            <Typography.Link
              onClick={() => save(record.id)}
              className=" font-poppins"
            >
              Save
            </Typography.Link>

            <Typography.Link
              title="Sure to cancel?"
              className=" font-poppins"
              onClick={() => cancel()}
            >
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link
            className=" font-poppins"
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      name: record.name,
      nurse_id: record.nurse_id,
      email: record.email,
      email_id: record.email_id,
      contact_number: record.contact_number,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const { data } = await axios.put(
        `${URLconstants.BASE_URL}/api/nurses/${id}`,
        {
          data: row,
        }
      );
      await fetchData(currentPage);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setEditingKey("");
    }
  };

  useEffect(() => {
    const fetchDataAndToggleLoading = async () => {
      try {
        setLoading(true);
        setFilterOn(false);
        setCurrentPage(1);
        setFilterInfo({ date: "", fieldName: "", subFilter: "" });
        localStorage.setItem("section", "nurses");
        if (filter) {
          await handleFilterTable();
        } else {
          await fetchData(1);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndToggleLoading();
  }, [section]);

  const handlePageChange = (page) => {
    setCurrentPage(page);

    if (!filterOn) {
      fetchData(page);
    } else {
      filterFunction(
        page,
        filterInfo.fieldName,
        filterInfo.subFilter,
        filterInfo.date
      );
    }
  };

  return (
    <>
      <Navbar />
      <SideBarFloat />
      <section className="scroll-smooth h-screen  bg-[#f0f0f0ea] font-poppins relative overflow-clip">
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>

        <div className="px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100]">
          <div
            className={`flex ${
              open ? "lg:w-7/12 md:w-7/12" : ""
            } pt-10 pb-10 mt-[4.5rem] justify-between lg:items-center md:items-end items-start lg:flex-row md:flex-row flex-col relative`}
          >
            <div className="flex gap-4 md:items-center lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
              <div>
                <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                  {open ? `Add ${name}` : `${name} List`}{" "}
                </p>
                <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
              </div>

              <Link to="/home">
                <Button
                  onClick={() => setSection("dash")}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <p className="lg:text-[18px] text-slate-500 font-poppins">
                    Home
                  </p>
                </Button>
              </Link>
            </div>
            {open ? (
              <Button
                sx={{ borderRadius: "1.5rem", textTransform: "none" }}
                onClick={() => setOpen((prev) => !prev)}
                className="w-[120px] h-[52px]  gap-2 border border-slate-400 rounded-[2.5rem] flex items-center justify-center shadow-[inset_-2px_1px_10px_#9de6f5]"
              >
                <p className="font-poppins text-[18px] text-slate-700">Close</p>
                <IoClose size={25} className="text-slate-900" />
              </Button>
            ) : (
              <div className="flex relative z-[100] items-center gap-4 lg:mt-0 mt-8">
                <Button
                  sx={{ borderRadius: "1.5rem", textTransform: "none" }}
                  onClick={() => setOpen((prev) => !prev)}
                  className="w-[100px] h-[52px]  gap-2 border border-slate-400 rounded-[2.5rem] flex items-center justify-center shadow-[inset_-2px_1px_10px_#9de6f5]"
                >
                  <p className="font-poppins text-[16px] text-slate-700">Add</p>
                  <FaUserNurse size={20} className="text-slate-800" />
                </Button>
                <div className=" rounded-[1.5rem]  bg-slate-900">
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1.5rem",
                      padding: "10px",
                    }}
                    onClick={() => handleDownload(dataArray, heading)}
                  >
                    <p className="lg:text-[18px] font-poppins font-light text-slate-200  ">
                      Download Excel
                    </p>
                  </Button>
                </div>
              </div>
            )}
          </div>
          {open ? (
            <AddForm />
          ) : (
            <>
              <FilterForm setFilterOn={setFilterOn} />

              <div className="lg:py-8 md:py-8 pt-4 pb-[6rem] flex items-center justify-center">
                {loading ? (
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#27284a",
                      "#3c4a74",
                      "#4f6ea0",
                      "#5f96cd",
                      "#6ec0fa",
                    ]}
                  />
                ) : (
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBorderRadius: 20,
                          headerBg: "#cbd5e1",
                          headerSortHoverBg: "",
                        },
                      },
                    }}
                  >
                    <Form form={form} component={false}>
                      <Table
                        dataSource={rows}
                        columns={columns}
                        className="rounded-[1.5rem]   text-slate-800 shadow-lg shadow-slate-300  "
                        pagination={{
                          current: currentPage,
                          pageSize: pageSize,
                          showSizeChanger: false,
                          total: total,
                          onChange: handlePageChange,
                          style: {
                            padding: "0 12px",
                            fontFamily: "Poppins",
                          },
                        }}
                        scroll={{ y: 450, x: "calc(650px + 45%)" }}
                      />
                    </Form>
                  </ConfigProvider>
                )}
              </div>
            </>
          )}
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default Nurse;
