import Home from "./pages/Home";
import Login from "./pages/Login";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Hospital from "./components/lists/Hospital";
import Clinic from "./components/lists/Clinic";
import Organisation from "./components/lists/Organisation";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import Contact from "./pages/Contact";
import Appointment from "./components/lists/Appointment";
import Staff from "./components/lists/StaffList";
import Doctors from "./components/lists/Doctor";
import Nurse from "./components/lists/Nurse";
import Transcript from "./components/lists/Transcript";
import ManualTranscript from "./components/lists/ManualTranscripts";
import NextTopLoader from "nextjs-toploader";
import AddRecordings from "./components/forms/AddRecordings";
import SignatureUpload from "./components/forms/SignatureUpload";
import TranscriptUpload from "./components/forms/TranscriptUpload";
import Workpool from "./components/features/Workpool";
import TranscripterJobs from "./components/lists/TranscripterJobs";

function App() {
  const ProtectedRoute = ({ children }) => {
    const user = localStorage.getItem("isAuth");
    if (!user) {
      // user is not authenticated
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
        <NextTopLoader />
        <Routes>
          <Route path="/">
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hospital"
              element={
                <ProtectedRoute>
                  <Hospital />
                </ProtectedRoute>
              }
            />
            <Route
              path="/clinic"
              element={
                <ProtectedRoute>
                  <Clinic />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organisation"
              element={
                <ProtectedRoute>
                  <Organisation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/appointment"
              element={
                <ProtectedRoute>
                  <Appointment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/terms"
              element={
                <ProtectedRoute>
                  <Terms />
                </ProtectedRoute>
              }
            />
            <Route
              path="/policy"
              element={
                <ProtectedRoute>
                  <Policy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact"
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doctor"
              element={
                <ProtectedRoute>
                  <Doctors />
                </ProtectedRoute>
              }
            />
            <Route
              path="/staff"
              element={
                <ProtectedRoute>
                  <Staff />
                </ProtectedRoute>
              }
            />
            <Route
              path="/nurse"
              element={
                <ProtectedRoute>
                  <Nurse />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transcript"
              element={
                <ProtectedRoute>
                  <Transcript />
                </ProtectedRoute>
              }
            />
            <Route
              path="/appointment"
              element={
                <ProtectedRoute>
                  <Appointment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manualTranscript"
              element={
                <ProtectedRoute>
                  <ManualTranscript />
                </ProtectedRoute>
              }
            />
            <Route
              path="/addRecording"
              element={
                <ProtectedRoute>
                  <AddRecordings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/signatureUpload"
              element={
                <ProtectedRoute>
                  <SignatureUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transcriptUpload"
              element={
                <ProtectedRoute>
                  <TranscriptUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/workpool"
              element={
                <ProtectedRoute>
                  <Workpool />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transcripterJobs"
              element={
                <ProtectedRoute>
                  <TranscripterJobs />
                </ProtectedRoute>
              }
            />

            <Route index element={<Login />} />
          </Route>
        </Routes>
      </div>
    </LocalizationProvider>
  );
}

export default App;
