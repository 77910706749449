import React, { useContext, useState } from "react";
import { Button, Drawer, Fab } from "@mui/material";
import { MdAccountCircle, MdAssignmentAdd } from "react-icons/md";
import { Context } from "../context/StateContext";
import { HiMenu } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { FaCalendarCheck, FaClipboardList } from "react-icons/fa6";
import { FiLogOut } from "react-icons/fi";
import logo from "../assets/Mdlogo.png";
import logofull from "../assets/MDLogoFull.png";
import { TbReportMedical } from "react-icons/tb";
import { motion } from "framer-motion";
import { CgOrganisation } from "react-icons/cg";
import { GiHospitalCross } from "react-icons/gi";
import { FaClinicMedical } from "react-icons/fa";
import { FaUserNurse } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { FaUserDoctor } from "react-icons/fa6";
import { IoCheckbox } from "react-icons/io5";
const SideBarFloat = () => {
  const [baropen, setBarOpen] = useState(false);

  const {
    setSection,
    setFilter,
    setLoading,
    setOpen,
    userType,
    setCurrentPage,
    section,
  } = useContext(Context);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(logOut());
    navigate("/", { replace: true });
  };
  const lists = [
    <Button
      key={0}
      onClick={() => {
        setSection("organisations");
        setFilter("");
        setOpen(false);
        setLoading(false);
        setCurrentPage(1);
        navigate("/organisation");
      }}
      sx={{
        textTransform: "none",

        borderRadius: "1.5rem",
      }}
    >
      <div className="flex flex-col gap-1">
        <div className={`flex items-center gap-2`}>
          <CgOrganisation size={25} className={`text-[#f0f0f0c2]`} />
          <p className={`text-[19px] text-[#f0f0f0c2] font-poppins`}>
            Organisation List
          </p>
        </div>
        {section === "organisations" && (
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            viewport={{ once: true }}
            className=" translate-x-8 border border-slate-200 w-[60%]"
          ></motion.div>
        )}
      </div>
    </Button>,
    <Button
      key={1}
      onClick={() => {
        navigate("/hospital");
        setSection("hospitals");
        setFilter("");
        setOpen(false);
        setLoading(false);
        setCurrentPage(1);
      }}
      sx={{
        textTransform: "none",

        borderRadius: "1.5rem",
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2">
          <GiHospitalCross size={25} color="#f0f0f0c2" />
          <p className="text-[19px] text-[#f0f0f0c2] font-poppins">
            Hospital List
          </p>
        </div>
        {section === "hospitals" && (
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            viewport={{ once: true }}
            className=" translate-x-8 border border-slate-200 w-[60%]"
          ></motion.div>
        )}
      </div>
    </Button>,
    <Button
      key={2}
      onClick={() => {
        setSection("clinics");
        setFilter("");
        setOpen(false);
        setLoading(false);
        setCurrentPage(1);
        navigate("/clinic");
      }}
      sx={{
        textTransform: "none",

        borderRadius: "1.5rem",
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2 w-full">
          <FaClinicMedical size={25} color="#f0f0f0c2" />
          <p className="text-[19px] text-[#f0f0f0c2] font-poppins">
            Clinic List
          </p>
        </div>
        {section === "clinics" && (
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            viewport={{ once: true }}
            className=" translate-x-8 border border-slate-200 w-[60%]"
          ></motion.div>
        )}
      </div>
    </Button>,
    <Button
      key={3}
      onClick={() => {
        setSection("doctors");
        setFilter("");
        setOpen(false);
        setLoading(false);
        setCurrentPage(1);
        navigate("/doctor");
      }}
      sx={{
        textTransform: "none",

        borderRadius: "1.5rem",
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2 w-full">
          <FaUserDoctor size={25} color="#f0f0f0c2" />
          <p className="text-[19px] text-[#f0f0f0c2] font-poppins">
            Doctor List
          </p>
        </div>
        {section === "doctors" && (
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            viewport={{ once: true }}
            className=" translate-x-8 border border-slate-200 w-[60%]"
          ></motion.div>
        )}
      </div>
    </Button>,
    <Button
      key={4}
      onClick={() => {
        setSection("nurses");
        setFilter("");
        setOpen(false);
        setLoading(false);
        setCurrentPage(1);
        navigate("/nurse");
      }}
      sx={{
        textTransform: "none",

        borderRadius: "1.5rem",
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2 w-full">
          <FaUserNurse size={25} color="#f0f0f0c2" />
          <p className="text-[19px] text-[#f0f0f0c2] font-poppins">
            Nurse List
          </p>
        </div>
        {section === "nurses" && (
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            viewport={{ once: true }}
            className=" translate-x-8 border border-slate-200 w-[60%]"
          ></motion.div>
        )}
      </div>
    </Button>,
    <Button
      key={5}
      onClick={() => {
        setSection("staffs");
        setFilter("");
        setOpen(false);
        setLoading(false);
        setCurrentPage(1);
        navigate("/staff");
      }}
      sx={{
        textTransform: "none",

        borderRadius: "1.5rem",
      }}
    >
      <div className=" flex flex-col gap-1">
        <div className="flex items-center gap-2 w-full">
          <IoIosPeople size={25} color="#f0f0f0c2" />
          <p className="text-[19px] text-[#f0f0f0c2] font-poppins">
            Staff List
          </p>
        </div>
        {section === "staffs" && (
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              delay: 0,
            }}
            viewport={{ once: true }}
            className=" translate-x-8 border border-slate-200 w-[60%]"
          ></motion.div>
        )}
      </div>
    </Button>,
  ];
  const listSub = lists.slice(userType);
  return (
    <div className="">
      <div>
        <Fab
          sx={{
            position: "absolute",
            bottom: "1.5rem",
            right: "2rem",
            background: "#27284A",
          }}
          onClick={() => setBarOpen((prev) => !prev)}
        >
          <HiMenu color="whitesmoke" size={25} />
        </Fab>
      </div>

      <Drawer
        PaperProps={{
          style: {
            background: "#27284A",

            height: "100vh",
            // overflowY: "hidden",
            scrollBehavior: "smooth",
            scrollbarColor: "#000",
          },
        }}
        open={baropen}
        onClose={() => setBarOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },

              boxShadow: "none",
            },
          },
        }}
      >
        {" "}
        <div className="font-poppins flex h-screen justify-between lg:w-[320px]  md:w-[320px] w-[300px]   flex-col px-8 py-8 ">
          <div className="flex flex-col">
            <div className=" flex flex-col items-center gap-4">
              <Button
                onClick={() => {
                  setSection("dash");
                  navigate("/home");
                }}
              >
                <div className="w-[100px] lg:hidden  h-[100px] border flex items-center justify-center border-slate-500 rounded-[100%] ">
                  <img className="" src={logo} alt="mdlogo" />
                </div>
                <img
                  className="lg:block w-[15rem] hidden"
                  src={logofull}
                  alt="mdlogo"
                />
              </Button>
              <div className="w-[60%] rounded-[50%] border border-slate-400 "></div>
            </div>
            <div className="flex flex-col items-start w-full gap-2 my-9">
              <Button
                onClick={() => {
                  setSection("dash");
                  setCurrentPage(1);
                  setFilter("");
                  setOpen(false);
                  setLoading(false);
                }}
                sx={{
                  textTransform: "none",

                  borderRadius: "1.5rem",
                }}
              >
                <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <MdAccountCircle size={25} color="#f0f0f0c2" />
                    <Link
                      to="/home"
                      className="text-[20px] font-poppins text-[#f0f0f0c2]"
                    >
                      Dashboard
                    </Link>
                  </div>
                  {section === "dash" && (
                    <motion.div
                      initial={{
                        x: -100,
                      }}
                      animate={{
                        x: 0,
                      }}
                      transition={{
                        duration: 1,
                        delay: 0,
                      }}
                      viewport={{ once: true }}
                      className=" translate-x-8 border border-slate-200 w-[60%]"
                    ></motion.div>
                  )}
                </div>
              </Button>
              {userType < 4 && (
                <Button
                  onClick={() => {
                    setSection("transcript");
                    setCurrentPage(1);
                    setOpen(false);
                    setLoading(false);
                    setFilter("");
                  }}
                  sx={{
                    textTransform: "none",

                    borderRadius: "1.5rem",
                  }}
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <TbReportMedical size={25} color="#f0f0f0c2" />
                      <Link
                        to="/transcript"
                        className="text-[20px] font-poppins text-[#f0f0f0c2]"
                      >
                        Transcription List
                      </Link>
                    </div>
                    {section === "transcript" && (
                      <motion.div
                        initial={{
                          x: -100,
                        }}
                        animate={{
                          x: 0,
                        }}
                        transition={{
                          duration: 1,
                          delay: 0,
                        }}
                        viewport={{ once: true }}
                        className=" translate-x-8 border border-slate-200 w-[60%]"
                      ></motion.div>
                    )}
                  </div>
                </Button>
              )}
              {(userType === "4" ||
                userType === "2" ||
                userType === "0" ||
                userType === "6") && (
                <Button
                  onClick={() => {
                    setSection("manualTranscript");
                    setFilter("");
                    setOpen(false);
                    setLoading(false);
                    setCurrentPage(1);
                  }}
                  sx={{
                    textTransform: "none",

                    borderRadius: "1.5rem",
                  }}
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <FaClipboardList size={25} color="#f0f0f0c2" />
                      <Link
                        to="/manualTranscript"
                        className="text-[20px] font-poppins text-[#f0f0f0c2] lg:block md:block hidden"
                      >
                        {userType === "6" ? "Transcripts" : "Manual Transcript"}
                      </Link>
                      <p className="text-[20px] font-poppins text-[#f0f0f0c2] lg:hidden md:hidden ">
                        MT List
                      </p>
                    </div>
                    {section === "manualTranscript" && (
                      <motion.div
                        initial={{
                          x: -100,
                        }}
                        animate={{
                          x: 0,
                        }}
                        transition={{
                          duration: 1,
                          delay: 0,
                        }}
                        viewport={{ once: true }}
                        className=" translate-x-8 border border-slate-200 w-[60%]"
                      ></motion.div>
                    )}
                  </div>
                </Button>
              )}
              {userType !== "4" && listSub}

              {userType < 4 && (
                <Button
                  onClick={() => {
                    setSection("appoints");
                    setFilter("");
                    setOpen(false);
                    setLoading(false);
                    setCurrentPage(1);
                  }}
                  sx={{
                    textTransform: "none",

                    borderRadius: "1.5rem",
                  }}
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2 w-full">
                      <FaCalendarCheck size={25} color="#f0f0f0c2" />
                      <Link
                        to="/appointment"
                        className="text-[19px] text-[#f0f0f0c2] font-poppins"
                      >
                        Appointment List
                      </Link>
                    </div>
                    {section === "appoints" && (
                      <motion.div
                        initial={{
                          x: -100,
                        }}
                        animate={{
                          x: 0,
                        }}
                        transition={{
                          duration: 1,
                          delay: 0,
                        }}
                        viewport={{ once: true }}
                        className=" translate-x-8 border border-slate-200 w-[60%]"
                      ></motion.div>
                    )}
                  </div>
                </Button>
              )}
              {userType === "7" && (
                <Button
                  onClick={() => {
                    setSection("jobs");
                    setFilter("");
                    setOpen(false);
                    setLoading(false);
                    setCurrentPage(1);
                  }}
                  sx={{
                    textTransform: "none",

                    borderRadius: "1.5rem",
                  }}
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2 w-full">
                      <MdAssignmentAdd size={25} color="#f0f0f0c2" />
                      <Link
                        to="/transcripterJobs"
                        className="text-[19px] text-[#f0f0f0c2] font-poppins"
                      >
                        Transcript Jobs
                      </Link>
                    </div>
                    {section === "jobs" && (
                      <motion.div
                        initial={{
                          x: -100,
                        }}
                        animate={{
                          x: 0,
                        }}
                        transition={{
                          duration: 1,
                          delay: 0,
                        }}
                        viewport={{ once: true }}
                        className=" translate-x-8 border border-slate-200 w-[60%]"
                      ></motion.div>
                    )}
                  </div>
                </Button>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full">
            <div className="text-[#f0f0f0c2] flex mb-2 w-full justify-center">
              <Link
                to="/policy"
                className="w-full flex justify-center text-[15px]"
              >
                Privacy Policy
              </Link>
              <p className="mx-2">|</p>
              <Link
                to="/terms"
                className="w-full flex justify-center text-[15px]"
              >
                Terms of Use
              </Link>
            </div>
            <div className="w-[100%] rounded-[50%] border border-slate-400 " />

            <Button
              onClick={() => handleLogOut()}
              sx={{
                width: "100%",
                padding: "8px",
                borderRadius: "12px",
                marginTop: "12px",
              }}
            >
              <div className="flex items-center gap-4">
                <p className="font-poppins text-[18px] text-slate-300">
                  LogOut
                </p>
                <FiLogOut size={25} className="text-slate-300" />
              </div>
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default SideBarFloat;
