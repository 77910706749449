import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/StateContext";
import { Button, Autocomplete, Box, TextField } from "@mui/material";
import { ConfigProvider, DatePicker, Dropdown, Table } from "antd";
import axios from "axios";
import { ColorRing, Grid } from "react-loader-spinner";
import File from "../features/File";
import { MdOutlineDescription } from "react-icons/md";
import MedicalTranscribe from "../forms/MedicalTranscribe";
import { IoClose, IoMenu, IoTrashBin } from "react-icons/io5";
import logo from "../../assets/MDLogoFull.png";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../navbar";
import SideBarFloat from "../SideBarFloat";
import bg from "../../assets/D2.png";
import dayjs from "dayjs";
import { URLconstants } from "../../api/constants";

const Transcript = () => {
  const [body, setBody] = useState([]);
  const [toggle, setToggle] = useState(false);
  const {
    downloadFile,
    setSection,
    open,
    handleDownload,
    setFilter,
    setOpen,
    loading,
    setLoading,
    userType,
    user,
    section,
    rowSelection,
    selectedRowKeys,
    selectedUrls,
    setSelectedRowKeys,
    downloadAndAddToZip,
  } = useContext(Context);
  const heading = [
    "Id",
    "Job_Id",
    "Pdf_url",
    "Recording_url",
    "Doctor Name",
    "Created At",
  ];

  const [filterInfo, setFilterInfo] = useState({
    name: "",
    hospital: "",
    fromDate: "",
    toDate: "",
    file: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const [adminJobs, setAdminJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dowloading, setDownloading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [file, setFile] = useState("");
  const [logs, setLogs] = useState({
    openLogs: false,
    auditLogs: [],
  });
  const [transcripters, setTranscripters] = useState([]);
  const [transcriber, setTranscriber] = useState({
    transcriber_id: null,
    open: false,
    job_info: null,
  });
  const [upload, setUpload] = useState({
    id: "",
    destination: "",
    open: false,
  });
  const date = new Date();
  const month =
    date.getUTCMonth().toString().length === 1
      ? "0" + (date.getUTCMonth() + 1).toString()
      : (date.getUTCMonth() + 1).toString();
  const dateToday =
    date.getUTCDate().toString().length === 1
      ? "0" + date.getUTCDate().toString()
      : date.getUTCDate().toString();
  const year = date.getFullYear();

  const columns = [
    {
      title: "",
      dataIndex: "options",
      key: "options",
      width: "70px",
      align: "center",
      render: (item, records) => {
        const items = [
          {
            key: "0",
            label: (
              <div
                className="py-2 px-2"
                onClick={(e) => {
                  //open https://word-editor-website.capricontechnology.com/ in a new tab

                  if (records?.word_url && records.word_url.length > 1) {
                    const url = encodeURIComponent(records?.word_url);
                    const file_name =
                      records?.word_url.split("/")[
                        records?.word_url.split("/").length - 1
                      ];
                    console.log("FIlename", file_name);
                    window.open(
                      `https://word-editor-website.capricontechnology.com?file=${file_name}&manual=false`,
                      "_blank"
                    );
                  } else if (
                    records?.transcript_word_url &&
                    records.transcript_word_url.length > 1
                  ) {
                    const url = encodeURIComponent(
                      records?.transcript_word_url
                    );
                    const file_name =
                      records?.transcript_word_url.split("/")[
                        records?.transcript_word_url.split("/").length - 1
                      ];
                    console.log("FIlename", file_name);
                    window.open(
                      `https://word-editor-website.capricontechnology.com?file=${file_name}&manual=false`,
                      "_blank"
                    );
                  }
                }}
              >
                <p className=" font-poppins text-slate-700 m-0">Edit File</p>
              </div>
            ),
          },
          {
            key: "1",
            label: (
              <div
                className="py-2 px-2"
                onClick={(e) => {
                  const url = decodeURIComponent(records?.recording_url);
                  const fileName = url.split("/").pop(); // Get the last segment after the last '/'
                  const desiredPortion = fileName.split(".m4a")[0]; // Remove the '.m4a' extension
                  const finalName = `${records?.doctor?.hospital?.name}_${
                    records?.recording_time.split("T")[0]
                  }_${desiredPortion}`;
                  if (records.word_url) {
                    downloadFile(e, records?.word_url, `${finalName}.docx`);
                  } else if (records.transcript_word_url) {
                    downloadFile(
                      e,
                      records?.transcript_word_url,
                      `${finalName}.docx`
                    );
                  }
                }}
              >
                <p className=" font-poppins text-slate-700 m-0">
                  Download Report
                </p>
              </div>
            ),
          },

          {
            key: "2",
            label: (
              <div
                className="py-2 px-2"
                onClick={async () => {
                  if (records.recording_url) {
                    try {
                      const { data, status } = await axios.post(
                        `${URLconstants.BASE_URL_FARM}/doctor/change-audio-format`,
                        {
                          audio_url: records.recording_url,
                          job_id: records.job_id,
                          audio_format: "ogg",
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );
                      if (status === 200) {
                        handleListenRecording(data?.audio_url);
                      }
                    } catch (error) {
                      console.log(error);
                      toast.error(
                        <p className=" text-slate-700 font-poppins">
                          {error.message}
                        </p>,
                        {
                          position: "bottom-center",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    }
                  } else {
                    toast.info(
                      <p className=" text-slate-700 font-poppins">
                        No recording present!!
                      </p>,
                      {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }
                }}
              >
                <p className="font-poppins text-slate-700">Listen Recording</p>
              </div>
            ),
          },
          {
            key: "3",
            label: (
              <div
                className="py-2 px-2"
                onClick={(e) => {
                  downloadTextAsWord(e, records);
                }}
              >
                <p className=" font-poppins text-slate-700">See Transcript</p>
              </div>
            ),
          },
          {
            key: "4",
            label: (
              <div
                className="py-2 px-2"
                onClick={(prev) => {
                  const path = records.word_url.substring(
                    records.word_url.indexOf("reports")
                  );

                  setUpload({ id: records.id, destination: path, open: true });
                }}
              >
                <p className=" font-poppins text-slate-700">Upload Report</p>
              </div>
            ),
          },
          {
            key: "5",
            label: (
              <div
                className="py-2 px-2"
                onClick={async (e) => {
                  if (records.recording_url) {
                    const url = decodeURIComponent(records.recording_url);
                    const fileName = url.split("/").pop(); // Get the last segment after the last '/'
                    const desiredPortion = fileName.split(".m4a")[0]; // Remove the '.m4a' extension
                    const finalName = `${records?.doctor?.hospital?.name}_${
                      records?.recording_time.split("T")[0]
                    }_${desiredPortion}`;

                    try {
                      const { data, status } = await axios.post(
                        `${URLconstants.BASE_URL_FARM}/doctor/change-audio-format`,
                        {
                          audio_url: records.recording_url,
                          job_id: records.job_id,
                          audio_format: "ogg",
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );
                      if (status === 200) {
                        downloadFile(e, data.audio_url, `${finalName}.ogg`);
                      }
                    } catch (error) {
                      console.log(error);
                      toast.error(
                        <p className=" text-slate-700 font-poppins">
                          {error.message}
                        </p>,
                        {
                          position: "bottom-center",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    }
                  } else {
                    toast.info(
                      <p className=" text-slate-700 font-poppins">
                        No recording present!!
                      </p>,
                      {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }
                }}
              >
                <p className=" font-poppins text-slate-700">
                  Download Recording
                </p>
              </div>
            ),
          },

          {
            key: "6",
            label: (
              <div
                className="py-2 px-2"
                onClick={async (e) => {
                  try {
                    const { data, status } = await axios.get(
                      `${URLconstants.BASE_URL}/api/jobs?populate=*&filters[transcript][id]=${records.id}`
                    );
                    if (status === 200) {
                      setLogs((prev) => ({
                        openLogs: true,
                        auditLogs: data?.data[0],
                      }));
                    }
                  } catch (error) {
                    console.log(error);
                    toast.error(
                      <p className=" text-slate-700 font-poppins">
                        {error.message}
                      </p>,
                      {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }
                }}
              >
                <p className=" font-poppins text-slate-700">View Status</p>
              </div>
            ),
          },
        ];
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoMenu size={20} className="" />
            </Button>
          </Dropdown>
        );
      },
    },

    {
      title: "File",
      dataIndex: "counter_id",
      key: "counter_id",
      width: "8%",
      render: (item) => item && <p className="">{item}</p>,
    },
    {
      title: "Status",
      dataIndex: "job",
      key: "job",
      width: "",
      render: (item) => item && <p className="">{item.status}</p>,
    },
    {
      title: "Transcripter",
      dataIndex: "transcripter",
      key: "transcripter",
      width: "10%",
      render: (item, record) =>
        record.job && (
          <p className="">{record.job?.assigned_transcripter?.name}</p>
        ),
    },

    {
      title: "Doctor",
      dataIndex: "doctor",
      key: "doctor",
      width: "",
      render: (item) =>
        item && (
          <Button
            sx={{
              textTransform: "none",
              textAlign: "left",
              paddingX: "0",
            }}
            onClick={() => {
              setSection("doctors");
              setFilter(item.name);
            }}
          >
            <p className="font-poppins text-slate-800">
              {item.name},{item.qualifications}
            </p>
          </Button>
        ),
    },
    {
      title: "Hospital",
      dataIndex: "hospital",
      key: "hospital",
      width: "",
      align: "center",
      render: (item, record) =>
        record?.doctor?.hospital?.name ? (
          <p className=" text-slate-700">{record?.doctor?.hospital?.name}</p>
        ) : (
          <p className=" text-slate-700">N.A</p>
        ),
    },
    {
      title: "Clinic",
      dataIndex: "clinic",
      key: "clinic",
      width: "",
      align: "center",
      render: (item, record) =>
        item ? (
          <p className=" text-slate-700">{item?.name}</p>
        ) : (
          <p className=" text-slate-700">N.A</p>
        ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "",
      align: "center",
      render: (item) => {
        if (!item) {
          return <p>--:--</p>;
        } else {
          if (item.includes("m")) {
            return <p>{item}</p>;
          } else {
            const secs = Math.floor(item / 1000);
            const minutes = Math.floor(secs / 60);
            const remsecs = secs % 60;
            return <p>{`${minutes}m${remsecs}s`}</p>;
          }
        }
      },
    },
    {
      title: "Tat",
      dataIndex: "tat",
      key: "tat",
      width: "",
      render: (item, record) => {
        const hour = record.tatElapsed;
        return record.tat ? (
          <p
            className={`text-slate-200 p-3  text-center rounded-lg  ${
              hour < 8
                ? "bg-red-600"
                : hour >= 8 && hour < 16
                ? "bg-yellow-400 text-slate-600"
                : "bg-green-600"
            }`}
          >
            {record?.tat}
          </p>
        ) : (
          <p className=" text-slate-700">N.A</p>
        );
      },
    },
    {
      title: "Tat Left",
      dataIndex: "tat_left",
      key: "tat_left",
      width: "",
      render: (item, record) => {
        const hours = Math.floor(record.tatElapsed);
        const millisecs = record.tatElapsed * 60 * 60 * 1000;
        const minutes = Math.floor(
          (millisecs % (60 * 60 * 1000)) / (1000 * 60)
        );
        return record.tatElapsed ? (
          <p className="text-slate-700 p-3 bg-slate-300  text-center rounded-lg">
            {record.tatElapsed <= 0 ? "00:00" : `${hours}:${minutes}`}
          </p>
        ) : (
          <p className=" text-slate-700">N.A</p>
        );
      },
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "",
      render: (item) => {
        const dayTime = new Date(item).toLocaleString("en-US", {
          timeZone: "America/New_York",
        });
        return dayTime;
      },
    },
    ...(userType === "0"
      ? [
          {
            title: "Assign",
            dataIndex: "assign",
            key: "assign",
            render: (_, record) => (
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  display: "flex",
                  padding: "8px 0",
                  borderRadius: "12px",
                  justifyContent: "center",
                }}
                onClick={async (e) => {
                  setTranscripters([]);
                  try {
                    const { data, status } = await axios.get(
                      `${URLconstants.BASE_URL}/api/jobs?populate=*&filters[transcript][id]=${record.id}`
                    );
                    console.log(data);
                    if (status === 200) {
                      setTranscriber((prev) => ({
                        ...prev,
                        job_info: data?.data[0],
                        open: true,
                      }));
                      fetchTranscripters(
                        e,
                        data?.data[0]?.assigned_transcripter?.id
                      );
                    }
                  } catch (error) {
                    console.log(error);
                    toast.error(
                      <p className=" text-slate-700 font-poppins">
                        {error.message}
                      </p>,
                      {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }
                }}
              >
                <p className="font-poppins">Assign</p>
              </Button>
            ),
          },
        ]
      : []),
    // {
    //   title: "Remove",
    //   dataIndex: "remove",
    //   key: "remove",
    //   width: "100px",
    //   render: (item, record) => (
    //     <Button
    //       variant="outlined"
    //       sx={{
    //         textTransform: "none",
    //         width: "100%",
    //         display: "flex",
    //         padding: "8px 0",
    //         borderRadius: "12px",
    //         justifyContent: "center",
    //       }}
    //       onClick={async () => {
    //         const { data } = await axios.put(
    //           `${URLconstants.BASE_URL}/api/transcripts/${record?.id}`,
    //           {
    //             data: {
    //               visibility: false,
    //             },
    //           }
    //         );
    //         await fetchScripts(currentPage, pageSize);
    //         console.log(data);
    //       }}
    //     >
    //       <IoTrashBin size={22} className="" />
    //     </Button>
    //   ),
    // },
  ];

  const dataArray = body.map((item) => [
    item.id,
    item.job_id,
    item.word_url,
    item.recording_url,
    item.doctor.name,
    item.createdAt,
  ]);

  function getTATHours(hourVal) {
    const hourArr = hourVal.split(":");

    let finalValue =
      hourArr.length > 0 && Number(hourArr[0]) + Number(hourArr[1]) / 60;
    return finalValue;
  }
  const fetchAdminJob = async () => {
    try {
      const { data, status } = await axios.get(
        `${URLconstants.BASE_URL}/api/jobs?populate=*`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      setAdminJobs(data.data);
      return status;
    } catch (error) {
      console.log(error);

      toast.error(
        <p className=" font-poppins text-slate-700">Error Fetching Data!!!</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  //function to dowload transcript in text format
  const downloadTextAsWord = (e, record) => {
    e.preventDefault();
    try {
      toast.info(
        <p className=" text-slate-700 font-poppins">Download in progress!!</p>,
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      const url = decodeURIComponent(record.recording_url);
      const fileName = url.split("/").pop(); // Get the last segment after the last '/'
      const desiredPortion = fileName.split(".m4a")[0]; // Remove the '.m4a' extension
      const finalName = `${record.doctor?.hospital?.name}_${
        record.recording_time.split("T")[0]
      }_${desiredPortion}`;
      for (const log of record.logs) {
        if (log.key === "transcription") {
          const blob = new Blob([log.value], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${finalName}.txt`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  //download all function
  const handleDownloadAll = async (e) => {
    e.preventDefault();
    try {
      toast.info(
        <p className=" text-slate-700 font-poppins">Download in progress!!</p>,
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      const zip = new JSZip();
      setDownloading(true);
      for (const item of selectedUrls) {
        const { recording_url, word_url, doctor, recording_time, job_id } =
          item;

        const fileName = decodeURIComponent(recording_url).split("/").pop();
        const desiredPortion = fileName.split(`.m4a`)[0];
        const finalName = `${doctor?.hospital?.name}_${
          recording_time.split("T")[0]
        }_${desiredPortion}`;

        if (recording_url) {
          const { data } = await axios.post(
            `${URLconstants.BASE_URL_FARM}/doctor/change-audio-format`,
            {
              audio_url: recording_url,
              job_id: job_id,
              audio_format: "ogg",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          await downloadAndAddToZip(zip, data?.audio_url, "ogg", finalName);
        }
        if (word_url) {
          await downloadAndAddToZip(zip, word_url, "docx", finalName);
        }
      }

      // Generate a blob containing the zip file
      zip.generateAsync({ type: "blob" }).then((zipBlob) => {
        // Save the zip blob as a file
        setDownloading(false);
        saveAs(zipBlob, "downloaded_files.zip");
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedRowKeys([]);
    }
  };

  const handleListenRecording = (recrd) => {
    const newTab = window.open("", "_blank");
    newTab.document.write(`
      <html>
        <head>
          <title>Audio Player</title>
        </head>
        <style>
        body {
          font-family: 'Arial', sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          margin: 0;
          background-color:rgb(30,41,59);
        }
        audio {
          width: 40%;
        }
        div{
          display:flex;
          flex-direction:column;
          gap:5rem;
          align-items:center
        }
        img{
          width:40%
        }
      </style>
        <body>
          <div>
          <img src="${logo}" alt="" />
          <audio controls autoplay>
          <source src="${recrd}" type="audio/ogg">
          Your browser does not support the audio element.
          </audio>
          </div>
        </body>
      </html>
    `);
  };

  //function to upload word and replace the current
  const handleWordUpload = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("transcript", file);
      formData.append("destination", upload.destination);
      formData.append("id", upload.id);
      setLoading(true);
      const response = await axios.post(
        `${URLconstants.BASE_URL}/api/upload-transcript`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
      setUpload({ open: false, id: "", destination: "" });
    }
  };

  //trancsript list filter
  const handleFilter = async (e, pageSize) => {
    e.preventDefault();

    try {
      setCurrentPage(1);
      setLoading(true);
      if (
        e.target[0].value ||
        e.target[1].value ||
        e.target[2].value ||
        e.target[3].value ||
        e.target[4].value
      ) {
        setFilterInfo({
          name: e.target[0].value,
          hospital: e.target[1].value,
          fromDate: userType === "6" ? e.target[1].value : e.target[2].value,
          toDate: userType === "6" ? e.target[2].value : e.target[3].value,
          file: userType === "6" ? e.target[3].value : e.target[4].value,
        });
        userType === "6"
          ? await fetchScripts(
              1,
              pageSize,
              e.target[0].value,
              "",
              e.target[1].value,
              e.target[2].value,
              e.target[3].value
            )
          : await fetchScripts(
              1,
              pageSize,
              e.target[0].value,
              e.target[1].value,
              e.target[2].value,
              e.target[3].value,
              e.target[4].value
            );
      } else {
        await fetchScripts(1, pageSize);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  //filter and fetch function of transcript list called by handlefilter
  const fetchScripts = async (
    page,
    pageSize,
    name,
    hospital,
    fromDate,
    toDate,
    file
  ) => {
    let createdAtFilterStart = `${year}-${month}-${dateToday}T00:00:00.000Z`;
    let createdAtFilterEnd = `${year}-${month}-${dateToday}T23:59:59.999Z`;
    let dateFilter = `&filters[createdAt][$gte]=${createdAtFilterStart}&filters[createdAt][$lte]=${createdAtFilterEnd}`;

    if (fromDate && toDate) {
      createdAtFilterStart = `${fromDate}T00:00:00.000Z`;
      createdAtFilterEnd = `${toDate}T23:59:59.999Z`;
      dateFilter = `&filters[createdAt][$gte]=${createdAtFilterStart}&filters[createdAt][$lte]=${createdAtFilterEnd}`;
    } else if (fromDate) {
      createdAtFilterStart = `${fromDate}T00:00:00.000Z`;
      dateFilter = `&filters[createdAt][$gte]=${createdAtFilterStart}`;
    } else if (toDate) {
      createdAtFilterEnd = `${toDate}T23:59:59.999Z`;
      dateFilter = `&filters[createdAt][$lte]=${createdAtFilterEnd}`;
    }

    const { data, status } = await axios.get(
      `${URLconstants.fetchTranscripts()}?populate[clinic][populate][0]=clinic&populate[doctor][populate][0]=hospital,doctor${
        userType !== "0" && "&populate[doctor][populate][0]=hospital"
      }${
        userType === "1"
          ? `&filters[doctor][hospital][organisation][id]=${user.id}&populate[job][populate][0]=assigned_transcripter`
          : userType === "2"
          ? `&filters[doctor][hospital][id]=${user.id}&populate[job][populate][0]=assigned_transcripter`
          : userType === "3"
          ? `&filters[doctor][clinics][id]=${user.id}&populate[job][populate][0]=assigned_transcripter`
          : "&populate[job][populate][0]=assigned_transcripter"
      }${file && `&filters[counter_id][$containsi]=${file}`}${
        name && `&filters[doctor][name][$containsi]=${name}`
      }${
        hospital && `&filters[doctor][hospital][name][$containsi]=${hospital}`
      }${dateFilter}&filters[visibility][$eq]=true&filters[doctor][name][$ne]=null&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=tat:asc`
    );

    const bodyWithKeys = data.data.map((item, index) => {
      if (item?.tat) {
        let extraTime = getTATHours(item.tat);

        const currentTime = new Date().getTime();
        const createdTime = new Date(item.createdAt).getTime();

        const newTime = createdTime + extraTime * 1000 * 60 * 60 - currentTime;

        return {
          ...item,
          key: index,
          tatElapsed: newTime / (60 * 60 * 1000),
        };
      } else {
        return {
          ...item,
          key: index,
        };
      }
    });

    let finalOrder = bodyWithKeys.sort((a, b) => a.tatElapsed - b.tatElapsed);

    setBody(finalOrder);
    setTotalRecords(data.meta.pagination.total);
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    setSelectedRowKeys([]);
    await fetchScripts(
      page,
      pageSize,
      filterInfo.name,
      filterInfo.hospital,
      filterInfo.fromDate,
      filterInfo.toDate,
      filterInfo.file
    );
  };

  // row remove function
  const handleRemoveAll = async (e) => {
    e.preventDefault();
    try {
      setRemoving(true);
      for (const items of selectedUrls) {
        const { data } = await axios.put(
          `${URLconstants.fetchTranscripts()}/${items.id}`,
          {
            data: {
              visibility: false,
            },
          }
        );
        console.log(data);
      }
      setSelectedRowKeys([]);
      await fetchScripts(currentPage, pageSize);
    } catch (error) {
      console.log(error);
    } finally {
      setRemoving(false);
      toast.success(
        <p className=" text-slate-700 font-poppins">Records Deleted</p>,
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const fetchTranscripters = async (e, id) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(
        `${URLconstants.BASE_URL}/api/transcripters?populate=*${
          id && `&filters[id][$ne]=${id}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      const total = data.meta.pagination.total;
      const pages = Math.ceil(total / 100);
      for (let i = 1; i <= pages; i++) {
        const { data } = await axios.get(
          `${URLconstants.BASE_URL}/api/transcripters?populate=*${
            id && `&filters[id][$ne]=${id}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        setTranscripters((prev) => [...prev, ...data.data]);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        <p className=" font-poppins text-slate-700">Error Fetching Data!!!</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  useEffect(() => {
    const fetchDataAndToggleLoading = async () => {
      try {
        setLoading(true);
        setFilterInfo({ name: "", hospital: "", fromDate: "", toDate: "" });
        await fetchAdminJob();
        await fetchScripts(currentPage, pageSize);
      } finally {
        setLoading(false);
      }
    };
    fetchDataAndToggleLoading();
    setSelectedRowKeys([]);
  }, [section]);

  const assignJobs = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data, status } = await axios.put(
        `${URLconstants.BASE_URL}/api/admin/assign-job`,
        {
          transcriber_id: transcriber.transcriber_id,
          job_id: transcriber.job_info?.transcript?.job_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(data);
      if (status === 200) {
        setTranscriber({ open: false, transcriber_id: null, job_id: null });
        toast.success(<p className=" font-poppins text-slate-700">{data}</p>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(
        <p className=" font-poppins text-slate-700">{error.message}</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <>
      <Navbar />
      <SideBarFloat />
      <section className="scroll-smooth h-screen  bg-[#f0f0f0ea] font-poppins relative overflow-clip">
        {upload.open && (
          <div>
            {loading && (
              <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
                <Grid
                  visible={true}
                  height="80"
                  width="80"
                  color="#4A57EC"
                  ariaLabel="grid-loading"
                  radius="12.5"
                  wrapperStyle={{}}
                  wrapperClass="grid-wrapper"
                />
              </div>
            )}
            <div
              className={`absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100]  ${
                loading && "opacity-[0.4]"
              } flex flex-col items-end gap-1  `}
            >
              <Button
                sx={{
                  borderRadius: "50%",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "60px",
                  height: "60px",
                }}
                onClick={() => setUpload({ open: false })}
              >
                <IoClose size={25} className="text-slate-800" />
              </Button>
              <div className="bg-slate-800 p-4 rounded-2xl">
                <form
                  className="flex gap-4 items-center lg:flex-row md:flex-row flex-col"
                  onSubmit={(e) => handleWordUpload(e)}
                >
                  <input
                    type="file"
                    required
                    className="px-4 py-2 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 flex justify-center w-full"
                    placeholder="Id"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <Button
                    sx={{
                      borderRadius: "1.3rem",
                      padding: "10px 2rem",
                      color: "#f0f0f0fe",
                      marginBottom: "4px",
                      boxShadow: "inset -2px 1px 10px #f0f0f0fe",
                    }}
                    type="submit"
                  >
                    <p className="font-poppins text-[16px]">Submit</p>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        )}
        {logs.openLogs && (
          <div
            className={`absolute left-[50%] top-[30%] -translate-x-1/2 -translate-y-1/2 z-[1100]  ${
              loading && "opacity-[0.4]"
            } flex flex-col items-end gap-1  `}
          >
            <Button
              sx={{
                borderRadius: "50%",
                padding: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60px",
                background: "rgb(51 65 85)",
                height: "60px",
              }}
              onClick={() => setLogs((prev) => ({ ...prev, openLogs: false }))}
            >
              <IoClose size={25} className="text-slate-200 m-0" />
            </Button>
            <div className="bg-slate-200 p-4 rounded-2xl font-poppins h-[300px] overflow-y-scroll scrollbar-hide">
              <div className="flex flex-col">
                <p className=" tracking-wide text-[18px]">History</p>
                <div className="border-slate-700 border w-full bg-slate-700 my-2  " />
              </div>
              {logs.auditLogs?.audit_logs?.map((log) => {
                const dayTime = new Date(log.date_time).toLocaleString(
                  "en-US",
                  {
                    timeZone: "America/New_York",
                  }
                );
                return (
                  <div className="flex gap-2 my-3">
                    <p>{dayTime}</p>
                    <div className="flex flex-col gap-2">
                      <p>{log.message}</p>
                      <p>
                        Assigned Transcripter :
                        <span>
                          {logs.auditLogs?.assigned_transcripter?.name}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {transcriber.open && (
          <div className="w-full">
            {loading && (
              <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
                <Grid
                  visible={true}
                  height="80"
                  width="80"
                  color="#4A57EC"
                  ariaLabel="grid-loading"
                  radius="12.5"
                  wrapperStyle={{}}
                  wrapperClass="grid-wrapper"
                />
              </div>
            )}
            <div
              className={`absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ${
                loading && "opacity-[0.4]"
              } flex flex-col items-end gap-1 w-4/5 `}
            >
              <Button
                sx={{
                  borderRadius: "50%",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "60px",
                  height: "60px",
                }}
                onClick={() => setTranscriber({ open: false })}
              >
                <IoClose size={25} className="text-slate-800" />
              </Button>
              <div className="bg-slate-800 p-4 rounded-2xl">
                <form
                  className="flex gap-4 items-center lg:flex-row md:flex-row flex-col w-full"
                  onSubmit={(e) => assignJobs(e)}
                >
                  <Autocomplete
                    disablePortal
                    id="doctor-select-demo"
                    width="100%"
                    sx={{
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgb(71,85,105)",
                          borderRadius: "1.2rem",
                          padding: "1rem 0.75rem",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        color: "whitesmoke",
                        position: "relative",
                        zIndex: "100",
                      },
                    }}
                    options={transcripters}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      if (value) {
                        setTranscriber((prev) => ({
                          ...prev,
                          transcriber_id: value.id,
                        }));
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box {...props}>
                        <p className=" font-poppins text-slate-700 indent-2">
                          {option.name}
                        </p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                            Transcripters
                          </p>
                        }
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",
                            width: "100%",
                            color: "#f0f0f0d2",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                  <Button
                    sx={{
                      borderRadius: "1.3rem",
                      padding: "10px 2rem",
                      color: "#f0f0f0fe",
                      marginBottom: "4px",
                      boxShadow: "inset -2px 1px 10px #f0f0f0fe",
                    }}
                    type="submit"
                  >
                    <p className="font-poppins text-[16px]">Submit</p>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        )}
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>
        <section
          className={`px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100] ${
            (upload.open || logs.openLogs || transcriber.open) &&
            "opacity-[0.4]"
          } `}
        >
          <div className="flex pt-10 pb-10 mt-[4.5rem] gap-3 justify-between lg:items-center md:items-end items-start  lg:flex-row md:flex-row flex-col relative">
            <div className="flex gap-4  lg:items-center relative z-[100] lg:flex-row  flex-col items-start ">
              <div>
                <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                  {!open ? "Transcription List" : "Transcriber"}
                </p>
                <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
              </div>
            </div>
            {open ? (
              <Button
                sx={{
                  borderRadius: "1.5rem",
                  marginTop: "2rem",
                  textTransform: "none",
                }}
                onClick={() => {
                  setOpen((prev) => !prev);
                  setToggle(false);
                }}
                className="w-[120px] h-[52px]  gap-2 border border-slate-400 rounded-[2.5rem] flex items-center justify-center shadow-[inset_-2px_1px_10px_#9de6f5]"
              >
                <p className="font-poppins text-[18px] text-slate-800">Close</p>
                <IoClose size={25} className="text-slate-900" />
              </Button>
            ) : (
              <div className="flex relative z-[100] items-center gap-4 lg:mt-0 mt-8 flex-wrap">
                <Button
                  sx={{ borderRadius: "1.5rem", textTransform: "none" }}
                  onClick={() => setOpen((prev) => !prev)}
                  className="w-[150px] h-[52px]  gap-2 border border-slate-400 rounded-[2.5rem] flex items-center justify-center shadow-[inset_-2px_1px_10px_#9de6f5]"
                >
                  <p className="font-poppins text-[16px] text-slate-700">
                    Transcribe
                  </p>
                  <MdOutlineDescription size={20} className="text-slate-800" />
                </Button>
                <div class="mt-1">
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={() => setToggle((prev) => !prev)}
                    />
                    <span class="slider"></span>
                  </label>
                </div>
                {!toggle && (
                  <div className=" rounded-[1.5rem]  bg-slate-900">
                    <Button
                      sx={{
                        textTransform: "none",
                        borderRadius: "1.5rem",
                        padding: "10px",
                      }}
                      onClick={() => handleDownload(dataArray, heading)}
                    >
                      <p className="lg:text-[18px] font-poppins font-light text-slate-200  ">
                        Download Excel
                      </p>
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          {open ? (
            <MedicalTranscribe />
          ) : toggle ? (
            <File toggle={toggle} />
          ) : (
            <>
              <div className="lg:mt-3 md:mt-4 mt-2 pb-8  lg:w-full md:w-4/5  ">
                <div className="border-[2px] lg:hidden md:hidden block border-dashed border-slate-600 mb-12 "></div>
                <form
                  action=""
                  onSubmit={(e) => {
                    handleFilter(e, pageSize);
                  }}
                  className="flex lg:flex-row md:flex-row flex-col lg:items-end md:items-end items-start gap-2 lg:w-4/5 w-full  flex-wrap  "
                >
                  <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 gap-x-3 gap-y-3 w-full">
                    <div className="flex flex-col gap-3">
                      <label htmlFor="" className="text-slate-600 text-[18px] ">
                        Doctor
                      </label>
                      <input
                        type="text"
                        className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400   "
                        placeholder={`Doctor`}
                      />
                    </div>

                    {userType < 2 && (
                      <div className="flex flex-col gap-3">
                        <label
                          htmlFor=""
                          className="text-slate-600 text-[18px] "
                        >
                          Hospital
                        </label>
                        <input
                          type="text"
                          className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400   "
                          placeholder={`Hospital`}
                        />
                      </div>
                    )}
                    <div className="flex flex-col gap-[0.6rem]">
                      <label htmlFor="" className="text-slate-600 text-[18px] ">
                        From
                      </label>
                      <ConfigProvider
                        theme={{
                          components: {
                            DatePicker: {
                              zIndexPopup: 1100,
                              fontSize: "16px",
                              colorPrimaryHover: "none",
                              colorTextDisabled: "rgb(226,232,240)",
                              colorTextDescription: "rgb(100,116,139)",
                              colorText: "rgb(51,65,85)",
                              colorTextPlaceholder: "rgb(226,232,240)",
                            },
                          },
                          token: {
                            colorIcon: "whitesmoke",
                          },
                        }}
                      >
                        <DatePicker
                          defaultValue={dayjs(`${year}-${month}-${dateToday}`)}
                          style={{
                            padding: "12px",
                            borderRadius: "1.2rem",
                            fontFamily: "Poppins",
                            background: "rgb(143,168,184)",
                            width: "",
                          }}
                        />
                      </ConfigProvider>
                    </div>
                    <div className="flex flex-col gap-[0.6rem]">
                      <label htmlFor="" className="text-slate-600 text-[18px] ">
                        To
                      </label>
                      <ConfigProvider
                        theme={{
                          components: {
                            DatePicker: {
                              zIndexPopup: 1100,
                              fontSize: "16px",
                              colorPrimaryHover: "none",
                              colorTextDisabled: "rgb(226,232,240)",
                              colorTextDescription: "rgb(100,116,139)",
                              colorText: "rgb(51,65,85)",
                              colorTextPlaceholder: "rgb(226,232,240)",
                            },
                          },
                          token: {
                            colorIcon: "whitesmoke",
                          },
                        }}
                      >
                        <DatePicker
                          defaultValue={dayjs(`${year}-${month}-${dateToday}`)}
                          style={{
                            padding: "12px",
                            borderRadius: "1.2rem",
                            fontFamily: "Poppins",
                            background: "rgb(143,168,184)",
                            width: "",
                          }}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      File_Name
                    </label>
                    <input
                      type="text"
                      className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400   "
                      placeholder={`File_Name`}
                    />
                  </div>
                  <div className=" md:mt-0 mt-8">
                    <Button
                      sx={{
                        border: "1px solid rgb(203,213,225)",
                        borderRadius: "1.3rem",
                        padding: "10px 2rem",
                        color: "",

                        boxShadow: "inset -2px 1px 10px rgb(203,213,225)",
                      }}
                      type="submit"
                    >
                      <p className="font-poppins text-slate-600 text-[16px]">
                        Find
                      </p>
                    </Button>
                  </div>
                </form>
              </div>

              <div className="lg:py-8 relative md:py-8 pt-4 pb-[6rem] flex items-center justify-center">
                {loading ? (
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#27284a",
                      "#3c4a74",
                      "#4f6ea0",
                      "#5f96cd",
                      "#6ec0fa",
                    ]}
                  />
                ) : (
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBorderRadius: 20,
                          headerBg: "#cbd5e1",
                          headerSortHoverBg: "",
                        },
                      },
                    }}
                  >
                    <Table
                      rowSelection={rowSelection}
                      dataSource={body}
                      columns={columns}
                      className="rounded-[1.5rem] bg-[#F1F1F1]   text-slate-800 shadow-lg shadow-slate-300  "
                      pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: false,
                        total: totalRecords,
                        onChange: handlePageChange,
                        style: {
                          padding: "0 12px",
                          fontFamily: "Poppins",
                        },
                      }}
                      footer={() => (
                        <div className="flex gap-2">
                          {selectedRowKeys.length > 0 && (
                            <Button
                              onClick={(e) => handleDownloadAll(e)}
                              sx={{
                                textTransform: "none",
                                border: "1px solid lightgrey",
                                borderRadius: "0.8rem",
                              }}
                            >
                              <p className="font-poppins text-[16px] text-slate-700">
                                {dowloading ? "Dowloading..." : "Dowload"}
                              </p>
                            </Button>
                          )}
                          {selectedRowKeys.length > 0 && (
                            <Button
                              onClick={(e) => handleRemoveAll(e)}
                              sx={{
                                textTransform: "none",
                                border: "1px solid lightgrey",
                                borderRadius: "0.8rem",
                              }}
                            >
                              <p className="font-poppins text-[16px] text-slate-700">
                                {removing ? "Removing..." : "Remove"}
                              </p>
                            </Button>
                          )}
                        </div>
                      )}
                      scroll={{ y: 600, x: "calc(650px + 50%)" }}
                    />
                  </ConfigProvider>
                )}
              </div>
            </>
          )}
        </section>

        <ToastContainer />
      </section>
    </>
  );
};

export default Transcript;
