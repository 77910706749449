import React from "react";
import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import CanvasLoader from "../../components/Loader";
const Dna = () => {
  const earth = useGLTF("./dna/dna.glb");
  return (
    <mesh>
      <ambientLight intensity={4.5} />
      <primitive
        object={earth.scene}
        scale={13.5}
        position-y=""
        rotation-y={-0.18}
        rotation-x={-1.2}
        rotation-z={0.2}
      />
      <directionalLight intensity={3.5} position={[-0.7, 0.3, 0.5]} />
    </mesh>
  );
};

const DnaCanvas = () => {
  return (
    <Canvas
      shadows
      gl={{ preserveDrawingBuffer: true }}
      frameloop="demand"
      dpr={[1, 2]}
      camera={{ position: [-4, 3, 6], fov: 20, near: 0.1, far: 200 }}
    >
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls
          enableRotate={true}
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
        <Dna />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};
export default DnaCanvas;
