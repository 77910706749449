import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLconstants } from "../api/constants";

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password, user_type }) => {
    try {
      const { data } = await axios.post(
        `${URLconstants.BASE_URL}/api/auth/login`,
        {
          email: email,
          password: password,
          user_type: user_type,
        }
      );

      localStorage.setItem("userToken", data["jwt"]);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);
