import { useEffect, useState, useContext } from "react";
import FileViewer from "./FileViewer";
import axios from "axios";
import AudioVizualize from "./AudioVizualize";
import { Context } from "../../context/StateContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URLconstants } from "../../api/constants";

const File = ({ toggle }) => {
  const { setPdf, setRecrd } = useContext(Context);
  const [orgNames, setOrgNames] = useState([]);
  const [doc, setDoc] = useState([]);
  const fetchOrganisation = async () => {
    try {
      const response = await axios.get(
        `${URLconstants.BASE_URL}/api/organisations?populate=hospitals.clinics.doctors`
      );
      const docOrgnames = [];
      response.data.data.forEach((item) => {
        const orgName = item?.name;
        if (orgName) {
          if (!docOrgnames[orgName]) {
            docOrgnames[orgName] = [];
          }
        }
        item?.hospitals?.forEach((itemH) => {
          itemH?.clinics?.forEach((itemC) => {
            itemC?.doctors?.forEach((itemD) => {
              if (!docOrgnames[orgName].includes(itemD?.name)) {
                docOrgnames[orgName].push(itemD.name);
              }
            });
          });
        });
      });

      setDoc(docOrgnames);
      setOrgNames(response.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Error Fetching Data!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    fetchOrganisation();
    setPdf("");
    setRecrd("");
  }, [toggle]);

  return (
    <main className=" lg:w-10/12 w-full">
      <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-2">
        <FileViewer orgNames={orgNames} doc={doc} />
        <AudioVizualize />
      </div>
    </main>
  );
};

export default File;
