import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { CgOrganisation } from "react-icons/cg";
import { GiHospitalCross } from "react-icons/gi";
import { FaClinicMedical } from "react-icons/fa";
import { FaUserNurse } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { FaUserDoctor } from "react-icons/fa6";
import { Autoplay, Navigation } from "swiper/modules";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import DnaCanvas from "../assets/canvas/Dna";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/authAction";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/StateContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/Mdlogo.png";
import { Grid } from "react-loader-spinner";
import { CiFileOn } from "react-icons/ci";
import { TbReportMedical } from "react-icons/tb";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const { user_type, setUserType, setSection, loading, setLoading } =
    useContext(Context);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const notify = (
    <p className="font-poppins text-slate-600">
      Login failed. Please check your credentials.
    </p>
  );

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await dispatch(loginUser({ email, password, user_type }));

      localStorage.setItem("user_type", user_type);
      {
        (user_type === 4 || user_type === 6 || user_type === 5) &&
          setSection("manualTranscript");
      }
      {
        user_type === 7 && setSection("jobs");
      }
      {
        user_type === 4 || user_type === 6 || user_type === 5
          ? navigate("/manualTranscript", { replace: true })
          : user_type === 7
          ? navigate("/transcripterJobs", { replace: true })
          : navigate("/home", { replace: true });
      }
    } catch (error) {
      console.log(error);
      toast.error(notify, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      setLoggedIn(true);
      setLoading(true);
    } else {
      setLoggedIn(false);
    }
  }, []);
  return (
    <section className="relative">
      {loading && (
        <div className="absolute top-[50%] left-[50%]   -translate-x-1/2 -translate-y-1/2 z-[1200] ">
          <Grid
            visible={true}
            height="80"
            width="80"
            color="#4A57EC"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-wrapper"
          />
        </div>
      )}
      {loggedIn ? (
        navigate("/home", { replace: true })
      ) : (
        <section
          className={`bg-gradient-to-b from-[#353764] via-[#27294a]  to-[#17182c] font-poppins w-full h-screen relative flex overflow-clip overflow-y-scroll scroll-smooth scrollbar-hide py-2 items-center justify-center login z-[1100] ${
            loading && "opacity-[0.2]"
          } `}
        >
          <>
            <div className="lg:h-[180px] lg:w-[180px] md:h-[200px] md:w-[200px] w-[150px] h-[150px] bg-slate-800 rounded-[50%] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 shadow-[inset_6px_-3px_40px_#29b6eac9] lg:left-16 lg:top-4 md:top-[2rem] md:left-[1rem] top-[12rem] left-[14rem] absolute flex  items-center justify-center hover:scale-[1.1] duration-500 ease-in-out lg:z-[1] z-[1100]">
              <img
                src={logo}
                alt="mdlogo"
                className="lg:rotate-[-15deg] md:rotate-[-15deg] rotate-[15deg] lg:w-[145px] lg:h-[145px] md:w-[150px] md:h-[150px] w-[100px] h-[100px]"
              />
            </div>

            <div className="h-[130px] w-[130px] bg-slate-800 rounded-[50%] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 shadow-[inset_6px_-3px_40px_#22b37b] lg:left-4 lg:top-[10rem] z-[201] lg:block hidden absolute hover:scale-[1.1] duration-500 ease-in-out" />
            <div className="absolute  w-full h-screen   left-2 bg-transparent lg:block hidden">
              <DnaCanvas />
            </div>

            <div className="h-[120px] w-[120px] bg-slate-800 rounded-[50%] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 shadow-[inset_6px_-3px_40px_#2ba8d9] lg:left-[80%] lg:top-[50%] md:top-[10rem] md:left-[40rem] top-[-4rem] left-[80%] absolute flex  items-center justify-center hover:scale-[1.1] duration-500 ease-in-out z-[1100]" />

            <div className="h-[80px] w-[80px] bg-slate-800 rounded-[50%] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 shadow-[inset_6px_-2px_20px_#15d6b0] lg:left-[90%] lg:top-[30%] md:top-[2rem] md:left-[30rem] top-[-2rem] left-[-10rem] absolute flex  items-center justify-center hover:scale-[1.1] duration-500 ease-in-out" />
          </>

          <div className="bg-slate-600    bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 lg:w-[60%] md:w-[85%] h-fit w-full flex flex-col items-center rounded-[2rem] justify-center px-2 py-2 my-auto">
            <div className="w-full  opacity-[0.8]">
              <Swiper
                loop={true}
                spaceBetween={10}
                centeredSlides={true}
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/4033148/pexels-photo-4033148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="img1"
                      className="w-[100%] h-[16.5rem]  object-cover rounded-t-[2rem]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      src="https://images.unsplash.com/photo-1579684453423-f84349ef60b0?q=80&w=1791&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="img1"
                      className="w-[100%]  h-[16.5rem] object-cover rounded-t-[2rem]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/356040/pexels-photo-356040.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="img1"
                      className="w-[100%] h-[16.5rem] object-cover rounded-t-[2rem]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      src="https://images.unsplash.com/photo-1576765607924-3f7b8410a787?q=80&w=2075&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="img1"
                      className="w-[100%]  h-[16.5rem] object-cover rounded-t-[2rem]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/4031818/pexels-photo-4031818.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="img1"
                      className="w-[100%] h-[16.5rem] object-cover rounded-t-[2rem]"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="w-full  px-10 h-fit lg:py-5 md:py-4 py-2 bg-[#f0f0f0] rounded-b-[2rem] form-login">
              <div className=" my-7 leading-[2.8rem] ">
                <p className="tracking-wide lg:text-[62px] md:text-[48px] text-[32px] font-bold">
                  Welcome
                </p>
                <p className="text-slate-400 text-[15px]">
                  Please Login to your account
                </p>
              </div>

              <div className="">
                <FormControl
                  sx={{ minWidth: 300, fontFamily: "Poppins", maxHeight: 200 }}
                >
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Select Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={category}
                    onChange={handleChange}
                    autoWidth
                    label="Select Category"
                    sx={{
                      borderRadius: "1.2rem",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "200px", width: "300px" }, // Adjust the height as per your requirement
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em className="font-poppins">None</em>
                    </MenuItem>
                    <MenuItem value={1} onClick={() => setUserType(0)}>
                      <div className=" flex items-center">
                        <img
                          src={logo}
                          className="w-4 h-4 grayscale"
                          alt="logo"
                        />
                        <p className="font-poppins ml-1">Manager</p>
                      </div>
                    </MenuItem>
                    <MenuItem value={2} onClick={() => setUserType(1)}>
                      <div className=" flex items-center">
                        <CgOrganisation />
                        <p className="font-poppins ml-1">Organisation</p>
                      </div>
                    </MenuItem>
                    <MenuItem value={3} onClick={() => setUserType(2)}>
                      <div className=" flex items-center">
                        <GiHospitalCross />
                        <p className="font-poppins ml-1">Hospital</p>
                      </div>
                    </MenuItem>
                    <MenuItem value={4} onClick={() => setUserType(3)}>
                      <div className=" flex items-center">
                        <FaClinicMedical />
                        <p className="font-poppins ml-1">Clinic</p>
                      </div>
                    </MenuItem>

                    <MenuItem value={5} onClick={() => setUserType(4)}>
                      <div className=" flex items-center">
                        <FaUserDoctor />
                        <p className="font-poppins ml-1">Doctor</p>
                      </div>
                    </MenuItem>
                    <MenuItem value={6} onClick={() => setUserType(5)}>
                      <div className=" flex items-center">
                        <FaUserNurse />
                        <p className="font-poppins ml-1">Nurse</p>
                      </div>
                    </MenuItem>
                    <MenuItem value={7} onClick={() => setUserType(6)}>
                      <div className=" flex items-center">
                        <IoIosPeople />
                        <p className="font-poppins ml-1">Staff</p>
                      </div>
                    </MenuItem>
                    <MenuItem value={8} onClick={() => setUserType(7)}>
                      <div className=" flex items-center">
                        <TbReportMedical />
                        <p className="font-poppins ml-1">Transcripter</p>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="my-6  ">
                <form
                  action=""
                  onSubmit={(e) => handleSubmit(e)}
                  className="flex flex-col font-poppins  items-start gap-4 "
                >
                  <div className="w-full gap-4 flex lg:flex-row md:flex-row flex-col">
                    <TextField
                      className="lg:w-[40%] md:w-[40%] w-full font-poppins"
                      label="Username"
                      InputProps={{
                        style: {
                          borderRadius: "1.2rem",
                          padding: "0 2px",
                          fontFamily: "poppins",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontFamily: "poppins",
                          textIndent: "5px",
                        },
                      }}
                      id="outlined-basic"
                      placeholder="Username"
                      type="text"
                      required
                      variant="outlined"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormControl variant="outlined">
                      <InputLabel
                        className="font-poppins"
                        required
                        sx={{
                          fontFamily: "Poppins",
                        }}
                        htmlFor="outlined-adornment-password"
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        required
                        className="font-poppins"
                        style={{
                          borderRadius: "1.2rem",
                          fontFamily: "Poppins",
                        }}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                  </div>
                  <Button
                    variant="outlined"
                    type="submit"
                    style={{
                      padding: "12px 3rem",
                      borderRadius: "1.2rem",
                      background: "black",
                      border: "none",
                      marginTop: "5px",
                      textTransform: "none",
                      fontSize: "16px",
                    }}
                  >
                    <p className="font-poppins text-[#25db2e]">Login</p>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <ToastContainer />
    </section>
  );
};

export default Login;
