import { createContext, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CgOrganisation } from "react-icons/cg";
import { GiHospitalCross } from "react-icons/gi";
import { FaClinicMedical } from "react-icons/fa";
import { FaUserNurse } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { FaUserDoctor } from "react-icons/fa6";
import { CiFileOn } from "react-icons/ci";
import axios from "axios";
import { toast } from "react-toastify";
import { URLconstants } from "../api/constants";
export const Context = createContext();

export const StateContext = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userType = localStorage.getItem("user_type");
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [removing, setRemoving] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const staffhospital = JSON.parse(localStorage.getItem("staffHospital"));
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [pdf, setPdf] = useState("");
  const [recrd, setRecrd] = useState("");
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [section, setSection] = useState(
    localStorage.getItem("section") ? localStorage.getItem("section") : ""
  );
  const [open, setOpen] = useState(false);
  const [user_type, setUserType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resetPassword, setPassword] = useState({
    open: false,
    id: "",
  });
  const [filterInfo, setFilterInfo] = useState({
    fieldName: "",
    subFilter: "",
    date: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    id: null,
  });
  const notify = (
    <p className="font-poppins text-slate-600">Error Fetching Data!!</p>
  );

  const subs = {
    organisations: "hospitals",
    hospitals: "clinics",
    clinics: "doctors",
    doctors: "clinics",
    nurses: "email",
    staffs: "email",
  };
  const abvs = {
    hospitals: "organisations",
    clinics: "hospitals",
    doctors: "hospitals",
    nurses: "hospitals",
    staffs: "hospitals",
  };
  const icons = {
    organisations: <CgOrganisation />,
    hospitals: <GiHospitalCross />,
    clinics: <FaClinicMedical />,
    doctors: <FaUserDoctor />,
    nurses: <FaUserNurse />,
    staffs: <IoIosPeople />,
    transcripts: <CiFileOn />,
  };

  const handleFilterTable = async () => {
    const filters = [];

    if (section === "organisations") {
      filters.push("&filters[status][$eq]=1");
    }

    // Check and add name filter
    if (filter) {
      if (section === "transcript") {
        filters.push(`&filters[counter_id][$containsi]=${filter}`);
      } else {
        filters.push(`&filters[name][$containsi]=${filter}`);
      }
    }

    try {
      setLoading(true);
      const { data } = await axios.get(
        `${URLconstants.BASE_URL}/api/${section}?${filters.join("")}&populate=*`
      );
      setRows(data.data);
      setTotal(data.meta.pagination.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },

    onSelect: (record, selected, selectedRows, nativeEvent) => {
      console.log("onSelect", selectedRows);
      setSelectedUrls((prev) => selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log("onSelectAll", selectedRows);
      setSelectedUrls((prev) => selectedRows);
    },
  };
  const visibilityFunction = async (id) => {
    const { data } = await axios.put(
      `${URLconstants.BASE_URL}/api/${section}/${id}`,
      {
        data: {
          visibility: false,
        },
      }
    );
    await fetchData(currentPage);
    console.log(data);
  };
  const fetchData = async (page) => {
    try {
      if (section === "nurses" || section === "staffs") {
        const { data } = await axios.get(
          `${URLconstants.fetchWorkers()}${section}?${
            userType === "1"
              ? `&filters[clinic][hospital][organisation][id]=${user.id}`
              : userType === "2"
              ? `&filters[hospital][id]=${user.id}`
              : userType === "3" && `&filters[clinic][id]=${user.id}`
          }&populate=*&filters[visibility][$eq]=true&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setRows(rowWithKeys);
        setTotal(data.meta.pagination.total);
      } else if (section === "hospitals") {
        const { data } = await axios.get(
          `${URLconstants.fetchHospitals()}?${
            userType === "1"
              ? `populate[clinics][populate][0]=doctors&filters[organisation][id]=${user.id}`
              : `populate=clinics`
          }&filters[visibility][$eq]=true&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setRows(rowWithKeys);
        setTotal(data.meta.pagination.total);
      } else if (section === "clinics") {
        const { data } = await axios.get(
          `${URLconstants.fetchClinics()}?${
            userType === "1"
              ? `populate[doctors][populate][0]=doctors&filters[hospital][organisation][id]=${user.id}`
              : userType === "2"
              ? `populate[doctors][populate][0]=doctors&filters[hospital][id]=${user.id}`
              : `populate=doctors`
          }&filters[visibility][$eq]=true&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setRows(rowWithKeys);
        setTotal(data.meta.pagination.total);
      } else if (section === "doctors") {
        const { data } = await axios.get(
          `${URLconstants.fetchDoctors()}?populate=hospital,clinics${
            userType === "1"
              ? `&filters[hospital][organisation][id]=${user.id}`
              : userType === "2"
              ? `&filters[hospital][id]=${user.id}`
              : userType === "3"
              ? `&filters[clinics][id]=${user.id}`
              : ``
          }&filters[visibility][$eq]=true&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setRows(rowWithKeys);
        setTotal(data.meta.pagination.total);
      } else if (section === "organisations") {
        const { data } = await axios.get(
          `${URLconstants.fetchOrganisations()}?&filters[visibility][$eq]=true&populate=hospitals,clinics`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setRows(rowWithKeys);
        setTotal(data.meta.pagination.total);
      } else if (section === "appoints") {
        const { data } = await axios.get(
          `${URLconstants.fetchAppoints()}?${
            userType === "1"
              ? `&populate=*&filters[doctor][clinics][hospital][organisation][id]=${user.id}`
              : userType === "2"
              ? `&populate=*&filters[doctor][clinics][hospital][id]=${user.id}`
              : userType === "3"
              ? `&populate=*&filters[clinic][id]=${user.id}`
              : userType === "4"
              ? `&populate=*&filters[doctor][id]=${user.id}`
              : userType === "6"
              ? `&populate=*&filters[doctor][hospital][id]=${staffhospital?.id}`
              : "&populate=*"
          }&filters[visibility][$eq]=true&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setRows(rowWithKeys);
        setTotal(data.meta.pagination.total);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(notify, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const filterFunction = async (
    page,
    fieldName,
    subNameFilter,
    createdAtFilter
  ) => {
    const filters = [];

    // Check and add name filter
    if (fieldName) {
      filters.push(`&filters[name][$containsi]=${fieldName}`);
    }

    // Check and add subs[section] (e.g., hospitals) name filter
    if (subNameFilter) {
      if (section === "nurses" || section === "staffs") {
        filters.push(`&filters[email][$eq]=${subNameFilter}`);
      } else {
        filters.push(`&filters[${subs[section]}][name][$eq]=${subNameFilter}`);
      }
    }

    // Check and add createdAt filter
    if (createdAtFilter) {
      const createdAtFilterStart = `${createdAtFilter}T00:00:00.000Z`; // Start of the day
      const createdAtFilterEnd = `${createdAtFilter}T23:59:59.999Z`; // End of the day
      filters.push(
        `&filters[createdAt][$gte]=${createdAtFilterStart}&filters[createdAt][$lte]=${createdAtFilterEnd}`
      );
    }

    if (section === "nurses" || section === "staffs") {
      const { data } = await axios.get(
        `${URLconstants.fetchWorkers()}${section}?&populate=hospital,clinics${
          userType === "0"
            ? `populate=*${filters.join("")}`
            : userType === "1"
            ? `populate[clinic][hospital][populate][0]=organisation&filters[clinic][hospital][organisation][id]=${
                user.id
              }${filters.join("")}`
            : userType === "2"
            ? `populate[clinic][populate][0]=hospital&filters[clinic][hospital][id]=${
                user.id
              }${filters.join("")}`
            : `populate[clinic][populate][0]=clinic&filters[clinic][id]=${
                user.id
              }${filters.join("")}`
        }&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[visibility]=true`
      );
      const rowWithKeys = data.data.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      setRows(rowWithKeys);
      setTotal(data.meta.pagination.total);
    } else if (section === "hospitals") {
      const { data } = await axios.get(
        `${URLconstants.fetchHospitals()}?${
          userType === "1"
            ? `populate[clinics][populate][0]=doctors&filters[organisation][id]=${user.id}`
            : `populate=clinics`
        }${filters.join(
          ""
        )}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      );
      setRows(data.data);
      setTotal(data.meta.pagination.total);
      const rowWithKeys = data.data.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      setRows(rowWithKeys);
      setTotal(data.meta.pagination.total);
    } else if (section === "clinics") {
      const { data } = await axios.get(
        `${URLconstants.fetchClinics()}?${
          userType === "1"
            ? `populate[doctors][populate][0]=doctors&filters[hospital][organisation][id]=${user.id}`
            : userType === "2"
            ? `populate[doctors][populate][0]=doctors&filters[hospital][id]=${user.id}`
            : `populate=doctors`
        }${filters.join(
          ""
        )}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      );
      const rowWithKeys = data.data.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      setRows(rowWithKeys);
      setTotal(data.meta.pagination.total);
    } else if (section === "doctors") {
      const { data } = await axios.get(
        `${URLconstants.fetchDoctors()}?${
          userType === "1"
            ? `&populate=clinics&filters[hospital][organisation][id]=${user.id}`
            : userType === "2"
            ? `&populate=clinics&filters[hospital][id]=${user.id}`
            : userType === "3"
            ? `&populate=clinics&filters[clinics][id]=${user.id}`
            : `&populate=clinics`
        }${filters.join(
          ""
        )}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      );
      const rowWithKeys = data.data.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      setRows(rowWithKeys);
      setTotal(data.meta.pagination.total);
    } else if (section === "organisations") {
      const { data } = await axios.get(
        `${URLconstants.fetchOrganisations()}?&populate=hospitals${filters.join(
          ""
        )}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      );
      const rowWithKeys = data.data.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      setRows(rowWithKeys);
      setTotal(data.meta.pagination.total);
    }
  };

  const handleDownload = (dataArray, headings) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headings, ...dataArray]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { type: "array", bookType: "xlsx" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    saveAs(blob, "table_data.xlsx");
  };
  const handleRemoveAll = async (e) => {
    e.preventDefault();
    try {
      setRemoving(true);
      for (const items of selectedUrls) {
        const { data } = await axios.put(
          `${URLconstants.BASE_URL}/api/${section}/${items.id}`,
          {
            data: {
              visibility: false,
            },
          }
        );
        console.log(data);
      }
      setSelectedRowKeys([]);
      await fetchData(currentPage);
    } catch (error) {
      console.log(error);
    } finally {
      setRemoving(false);
    }
  };
  const passwordReset = async (e, id) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { status } = await axios.post(
        `${URLconstants.BASE_URL}/api/auth/forgot-password/${id}`,
        {
          password: e.target[0].value,
        }
      );

      if (status === 200) {
        setPassword({ open: false, id: "" });
        toast.success(
          <p className=" text-slate-700 font-poppins">
            Password Reset Successful !!!
          </p>,
          {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const [doctorList, setDoctorList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const fetchDoctor = async (id) => {
    try {
      setDoctorList([]);
      const { data } = await axios.get(
        `${URLconstants.fetchDoctors()}?&populate=hospital,clinics&filters[hospital][id]=${id}&filters[visibility][$eq]=true`
      );
      const total = data.meta.pagination.total;
      const pages = Math.ceil(total / 100);
      for (let i = 1; i <= pages; i++) {
        const { data } = await axios.get(
          `${URLconstants.fetchDoctors()}?&populate=hospital,clinics&filters[hospital][id]=${id}&filters[visibility][$eq]=true&pagination[pageSize]=100&pagination[page]=${i}`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setDoctorList((prev) => [...prev, ...rowWithKeys]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchHospital = async () => {
    try {
      setDoctorList([]);
      setHospitalList([]);
      const { data } = await axios.get(
        `${URLconstants.fetchHospitals()}?${
          userType === "1"
            ? `populate[clinics][populate][0]=doctors&filters[organisation][id]=${user.id}`
            : `populate=clinics`
        }&filters[visibility]=true`
      );
      const total = data.meta.pagination.total;
      const pages = Math.ceil(total / 100);
      for (let i = 1; i <= pages; i++) {
        const { data } = await axios.get(
          `${URLconstants.fetchHospitals()}?${
            userType === "1"
              ? `populate[clinics][populate][0]=doctors&filters[organisation][id]=${user.id}`
              : `populate=clinics,doctors`
          }&filters[visibility]=true&pagination[pageSize]=100&pagination[page]=${i}`
        );
        const rowWithKeys = data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        setHospitalList((prev) => [...prev, ...rowWithKeys]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const downloadFile = async (e, url, filename) => {
    e.preventDefault();
    try {
      if (!url) {
        toast.info(
          <p className=" text-slate-700 font-poppins">No file present!!</p>,
          {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.info(
          <p className=" text-slate-700 font-poppins">
            Download in progress!!
          </p>,
          {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      const response = await axios.get(url, { responseType: "blob" });
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Error downloading file at index  ${error.message}`);
    }
  };
  const downloadAndAddToZip = async (zip, url, extension, name) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      // Add the file to the zip
      zip.file(`${name}.${extension}`, response.data);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };
  return (
    <Context.Provider
      value={{
        downloadAndAddToZip,
        downloadFile,
        fetchDoctor,
        fetchHospital,
        setHospitalList,
        setDoctorList,
        doctorList,
        hospitalList,
        section,
        setSection,
        open,
        setOpen,
        page,
        setPage,

        handleDownload,
        handleFilterTable,
        user_type,
        setUserType,
        rows,
        fetchData,
        setRows,
        formData,
        setFormData,
        loading,
        setLoading,
        pdf,
        setPdf,
        recrd,
        setRecrd,
        subs,
        icons,
        filter,
        setFilter,
        abvs,
        user,
        userType,
        staffhospital,
        total,
        pageSize,
        currentPage,
        setPageSize,
        setCurrentPage,
        setTotal,
        filterInfo,
        setFilterInfo,
        filterFunction,
        visibilityFunction,
        rowSelection,
        selectedRowKeys,
        removing,
        selectedUrls,
        setSelectedUrls,
        setSelectedRowKeys,
        handleRemoveAll,
        passwordReset,
        resetPassword,
        setPassword,
      }}
    >
      {children}
    </Context.Provider>
  );
};
