import React, { useContext } from "react";
import logo from "../assets/MDLogoFull.png";
import logoMini from "../assets/Mdlogo.png";
import { FiLogOut } from "react-icons/fi";
import { Context } from "../context/StateContext";
import { logOut } from "../redux/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
const Navbar = () => {
  const { setSection } = useContext(Context);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(logOut());
    navigate("/", { replace: true });
  };
  return (
    <nav
      className={`bg-[#27284A] z-[1100] py-5 px-8 fixed top-0  w-full  flex items-center  justify-between ease-in-out lg:rounded-b-none rounded-b-3xl`}
    >
      <Link to="/home" onClick={() => setSection("dash")}>
        <div>
          <img
            src={logo}
            alt="mdlogo"
            className={`lg:block md:block hidden w-full h-[45px]`}
          />
          <img
            src={logoMini}
            alt="mdlogo"
            className={`lg:hidden md:hidden w-full h-[45px]`}
          />
        </div>
      </Link>

      <div className={`flex items-center gap-6 w-fit`}>
        <Link
          to="/home"
          onClick={() => setSection("dash")}
          className="font-poppins text-slate-200 text-[18px]"
        >
          Home
        </Link>
        <Link to="/contact" className="font-poppins text-slate-200 text-[18px]">
          Contact
        </Link>

        <Button
          onClick={() => handleLogOut()}
          sx={{
            width: "fit",
            padding: "0px",
            borderRadius: "12px",
            textTransform: "none",
          }}
        >
          <div className="flex items-center gap-2">
            <p className="font-poppins text-[18px]">Logout</p>
            <FiLogOut size={25} />
          </div>
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
