import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import JSZip from "jszip";
import axios from "axios";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../../context/StateContext";
import { Grid } from "react-loader-spinner";
import { ConfigProvider, Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import { URLconstants } from "../../api/constants";
import { transform } from "framer-motion";

const TranscriptUpload = () => {
  const {
    setSection,
    setLoading,
    loading,
    fetchDoctor,
    fetchHospital,
    doctorList,
    hospitalList,
  } = useContext(Context);
  const [fileList, setFileList] = useState([]);
  const [fileInfo, setFileInfo] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileInfo, setSelectedFileInfo] = useState([]);
  const [doc_id, setDocId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hospital, setHospital] = useState("");
  function throwError(content) {
    toast.error(content, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function extractDate(date) {
    if (!date.length === 6) {
      throwError("Date not in format");
    }
    let month = date.substring(0, 2).trim();
    let day = date.substring(2, 4).trim();
    let year = "20" + date.substring(4, 6).trim();
    console.log("Year ", year, day, month);
    return year + "-" + month + "-" + day;
  }

  const checkFileNameFormat = async (fileName, hospital) => {
    if (fileName && hospital) {
      let nameArr = fileName.split(" ");
      if (nameArr.length > 8) {
        throwError("Name not in format");
      }
      let appointInfo = {
        hospitalName: hospital,
        doctor_last_name: nameArr[1].trim(),
        patient_name: nameArr[2] + " " + nameArr[3],
        date_of_visit: extractDate(nameArr[4]),
        work_type: nameArr[5],
        voice_file_number: nameArr[6],
        mt_name: nameArr[7]?.split(".")[0]?.replace("-", " "),
        name: fileName,
      };
      console.log(appointInfo);
      return appointInfo;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("transcripts", selectedFiles[i]);
        console.log(selectedFiles[i]);
      }
      formData.append("fileInfo", JSON.stringify({ test: selectedFileInfo }));
      formData.append("doctor_id", doc_id);

      setLoading(true);
      const response = await axios.post(
        URLconstants.uploadTranscripts(),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        let arr = [];
        for (let i = 0; i < fileInfo.length; i++) {
          for (let j = 0; j < selectedFileInfo.length; j++) {
            if (
              fileInfo[i].voice_file_number ==
              selectedFileInfo[j].voice_file_number
            ) {
              arr.push(i);
              break;
            }
          }
        }

        setFileInfo((prev) => prev.filter((val, ind) => !arr.includes(ind)));
        setFileList((prev) => prev.filter((val, ind) => !arr.includes(ind)));

        setSelectedFileInfo([]);
        setSelectedFiles([]);
        setSelectedRowKeys([]);
        toast.success(response.data, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  function handleChange(e) {
    e.preventDefault();

    let fileInfo = e.target.files[0];
    var new_zip = new JSZip();

    new_zip.loadAsync(fileInfo).then(async function (zip) {
      let fileNames = Object.keys(zip.files);

      let arr = [];
      let fileInfoArr = [];
      for (let fileName in zip.files) {
        if (
          zip.files[fileName]?.name &&
          [
            zip.files[fileName].name.endsWith(".doc"),
            zip.files[fileName].name.endsWith(".docx"),
            zip.files[fileName].name.endsWith(".rtf"),
          ].includes(true)
        ) {
          let dataCheck = await checkFileNameFormat(
            zip.files[fileName].name,
            hospital
          );

          if (!dataCheck) {
            throwError("Filename not in proper date format");
            return;
          } else {
            let transcriptInfo = await zip.files[fileName].async("blob");

            fileInfoArr.push(dataCheck);
            arr.push(transcriptInfo);
          }
        }
      }
      console.log("FILEINFO ARR", fileInfoArr);
      console.log("TRANSCEIPT INFO", arr);

      setFileList(arr);
      const bodyWithKeys = fileInfoArr.map((item, index) => ({
        ...item,
        key: index,
      }));
      setFileInfo(bodyWithKeys);
    });
  }

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
    },
    {
      title: "Last Name",
      dataIndex: "doctor_last_name",
      key: "doctor_last_name",
    },
    {
      title: "Hospital",
      dataIndex: "hospitalName",
      key: "hospitalName",
    },
    {
      title: "Mt Name",
      dataIndex: "mt_name",
      key: "mt_name",
    },
    {
      title: "Date-of-Visit",
      dataIndex: "date_of_visit",
      key: "date_of_visit",
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
      const files = [];
      selectedRowKeys.forEach((i) => {
        files.push(fileList[i]);
      });

      setSelectedFiles(files);
    },

    onSelect: (record, selected, selectedRows, nativeEvent) => {
      console.log("onSelect", selectedRows);
      setSelectedFileInfo(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log("onSelectAll", selectedRows);
      setSelectedFileInfo(selectedRows);
    },
  };

  useEffect(() => {
    const fetchDataAndToggleLoading = async () => {
      try {
        await fetchHospital();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndToggleLoading();
  }, []);

  return (
    <>
      <Navbar />
      <section className="relative">
        {loading && (
          <div className="absolute top-[50%] left-[50%]   -translate-x-1/2 -translate-y-1/2 z-[1100] ">
            <Grid
              visible={true}
              height="80"
              width="80"
              color="#4A57EC"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="grid-wrapper"
            />
          </div>
        )}
        <div className={`flex flex-col px-8  ${loading && "opacity-40"}`}>
          <div className="flex gap-4 pt-10 pb-10 md:items-center mt-[4.5rem] lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
            <div>
              <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                Assign
              </p>
              <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
            </div>
            <Link to="/home">
              <Button
                onClick={() => setSection("dash")}
                sx={{
                  textTransform: "none",
                }}
              >
                <p className="lg:text-[18px] text-slate-500 font-poppins">
                  Home
                </p>
              </Button>
            </Link>
          </div>

          <form action="" onSubmit={(e) => handleSubmit(e)}>
            <div className=" bg-slate-800 py-8 px-6 rounded-3xl">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-4 w-full gap-y-4 ">
                <div className="flex flex-col gap-4 ">
                  <label
                    for="file"
                    className="text-slate-300 tracking-wide text-[18px]"
                  >
                    Select Hospital
                  </label>

                  <Autocomplete
                    disablePortal
                    id="doctor-select-demo"
                    sx={{
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgb(71,85,105)",
                          borderRadius: "1.2rem",
                          padding: "1rem 0.75rem",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        color: "whitesmoke",
                        position: "relative",
                        zIndex: "100",
                      },
                    }}
                    options={hospitalList}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      if (value) {
                        setHospital(value.name);
                        fetchDoctor(value.id);
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box {...props}>
                        <p className=" font-poppins text-slate-700 indent-2">
                          {option.name}
                        </p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                            Hospitals
                          </p>
                        }
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",

                            color: "#f0f0f0d2",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-4 ">
                  <label
                    for="file"
                    className="text-slate-300 tracking-wide text-[18px]"
                  >
                    Select Doctor
                  </label>

                  <Autocomplete
                    disablePortal
                    id="doctor-select-demo"
                    sx={{
                      fontFamily: "poppins",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgb(71,85,105)",
                          borderRadius: "1.2rem",
                          padding: "1rem 0.75rem",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976D2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976D2",
                        },
                      },
                      "& .MuiIconButton-root ": {
                        color: "whitesmoke",
                        position: "relative",
                        zIndex: "100",
                      },
                    }}
                    options={doctorList}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      if (value) {
                        setDocId(value.id);
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box {...props}>
                        <p className=" font-poppins text-slate-700 indent-2">
                          {option.name}
                        </p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <p className=" font-poppins text-slate-300 flex items-center justify-center h-full">
                            Doctors
                          </p>
                        }
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily: "poppins",

                            color: "#f0f0f0d2",
                          },
                          autoComplete: "off",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-4 ">
                  <label
                    for="file"
                    className="text-slate-300 tracking-wide text-[18px]"
                  >
                    Upload Zip File(*)
                  </label>
                  <input
                    type="file"
                    required
                    id="fileInput"
                    className="px-4 py-2 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 flex justify-center "
                    placeholder="Id"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              {fileInfo.length > 0 && (
                <div className="flex flex-col items-center justify-center mt-12">
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBorderRadius: 20,
                          headerBg: "#cbd5e1",
                          headerSortHoverBg: "",
                        },
                      },
                    }}
                  >
                    <Table
                      dataSource={fileInfo}
                      columns={columns}
                      className="rounded-[1.2rem] bg-[#cbd5e1]  text-slate-800  "
                      rowSelection={rowSelection}
                      pagination={{
                        pageSize: 6,
                        className: "bg-[#cbd5e1]  rounded-b-[1.2rem] ",
                      }}
                      scroll={{ y: 250, x: "calc(500px + 50%)" }}
                    />
                  </ConfigProvider>
                </div>
              )}
              <Button
                sx={{
                  borderRadius: "1.3rem",
                  padding: "1rem 2rem",
                  color: "#f0f0f0fe",
                  marginTop: "12px",
                  boxShadow: "inset -2px 1px 10px #f0f0f0fe",
                  textTransform: "none",
                }}
                type="submit"
              >
                <p className="font-poppins text-[18px] tracking-wide">Assign</p>
              </Button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};
export default TranscriptUpload;
