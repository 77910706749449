import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/StateContext";
import { Button, Radio } from "@mui/material";
import {
  ConfigProvider,
  Form,
  Input,
  DatePicker,
  Table,
  Typography,
  Dropdown,
} from "antd";
import { ColorRing, Grid } from "react-loader-spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../navbar";
import SideBarFloat from "../SideBarFloat";
import bg from "../../assets/D2.png";
import { Link } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { IoMenu } from "react-icons/io5";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { URLconstants } from "../../api/constants";

class PrintableContent extends React.Component {
  render() {
    return (
      <div>
        {/* Your printable content goes here */}
        <h1>Hello, world!</h1>
        <p>This is the content you want to print.</p>
      </div>
    );
  }
}

const PrintButton = () => {
  const componentRef = useRef();

  return (
    <div>
      {/* ReactToPrint component to wrap PrintableContent */}
      <ReactToPrint
        trigger={() => (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              width: "100%",
              display: "flex",
              padding: "8px 0",
              borderRadius: "12px",
              justifyContent: "center",
            }}
          >
            Print
          </Button>
        )} // Button to trigger printing
        content={() => componentRef.current} // Function to get the component to print
      />
      {/* PrintableContent component with ref */}
      <div className="hidden">
        <PrintableContent ref={componentRef} />
      </div>
    </div>
  );
};

const ManualTranscript = () => {
  const {
    setSection,
    handleDownload,
    rows,
    userType,
    user,
    setRows,
    pageSize,
    downloadFile,
    downloadAndAddToZip,
  } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedScripts, setSelectedScripts] = useState([]);
  const [signin, setSignin] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const [dowloading, setDownloading] = useState(false);
  const isEditing = (record) => record.key === editingKey;
  const [removing, setRemoving] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    userType === "6" ? "a" : "b"
  );
  const [staffHosp, setStaffHosp] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const [filterInfo, setFilterInfo] = useState({
    patient: "",
    voice: "",
    fromDate: "",
    toDate: "",
    doctor: "",
    hospital: "",
    work_type: "",
  });
  const heading = [
    "Id",
    "Patient_Name",
    "Hospital",
    "Mt_Name",
    "Date_of_Visit",
    "CreatedAt",
  ];
  const dataArray = rows.map((item) => [
    item.id,
    item.patient_name,
    item.hospital?.name,
    item.mt_name,
    item.voice_file_number,
    item.date_of_visit,
    item.createdAt,
  ]);

  const columns = [
    {
      title: "",
      dataIndex: "options",
      key: "options",
      width: "70px",
      align: "center",
      render: (item, records) => {
        const items = [
          {
            key: "0",
            label: (
              <div
                className="py-2 px-2"
                onClick={(e) => {
                  //open https://word-editor-website.capricontechnology.com/ in a new tab

                  if (records?.word_url && records.word_url.length > 1) {
                    const url = encodeURIComponent(records?.word_url);
                    console.log("OWrnd url ", records?.word_url);
                    const file_name =
                      records?.word_url.split("/")[
                        records?.word_url.split("/").length - 1
                      ];
                    console.log("FIlename", file_name);
                    window.open(
                      `https://word-editor-website.capricontechnology.com?file_url=${url}&file=${file_name}&manual=true&file_id=${records.id}`,
                      "_blank"
                    );
                  } else if (
                    records?.transcript_word_url &&
                    records.transcript_word_url.length > 1
                  ) {
                    const url = encodeURIComponent(
                      records?.transcript_word_url
                    );
                    const file_name =
                      records?.transcript_word_url.split("/")[
                        records?.transcript_word_url.split("/").length - 1
                      ];
                    console.log("FIlename", file_name);
                    window.open(
                      `https://word-editor-website.capricontechnology.com?file_url=${url}&file=${file_name}&manual=true&file_id=${records.id}`,
                      "_blank"
                    );
                  }
                }}
              >
                <p className=" font-poppins text-slate-700 m-0">Edit File</p>
              </div>
            ),
          },
          {
            key: "1",
            label: (
              <div
                className="py-2 px-2"
                onClick={(e) => {
                  const finalName =
                    records.patient_name +
                    "_" +
                    records.hospital?.name +
                    "_" +
                    records.createdAt.split("T")[0];
                  if (records.word_url && records.word_url.length > 1) {
                    const ext1 = decodeURI(records.word_url).split(" ").pop();
                    const ext2 = ext1.split("?")[0].split(".");

                    downloadFile(
                      e,
                      records?.word_url,
                      `${finalName}.${ext2.pop()}`
                    );
                  } else if (records.transcript_word_url) {
                    const ext1 = decodeURI(records.transcript_word_url)
                      .split(" ")
                      .pop();
                    const ext2 = ext1.split("?")[0].split(".");

                    downloadFile(
                      e,
                      records?.transcript_word_url,
                      `${finalName}.${ext2.pop()}`
                    );
                  }
                }}
              >
                <p className=" font-poppins text-slate-700 m-0">
                  Download Report
                </p>
              </div>
            ),
          },
        ];
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button>
              <IoMenu size={20} />
            </Button>
          </Dropdown>
        );
      },
      align: "center",
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="patient_name"
              rules={[
                {
                  required: true,
                  message: "Please Enter your name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    ...(userType === "0"
      ? [
          {
            title: "Hospital",
            dataIndex: "hospital",
            key: "hospital",
            render: (item) =>
              item && (
                <p className="font-poppins text-slate-700 text-[16px]">
                  {item.name}
                </p>
              ),
          },
        ]
      : []),
    ...(userType < 4 || userType === "6"
      ? [
          {
            title: "Doctor",
            dataIndex: "doctor",
            key: "doctor",
            render: (item) =>
              item && (
                <p className="font-poppins text-slate-700 text-[16px]">
                  {item.name}
                </p>
              ),
          },
        ]
      : []),
    {
      title: "Voice_file_No.",
      dataIndex: "voice_file_number",
      key: "voice_file_number",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="voice_file_number"
              rules={[
                {
                  required: true,
                  message: "Please Enter your voice file no.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Work Type",
      dataIndex: "work_type",
      key: "work_type",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="work_type"
              rules={[
                {
                  required: true,
                  message: "Please Enter your work type",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Date_of_Visit",
      dataIndex: "date_of_visit",
      key: "date_of_visit",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="date_of_visit"
              rules={[
                {
                  required: true,
                  message: "Please Enter date",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => item.split("T")[0],
    },
    ...(userType === "6" || userType === "5"
      ? []
      : [
          {
            title: "Sign",
            dataIndex: "sign",
            key: "sign",
            render: (_, record) => (
              <Button
                disabled={record.signed ? true : false}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  display: "flex",
                  padding: "8px 0",
                  borderRadius: "12px",
                  justifyContent: "center",
                }}
                onClick={(e) =>
                  record.doctor?.signature
                    ? SignIndividualManualScript(e, record)
                    : toast.error(
                        <p className="font-poppins text-slate-600">
                          Please Upload Signature
                        </p>,
                        {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      )
                }
              >
                <p className="font-poppins">Sign</p>
              </Button>
            ),
          },
        ]),
    ...(userType === "5"
      ? [
          {
            title: "Sign",
            dataIndex: "sign",
            key: "sign",
            render: (_, record) => (
              <Button
                disabled={record.nurse_signed}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  display: "flex",
                  padding: "8px 0",
                  borderRadius: "12px",
                  justifyContent: "center",
                }}
                onClick={async (e) => {
                  try {
                    const { data, status } = await axios.put(
                      `${URLconstants.fetchManualTranscripts()}/${record?.id}`,
                      {
                        data: {
                          nurse_signed: true,
                        },
                      }
                    );
                    console.log(data);
                    if (status === 200) {
                      await fetchFunction(
                        1,
                        filterInfo.patient,
                        filterInfo.voice,
                        filterInfo.work_type,
                        filterInfo.doctor,
                        filterInfo.hospital,
                        filterInfo.fromDate,
                        filterInfo.toDate
                      );
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <p className="font-poppins">Sign</p>
              </Button>
            ),
          },
        ]
      : []),

    ...(userType === "0"
      ? [
          {
            title: "",
            dataIndex: "action",
            width: `${editingKey !== "" ? "10%" : "6%"}`,
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <span className=" flex gap-2">
                  <Typography.Link
                    onClick={() => save(record.id)}
                    className=" font-poppins"
                  >
                    Save
                  </Typography.Link>

                  <Typography.Link
                    title="Sure to cancel?"
                    className=" font-poppins"
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Typography.Link>
                </span>
              ) : (
                <Typography.Link
                  className={`font-poppins `}
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  Edit
                </Typography.Link>
              );
            },
          },
        ]
      : []),
  ];
  const edit = (record) => {
    form.setFieldsValue({
      patient_name: record.patient_name,
      voice_file_number: record.voice_file_number,
      work_type: record.work_type,
      date_of_visit: record.date_of_visit,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const { data } = await axios.put(
        `${URLconstants.fetchManualTranscripts()}/${id}`,
        {
          data: row,
        }
      );
      await await fetchFunction(
        currentPage,
        filterInfo.patient,
        filterInfo.voice,
        filterInfo.work_type,
        filterInfo.doctor,
        filterInfo.hospital,
        filterInfo.fromDate,
        filterInfo.toDate
      );
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setEditingKey("");
    }
  };
  const filterManualScripts = async (e, page) => {
    e.preventDefault();

    try {
      setCurrentPage(1);
      setSelectedRowKeys([]);
      if (userType === "4") {
        setFilterInfo({
          patient: e.target[0].value,
          voice: e.target[1].value,
          fromDate: e.target[3].value,
          work_type: e.target[2].value,
          toDate: e.target[4].value,
        });

        await fetchFunction(
          page,
          e.target[0].value,
          e.target[1].value,
          e.target[2].value,
          "",
          "",
          e.target[3].value,
          e.target[4].value
        );
      } else if (userType === "2" || userType === "6" || userType === "5") {
        setFilterInfo({
          patient: e.target[0].value,
          voice: e.target[1].value,
          work_type: e.target[2].value,
          doctor: e.target[3].value,
          fromDate: e.target[4].value,
          toDate: e.target[5].value,
        });

        await fetchFunction(
          page,
          e.target[0].value,
          e.target[1].value,
          e.target[2].value,
          e.target[3].value,
          "",
          e.target[4].value,
          e.target[5].value
        );
      } else if (userType === "0") {
        setFilterInfo({
          patient: e.target[0].value,
          voice: e.target[1].value,
          work_type: e.target[2].value,
          fromDate: e.target[5].value,
          toDate: e.target[6].value,
          doctor: e.target[3].value,
          hospital: e.target[4].value,
        });
        await fetchFunction(
          page,
          e.target[0].value,
          e.target[1].value,
          e.target[2].value,
          e.target[3].value,
          e.target[4].value,
          e.target[5].value,
          e.target[6].value
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SignManualScripts = async (e) => {
    e.preventDefault();
    try {
      setSignin(true);
      for (let i = 0; i < selectedScripts.length; i++) {
        const { data, status } = await axios.post(
          URLconstants.signManualScripts(),
          {
            word_url: selectedScripts[i].word_url,
            signature_url: selectedScripts[i].doctor?.signature,
            doctor_name: selectedScripts[i].doctor?.name,
          }
        );

        if (status === 200) {
          const response = await axios.put(
            `${URLconstants.fetchManualTranscripts()}/${selectedScripts[i].id}`,
            {
              data: {
                signed: true,
                word_url: data?.url,
              },
            }
          );
        }
      }
      toast.success(
        <p className="font-poppins text-slate-600">Signed Transcripts</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <p className="font-poppins text-slate-600">{error.message}</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setSignin(false);
    } finally {
      setSignin(false);
      fetchFunction(1);
      selectedRowKeys([]);
    }
  };

  const SignIndividualManualScript = async (e, record) => {
    e.preventDefault();
    try {
      setSignin(true);
      const { data, status } = await axios.post(
        URLconstants.signManualScripts(),
        {
          word_url: record.word_url,
          signature_url: record.doctor?.signature,
          doctor_name: record.doctor?.name,
        }
      );

      if (status === 200) {
        const response = await axios.put(
          `${URLconstants.fetchManualTranscripts()}/${record.id}`,
          {
            data: {
              signed: true,
              word_url: data?.url,
            },
          }
        );
        await fetchFunction(1);
      }
      toast.success(
        <p className="font-poppins text-slate-600">Signed Transcript</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <p className="font-poppins text-slate-600">{error.message}</p>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setSignin(false);
    } finally {
      setSignin(false);
    }
  };

  const fetchFunction = async (
    page,
    patient,
    voice,
    work_type,
    doctor,
    hospital,
    fromDate,
    toDate
  ) => {
    const userTypeFilter =
      userType === "4"
        ? `&filters[doctor][id][$eq]=${user.id}`
        : userType === "2"
        ? `&filters[hospital][id][$eq]=${user.id}`
        : "";

    const hospitalFilter =
      userType === "0" && hospital
        ? `&filters[hospital][name][$containsi]=${hospital}`
        : "";
    const doctorFilter =
      (userType <= 2 || userType === "6") && doctor
        ? `&filters[doctor][name][$containsi]=${doctor}`
        : "";
    let dateFilter = "";

    if (fromDate && toDate) {
      const createdAtFilterEnd = `${toDate}`;
      const createdAtFilterStart = `${fromDate}`;
      dateFilter = `&filters[date_of_visit][$gte]=${createdAtFilterStart}&filters[date_of_visit][$lte]=${createdAtFilterEnd}`;
    } else if (fromDate) {
      const createdAtFilterStart = `${fromDate}`;
      dateFilter = `&filters[date_of_visit][$gte]=${createdAtFilterStart}`;
    } else if (toDate) {
      const createdAtFilterEnd = `${toDate}`;
      dateFilter = `&filters[date_of_visit][$lte]=${createdAtFilterEnd}`;
    }

    try {
      if (userType === "6") {
        const { data } = await axios.get(
          `${URLconstants.fetchManualTranscripts()}?&pagination[pageSize]=10&pagination[page]=${page}&filters[visibility]=true&populate=hospital,doctor${
            selectedValue === "b"
              ? "&filters[signed][$eq]=false"
              : "&filters[signed][$eq]=true"
          }&filters[hospital][id]=${staffHosp}${
            patient && `&filters[patient_name][$containsi]=${patient}`
          }${voice && `&filters[voice_file_number][$eq]=${voice}`}${
            work_type && `&filters[work_type][$containsi]=${work_type}`
          }${doctorFilter}${dateFilter}&sort[0]=createdAt:desc`
        );

        const bodyWithKeys = data.data.map((item, index) => ({
          ...item,
          key: index,
        }));
        setRows(bodyWithKeys);
        setTotalRecords(data.meta.pagination.total);
      } else if (userType === "5") {
        const { data } = await axios.get(
          `${URLconstants.fetchManualTranscripts()}?
          &filters[visibility]=true&populate=hospital,doctor&filters[hospital][id]=${staffHosp}${
            patient && `&filters[patient_name][$containsi]=${patient}`
          }${voice && `&filters[voice_file_number][$eq]=${voice}`}${
            work_type && `&filters[work_type][$containsi]=${work_type}`
          }${doctorFilter}${dateFilter}&sort[0]=createdAt:desc`
        );

        const bodyWithKeys = data.data.map((item, index) => ({
          ...item,
          key: index,
        }));
        setRows(bodyWithKeys);
        setTotalRecords(data.meta.pagination.total);
      } else {
        const { data } = await axios.get(
          `${URLconstants.fetchManualTranscripts()}?populate=hospital,doctor&filters[signed][$eq]=${
            selectedValue === "b" ? "false" : "true"
          }${patient && `&filters[patient_name][$containsi]=${patient}`}${
            voice && `&filters[voice_file_number][$eq]=${voice}`
          }${
            work_type && `&filters[work_type][$containsi]=${work_type}`
          }${dateFilter}${doctorFilter}${hospitalFilter}${userTypeFilter}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[visibility]=true&sort[0]=createdAt:desc`
        );

        const bodyWithKeys = data.data.map((item, index) => ({
          ...item,
          key: index,
        }));
        setRows(bodyWithKeys);
        setTotalRecords(data.meta.pagination.total);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveAll = async (e) => {
    e.preventDefault();
    try {
      setRemoving(true);
      for (const items of selectedScripts) {
        const { data } = await axios.put(
          `${URLconstants.fetchManualTranscripts()}/${items.id}`,
          {
            data: {
              visibility: false,
            },
          }
        );
        console.log(data);
      }
      setSelectedRowKeys([]);
      await fetchFunction(currentPage);
    } catch (error) {
      console.log(error);
    } finally {
      setRemoving(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    console.log(page);
    fetchFunction(
      page,
      filterInfo.patient,
      filterInfo.voice,
      filterInfo.work_type,
      filterInfo.doctor,
      filterInfo.hospital,
      filterInfo.fromDate,
      filterInfo.toDate
    );
  };
  const handleDownloadAll = async (e) => {
    e.preventDefault();
    try {
      toast.info(
        <p className=" text-slate-700 font-poppins">Download in progress!!</p>,
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      const zip = new JSZip();
      setDownloading(true);
      for (const item of selectedScripts) {
        const {
          word_url,
          createdAt,
          hospital,
          patient_name,
          transcript_word_url,
        } = item;
        const finalName =
          patient_name + "_" + hospital?.name + "_" + createdAt.split("T")[0];
        if (word_url) {
          const ext1 = decodeURI(word_url).split(" ").pop();
          const ext2 = ext1.split("?")[0].split(".");
          await downloadAndAddToZip(zip, word_url, ext2.pop(), finalName);
        } else if (transcript_word_url) {
          const ext1 = decodeURI(transcript_word_url).split(" ").pop();
          const ext2 = ext1.split("?")[0].split(".");
          await downloadAndAddToZip(
            zip,
            transcript_word_url,
            ext2.pop(),
            finalName
          );
        }
      }

      // Generate a blob containing the zip file
      zip.generateAsync({ type: "blob" }).then((zipBlob) => {
        // Save the zip blob as a file
        setDownloading(false);
        saveAs(zipBlob, "downloaded_files.zip");
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedRowKeys([]);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },

    onSelect: (record, selected, selectedRows, nativeEvent) => {
      console.log("onSelect", selectedRows);
      setSelectedScripts(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log("onSelectAll", selectedRows);
      setSelectedScripts(selectedRows);
    },
  };
  const fetchStaffHospital = async () => {
    const { data } = await axios.get(
      `${URLconstants.BASE_URL}/api/${userType === "5" ? "nurses" : "staffs"}/${
        user?.id
      }?&populate=hospital`
    );
    setStaffHosp(data.data.hospital?.id);
  };

  useEffect(() => {
    const fetchDataAndToggleLoading = async () => {
      try {
        setLoading(true);
        if ((userType === "6" || userType === "5") && !staffHosp) {
          fetchStaffHospital();
        } else {
          await fetchFunction(
            1,
            filterInfo.patient,
            filterInfo.voice,
            filterInfo.work_type,
            filterInfo.doctor,
            filterInfo.hospital,
            filterInfo.fromDate,
            filterInfo.toDate
          );
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error(
          <p className="font-poppins text-slate-600">Error Fetching Data!!</p>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndToggleLoading();
  }, [staffHosp]);

  return (
    <>
      <Navbar />
      <SideBarFloat />
      <section className="scroll-smooth h-screen  bg-[#f0f0f0ea] font-poppins relative overflow-clip">
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>
        {signin && (
          <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
            <Grid
              visible={true}
              height="80"
              width="80"
              color="#4A57EC"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="grid-wrapper"
            />
          </div>
        )}
        <div
          className={`px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100] ${
            signin && "opacity-30"
          }`}
        >
          <div className="flex pt-10 pb-10 mt-[4.5rem] justify-between lg:items-center md:items-end items-start lg:flex-row md:flex-row flex-col relative">
            <div className="flex gap-4 md:items-center lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
              <div>
                <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                  {userType === "6" ? "Transcripts" : "Manual Transcript"}
                </p>
                <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
              </div>

              <Link to="/home">
                <Button
                  onClick={() => setSection("dash")}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <p className="lg:text-[18px] text-slate-500 font-poppins">
                    Home
                  </p>
                </Button>
              </Link>
            </div>

            <div className=" rounded-[1.5rem]  bg-slate-900">
              <Button
                sx={{
                  textTransform: "none",
                  borderRadius: "1.5rem",
                  padding: "10px",
                }}
                onClick={() => handleDownload(dataArray, heading)}
              >
                <p className="lg:text-[18px] font-poppins font-light text-slate-200  ">
                  Download Excel
                </p>
              </Button>
            </div>
          </div>

          <div className="lg:mt-6 md:mt-4 mt-2 pb-10  lg:w-full md:w-4/5  ">
            <div className="border-[2px] lg:hidden md:hidden block border-dashed border-slate-600 mb-12 "></div>
            <form
              action=""
              onSubmit={(e) => {
                filterManualScripts(e, 1);
              }}
              className="flex lg:flex-row md:flex-row flex-col lg:items-end md:items-end items-start gap-4  font-poppins"
            >
              <div
                className={`grid lg:grid-cols-5
                
            md:grid-cols-2 grid-cols-1 gap-x-3 gap-y-4`}
              >
                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Patient Name
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400   "
                    placeholder="Patient Name"
                  />
                </div>

                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Voice_File_No.
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400   "
                    placeholder="Voice_File_No."
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Work_Type
                  </label>
                  <input
                    type="text"
                    className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400 "
                    placeholder="Work_Type"
                  />
                </div>

                {(userType <= 2 || userType === "6") && (
                  <div className="flex flex-col gap-3">
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      Doctor Name
                    </label>
                    <input
                      type="text"
                      className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400   "
                      placeholder="Doctor Name"
                    />
                  </div>
                )}
                {userType === "0" && (
                  <div className="flex flex-col gap-3">
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      Hospital Name
                    </label>
                    <input
                      type="text"
                      className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-200 placeholder:text-[16px] bg-slate-400   "
                      placeholder="Hospital Name"
                    />
                  </div>
                )}
                <div className="flex flex-col gap-[0.6rem]">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Date_of_Visit
                  </label>
                  <ConfigProvider
                    theme={{
                      components: {
                        DatePicker: {
                          zIndexPopup: 1100,
                          fontSize: "16px",
                          colorPrimaryHover: "none",
                          colorTextDisabled: "rgb(226,232,240)",
                          colorTextDescription: "rgb(100,116,139)",
                          colorText: "rgb(51,65,85)",
                          colorTextPlaceholder: "rgb(226,232,240)",
                        },
                      },
                      token: {
                        colorIcon: "whitesmoke",
                      },
                    }}
                  >
                    <DatePicker
                      placeholder="From"
                      style={{
                        padding: "12px",
                        borderRadius: "1.2rem",
                        fontFamily: "Poppins",
                        background: "rgb(143,168,184)",
                      }}
                    />
                  </ConfigProvider>
                </div>
                <div className="flex flex-col gap-[0.6rem]">
                  <label htmlFor="" className="text-slate-600 text-[18px] ">
                    Date_of_Visit
                  </label>
                  <ConfigProvider
                    theme={{
                      components: {
                        DatePicker: {
                          zIndexPopup: 1100,
                          fontSize: "16px",
                          colorPrimaryHover: "none",
                          colorTextDisabled: "rgb(226,232,240)",
                          colorTextDescription: "rgb(100,116,139)",
                          colorText: "rgb(51,65,85)",
                          colorTextPlaceholder: "rgb(226,232,240)",
                        },
                      },
                      token: {
                        colorIcon: "whitesmoke",
                      },
                    }}
                  >
                    <DatePicker
                      placeholder="To"
                      style={{
                        padding: "12px",
                        borderRadius: "1.2rem",
                        fontFamily: "Poppins",
                        background: "rgb(143,168,184)",
                      }}
                    />
                  </ConfigProvider>
                </div>

                <div className="flex items-end">
                  <div>
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      Signed
                    </label>
                    <Radio {...controlProps("a")} size="medium" />
                  </div>
                  <div>
                    <label htmlFor="" className="text-slate-600 text-[18px] ">
                      UnSigned
                    </label>
                    <Radio {...controlProps("b")} size="medium" />
                  </div>
                </div>

                <div
                  className={`lg:mt-9 ${userType === "0" && "md:mt-9"} mt-6`}
                >
                  <Button
                    sx={{
                      border: "1px solid rgb(203,213,225)",
                      borderRadius: "1.3rem",
                      padding: "10px 2rem",
                      color: "",

                      boxShadow: "inset -2px 1px 10px rgb(203,213,225)",
                    }}
                    type="submit"
                  >
                    <p className="font-poppins text-slate-600 text-[16px]">
                      Find
                    </p>
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <div className="lg:py-8 md:py-8 pt-4 pb-[6rem] flex items-center justify-center ">
            {loading ? (
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#27284a", "#3c4a74", "#4f6ea0", "#5f96cd", "#6ec0fa"]}
              />
            ) : (
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBorderRadius: 20,
                      headerBg: "#cbd5e1",
                      headerSortHoverBg: "",
                    },
                  },
                }}
              >
                <Form form={form} component={false}>
                  <Table
                    dataSource={rows}
                    columns={columns}
                    className="rounded-[1.5rem]   text-slate-800 shadow-lg shadow-slate-300  "
                    rowSelection={rowSelection}
                    footer={() => (
                      <div className="flex gap-2">
                        {selectedRowKeys.length > 0 &&
                          selectedValue === "b" &&
                          userType !== "6" && (
                            <Button
                              onClick={(e) => SignManualScripts(e)}
                              sx={{
                                textTransform: "none",
                                border: "1px solid lightgrey",
                                borderRadius: "0.8rem",
                              }}
                            >
                              <p className="font-poppins text-[16px] text-slate-700">
                                {signin
                                  ? "Signing..."
                                  : `${
                                      selectedRowKeys.length === 1
                                        ? "Sign"
                                        : "Sign All"
                                    }`}
                              </p>
                            </Button>
                          )}
                        {selectedRowKeys.length > 0 && (
                          <Button
                            onClick={(e) => handleDownloadAll(e)}
                            sx={{
                              textTransform: "none",
                              border: "1px solid lightgrey",
                              borderRadius: "0.8rem",
                            }}
                          >
                            <p className="font-poppins text-[16px] text-slate-700">
                              {dowloading
                                ? "Downloading..."
                                : `${
                                    selectedRowKeys.length === 1
                                      ? "Download"
                                      : "Download All"
                                  }`}
                            </p>
                          </Button>
                        )}
                        {selectedRowKeys.length > 0 && (
                          <Button
                            onClick={(e) => handleRemoveAll(e)}
                            sx={{
                              textTransform: "none",
                              border: "1px solid lightgrey",
                              borderRadius: "0.8rem",
                            }}
                          >
                            <p className="font-poppins text-[16px] text-slate-700">
                              {removing ? "Removing..." : "Remove"}
                            </p>
                          </Button>
                        )}
                      </div>
                    )}
                    pagination={{
                      current: currentPage,
                      pageSize: pageSize,
                      showSizeChanger: false,
                      total: totalRecords,
                      onChange: handlePageChange,
                      style: {
                        padding: "0 12px",
                        fontFamily: "Poppins",
                      },
                    }}
                    scroll={{ y: 450, x: "calc(550px + 50%)" }}
                  />
                </Form>
              </ConfigProvider>
            )}
          </div>
        </div>

        <ToastContainer />
      </section>
    </>
  );
};

export default ManualTranscript;
