import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Context } from "../../context/StateContext";
import axios from "axios";
import { Grid } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoTimeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import { URLconstants } from "../../api/constants";

const AddRecordings = () => {
  const { setSection, icons, loading, setLoading, user, userType } =
    useContext(Context);
  const [doc_id, setDocId] = useState("");
  const [tats, setTats] = useState([]);
  const [info, setinfo] = useState({
    id: "",
    doctor_id: "",
    qualifications: "",
    date: "",
    name: "",
    signature: "",
  });
  const navigate = useNavigate();
  const [itemList, setList] = useState([]);
  const [tatList, setTatList] = useState("");
  const [file, setFile] = useState("");

  const handleTatChange = (event) => {
    setTatList(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileSubmit1 = async (e) => {
    e.preventDefault();
    console.log("E>TRAGET", e.target);
    const formVal = new FormData(e.target);
    console.log("FORM VAL", Object.fromEntries(formVal));

    let allValues = Object.fromEntries(formVal);
    const date = new Date();
    const formData = new FormData();
    formData.append("testAudioFile", file);

    formData.append(
      "destination",
      `${info.doctor_id}/${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()}/${info.id}/${doc_id}.m4a`
    );

    try {
      setLoading(true);
      const response = await axios.post(URLconstants.uploadAudio(), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      console.log(response.data);
      if (response.status === 200) {
        handleFileSave(
          e,
          info.id,
          response.data["response"]["mediaLink"],
          `${info.name},${info.qualifications}`,
          allValues.patient_name,
          allValues.patient_dob,
          allValues.patient_id,
          doc_id,
          tatList
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleFileSave = async (
    e,
    doctor_id,
    url,
    name,
    patient_name,
    patient_dob,
    patient_id,
    counter_id,
    tat
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        URLconstants.saveURL(),
        JSON.stringify({
          doctor: doctor_id,
          recording_url: url,
          doctor_name: name,
          patient_name: patient_name,
          patient_dob: patient_dob,
          patient_id: patient_id,
          counter_id: counter_id,
          tat: tat,
          doctor_signature: info.signature,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      console.log(response.data);
      if (response.status === 200) {
        toast.done("Recording Uploaded!!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(
        <p className=" text-slate-700 font-poppins">
          Error in Uploading Recording
        </p>,
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } finally {
      setLoading(false);
      navigate("/home");
      setSection("dash");
    }
  };

  const fetchDoctor = async () => {
    try {
      const { data } = await axios.get(
        `${URLconstants.BASE_URL}/api/doctors?${
          userType === "1"
            ? `&filters[hospital][organisation][id]=${user.id}`
            : userType === "2"
            ? `&filters[hospital][id]=${user.id}`
            : ""
        }&filters[visibility][$eq]=true`
      );

      const total = data.meta.pagination.total;
      const pages = Math.ceil(total / 100);
      for (let i = 1; i <= pages; i++) {
        const { data } = await axios.get(
          `${URLconstants.BASE_URL}/api/doctors?${
            userType === "1"
              ? `&filters[hospital][organisation][id]=${user.id}`
              : userType === "2"
              ? `&filters[hospital][id]=${user.id}`
              : ""
          }&filters[visibility][$eq]=true&pagination[pageSize]=100&pagination[page]=${i}`
        );
        setList((prev) => [...prev, ...data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDoctor();
    const doctorWithKeys = itemList.map((item, index) => {
      return {
        ...item,
        key: index,
      };
    });
    setList(doctorWithKeys);
  }, []);

  useEffect(() => {
    if (info.id) {
      const fetchData = async () => {
        try {
          const { data } = await axios.get(
            `${URLconstants.BASE_URL}/api/doctors/${info.id}`
          );
          setDocId(data.data.transcript_counter);
        } catch (error) {
          console.log(error);
        }
      };
      const fetchTat = async () => {
        try {
          const { data, status } = await axios.get(
            `${URLconstants.BASE_URL}/api/tats?populate=*&filters[doctor][id][$eq]=${info.id}`
          );

          data.data.length > 0
            ? setTats(data.data[0].timing["time"])
            : setTats(["24:00"]);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
      fetchTat();
    }
  }, [info.id]);

  return (
    <>
      <Navbar />
      <section className="relative ">
        {loading && (
          <div className="absolute top-[50%] left-[40%]  translate-x-1/2 -translate-y-1/2 z-[1100] ">
            <Grid
              visible={true}
              height="80"
              width="80"
              color="#4A57EC"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="grid-wrapper"
            />
          </div>
        )}

        <div className="font-poppins px-8 relative overflow-x-clip overflow-y-scroll scrollbar-hide ">
          <div className="flex gap-4 pt-10 pb-10 mt-[4.5rem] md:items-center lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
            <div>
              <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                Add Recordings
              </p>
              <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
            </div>
            <Link to="/home">
              <Button
                onClick={() => setSection("dash")}
                sx={{
                  textTransform: "none",
                }}
              >
                <p className="lg:text-[18px] text-slate-500 font-poppins">
                  Home
                </p>
              </Button>
            </Link>
          </div>
          <div
            className={` mb-4 py-10 px-8 flex flex-col gap-10  bg-slate-800 rounded-[1.5rem] ${
              loading && "opacity-[0.4]"
            } `}
          >
            <form
              action=""
              onSubmit={(e) =>
                info.signature
                  ? handleFileSubmit1(e)
                  : toast.error(
                      <p className="font-poppins text-slate-600">
                        Doctor doesn't have signature
                      </p>,
                      {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    )
              }
              className="flex flex-col gap-4 font-poppins w-full"
            >
              <p className="text-[24px] text-slate-200">Store Recording</p>

              <div className="flex flex-col items-center gap-4">
                <div className="flex lg:flex-row md:flex-row flex-col w-full gap-4">
                  <div className="flex  flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-300 text-[18px] ">
                      Doctor
                    </label>
                    <Autocomplete
                      disablePortal
                      id="doctor-select-demo"
                      sx={{
                        width: "100%",
                        fontFamily: "poppins",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "rgb(71,85,105)",
                            borderRadius: "1.2rem",
                            padding: "0px",
                            background: "rgb(36,49,67)",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1976D2",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1976D2",
                          },
                        },
                        "& .MuiIconButton-root ": {
                          "& fieldset": {
                            color: "whitesmoke",
                          },
                        },
                        "& .MuiIconButton-root ": {
                          color: "whitesmoke",
                          position: "relative",
                          zIndex: "100",
                        },
                      }}
                      options={itemList}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, value) => {
                        event.stopPropagation();
                        if (value) {
                          setinfo((prev) => ({
                            ...prev,
                            id: value.id,
                            qualifications: value.qualifications,
                            name: value.name,
                            doctor_id: value.doctor_id,
                            signature: value.signature,
                          }));
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box {...props}>
                          <p className=" font-poppins text-slate-700 indent-2">
                            {option.name}
                          </p>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Doctors"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontFamily: "poppins",
                              background: "rgb(36,49,67)",
                              color: "#f0f0f0d2",
                              position: "relative",
                              zIndex: "100",
                              margin: "0px",
                              padding: "0.5rem",
                              borderRadius: "0.75rem",
                            },
                            autoComplete: "off",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-300 text-[18px] ">
                      Patient Name
                    </label>
                    <input
                      type="text"
                      required
                      name="patient_name"
                      className="px-4 py-3 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 text-[18px]"
                      placeholder="Patient Name"
                    />
                  </div>
                </div>

                <div className="flex w-full lg:flex-row md:flex-row flex-col items-center gap-4">
                  <div className="flex flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-300 text-[18px] ">
                      Patient DOB
                    </label>
                    <input
                      type="date"
                      required
                      name="patient_dob"
                      className="px-4 py-[0.75rem] rounded-[1.2rem] outline-none font-poppins text-[#f0f0f0d2] bg-slate-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 text-[16px] "
                      placeholder="Date-of-Birth"
                    />
                  </div>
                  <div className="flex flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-300 text-[18px] ">
                      Patient Id
                    </label>
                    <input
                      type="text"
                      required
                      name="patient_id"
                      className="px-4 py-[0.75rem] rounded-[1.2rem] outline-none font-poppins text-[#f0f0f0d2] bg-slate-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 text-[18px] "
                      placeholder="Description"
                    />
                  </div>
                </div>

                <div className="flex lg:flex-row md:flex-row flex-col w-full gap-4 lg:items-end md:items-end items-start">
                  <div className="flex  flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-300 text-[18px] ">
                      Tats
                    </label>
                    <FormControl sx={{}}>
                      <Select
                        required
                        sx={{
                          width: "full",
                          fontSize: "16px",
                          color: "#f0f0f0da",
                          fontFamily: "poppins",
                          height: "50px",
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid rgb(71,85,105)",
                          },
                          background: "rgb(36,49,67)",
                          borderRadius: "1.2rem",
                          backgroundClip: "padding-box",
                          backdropFilter: "blur(10px)",
                          outline: "none",
                          padding: "1.75rem 0.5rem",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleTatChange(e)}
                        value={tatList}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <p className="font-poppins text-[16px] text-slate-200 mr-4">
                                Tats
                              </p>
                            );
                          }
                          return selected;
                        }}
                      >
                        {tats.map((i) => (
                          <MenuItem
                            value={i}
                            sx={{
                              fontFamily: "Poppins",
                            }}
                          >
                            <div className="flex gap-1 items-center">
                              <IoTimeOutline />
                              <p className="font-poppins text-[16px] text-slate-700">
                                {i}
                              </p>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex  flex-col gap-3 w-full">
                    <label htmlFor="" className="text-slate-300 text-[18px] ">
                      Select File
                    </label>
                    <input
                      type="file"
                      accept="audio/*"
                      required
                      className="px-4 py-2 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 flex justify-center w-full"
                      placeholder="Id"
                      onChange={(e) => handleFileChange(e)}
                    />
                  </div>
                  <Button
                    sx={{
                      borderRadius: "1.3rem",
                      padding: "1rem 2rem",
                      color: "#f0f0f0fe",
                      boxShadow: "inset -2px 1px 10px #f0f0f0fe",
                    }}
                    type="submit"
                  >
                    <p className="font-poppins text-[16px]">Submit</p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default AddRecordings;
