import React, { useContext, useEffect, useState } from "react";
import Navbar from "../navbar";
import SideBarFloat from "../SideBarFloat";
import bg from "../../assets/D2.png";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "antd";
import { IoIosAdd, IoMdClose, IoMdSave } from "react-icons/io";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Autocomplete, Box, TextField, Checkbox } from "@mui/material";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Context } from "../../context/StateContext";
import axios from "axios";
import { URLconstants } from "../../api/constants";
import { Grid } from "react-loader-spinner";
const EditWorkpool = ({
  record,
  workTypes,
  dictators,
  setEditPool,
  hospitalList,
}) => {
  const { icons, setLoading, loading } = useContext(Context);
  const [currClinics, setClinics] = useState([]);
  const [currDoctors, setDoctors] = useState([]);
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value5, setValue5] = useState([]);
  const [groupInfo, setGroupINfo] = useState({
    name: "",
    doctors: [],
    clinics: [],
    work_types: [],
    transcripters: [],
  });
  console.log(record);
  const editGroup = async (e) => {
    e.preventDefault();
    const Dids = record.doctors?.map((i) => i.id);
    const Cids = record.clinics?.map((i) => i.id);
    const Tids = record.transcripters?.map((i) => i.id);
    try {
      setLoading(true);
      const { data, status } = await axios.put(
        `${URLconstants.BASE_URL}/api/groups/${record?.id}`,
        {
          data: {
            name: groupInfo.name ? record.name : groupInfo.name,
            doctors: [...Dids, ...groupInfo.doctors],
            clinics: [...Cids, ...groupInfo.clinics],
            transcripters: [...Tids, ...groupInfo.transcripters],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      if (status === 200) {
        toast.success(<p className=" font-poppins">Group Edited!</p>, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setEditPool(false);
      }
    } catch (error) {
      console.log(error);

      toast.error(<p className=" font-poppins">{error.message}</p>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setGroupINfo({
      doctors: [],
      clinics: [],
      transcripters: [],
      work_types: [],
    });
    setValue([]);
    setValue2([]);
    setValue3([]);
    setValue4([]);
    setValue5([]);
    setClinics([]);
    setDoctors([]);
  }, []);
  return (
    <section>
      <Navbar />
      <SideBarFloat />
      <div className="h-screen w-full flex  bg-[#f0f0f0ea] font-poppins relative overflow-clip ">
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>
        {loading && (
          <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
            <Grid
              visible={true}
              height="80"
              width="80"
              color="#4A57EC"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="grid-wrapper"
            />
          </div>
        )}
        <div
          className={` ${
            loading && " opacity-30"
          } px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100] flex flex-col`}
        >
          <div className=" grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-28">
            <div className="font-poppins flex flex-col gap-3 w-full">
              <label htmlFor="" className="text-slate-600 text-[18px] ">
                File Types
              </label>
              <Autocomplete
                disablePortal
                id="doctor-select-demo"
                sx={{
                  height: "100%",

                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      background: "rgb(148,163,184)",

                      borderRadius: "1.2rem",
                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    color: "whitesmoke",
                    position: "relative",
                    zIndex: "100",
                  },
                }}
                options={[]}
                getOptionLabel={(option) => option.name || option.email}
                onChange={(event, value) => {
                  console.log(value);
                }}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <p className="font-poppins m-0 text-[14px] text-slate-700">
                      {option.name || option.email}
                    </p>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="All Files"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",
                        background: "rgb(148,163,184)",
                        color: "rgb(51,65,85)",

                        position: "relative",
                        zIndex: "100",
                        padding: "0.25rem",
                        borderRadius: "1.2rem",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="font-poppins flex flex-col gap-3 w-full">
              <label htmlFor="" className="text-slate-600 text-[18px] ">
                STAT Types
              </label>
              <Autocomplete
                disablePortal
                id="doctor-select-demo"
                sx={{
                  height: "100%",

                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      background: "rgb(148,163,184)",

                      borderRadius: "1.2rem",
                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    color: "whitesmoke",
                    position: "relative",
                    zIndex: "100",
                  },
                }}
                options={[]}
                getOptionLabel={(option) => option.name || option.email}
                onChange={(event, value) => {
                  console.log(value);
                }}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <p className="font-poppins m-0 text-[14px] text-slate-700">
                      {option.name || option.email}
                    </p>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="All"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",
                        background: "rgb(148,163,184)",
                        color: "rgb(51,65,85)",

                        position: "relative",
                        zIndex: "100",
                        padding: "0.25rem",
                        borderRadius: "1.2rem",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="font-poppins flex flex-col gap-3 w-full">
              <label htmlFor="" className="text-slate-600 text-[18px] ">
                Edit Levels
              </label>
              <Autocomplete
                disablePortal
                id="doctor-select-demo"
                sx={{
                  height: "100%",

                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      background: "rgb(148,163,184)",

                      borderRadius: "1.2rem",
                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    color: "whitesmoke",
                    position: "relative",
                    zIndex: "100",
                  },
                }}
                options={[]}
                getOptionLabel={(option) => option.name || option.email}
                onChange={(event, value) => {
                  console.log(value);
                }}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <p className="font-poppins m-0 text-[14px] text-slate-700">
                      {option.name || option.email}
                    </p>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",
                        background: "rgb(148,163,184)",
                        color: "rgb(51,65,85)",

                        position: "relative",
                        zIndex: "100",
                        padding: "0.25rem",
                        borderRadius: "1.2rem",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="font-poppins flex flex-col gap-3 w-full items-center ">
              <label htmlFor="" className="text-slate-600 text-[18px] ">
                Include TAT left
              </label>
              <div className="flex gap-2 w-full">
                <input
                  type="time"
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400 w-full"
                  placeholder="Min"
                />
                <input
                  type="time"
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400 w-full"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="font-poppins flex flex-col gap-3 w-full items-center ">
              <label htmlFor="" className="text-slate-600 text-[18px] ">
                Include TAT
              </label>
              <div className="flex gap-2 w-full">
                <input
                  type="time"
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400 w-full"
                  placeholder="Min"
                />
                <input
                  type="time"
                  className="px-4 py-3 rounded-[1.2rem] outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400 w-full"
                  placeholder="Max"
                />
              </div>
            </div>
            <div className="font-poppins flex flex-col gap-3 w-full">
              <label htmlFor="" className="text-slate-600 text-[18px] ">
                File Status
              </label>

              <Autocomplete
                disablePortal
                limitTags={3}
                multiple
                id="doctor-select-demo"
                sx={{
                  height: "100%",

                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      background: "rgb(148,163,184)",

                      borderRadius: "1.2rem",
                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      padding: "0px",
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    color: "whitesmoke",
                    position: "relative",
                    zIndex: "100",
                  },
                }}
                options={[]}
                getOptionLabel={(option) => option.name || option.email}
                onChange={(event, value) => {
                  console.log(value);
                }}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <p className="font-poppins m-0 text-[14px] text-slate-700">
                      {option.name || option.email}
                    </p>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="All Files"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",
                        background: "rgb(148,163,184)",
                        color: "rgb(51,65,85)",

                        position: "relative",
                        zIndex: "100",
                        padding: "0.25rem",
                        borderRadius: "1.2rem",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="font-poppins flex flex-col gap-3 mt-4 w-1/2">
            <label htmlFor="" className="text-slate-600 text-[18px] ">
              Group Name
            </label>
            <input
              required
              type="text"
              className="px-4 py-3 rounded-xl outline-none text-slate-700 text-[16px] placeholder:text-slate-500 placeholder:text-[16px] bg-slate-400 w-full"
              placeholder="Enter Group Name "
              onChange={(e) =>
                setGroupINfo((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 w-full gap-y-6 gap-x-4 mt-6">
            <div className="w-full h-[300px]">
              <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                <p className="text-[18px] text-slate-300">Hospitals</p>
                <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                  <IoIosAdd size={25} className=" font-medium" />
                  Add
                </Button>
              </div>
              <Autocomplete
                id="doctor-select-demo"
                disablePortal={true}
                multiple
                value={value}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [{ name: "Select All...", all: true }, ...filtered];
                }}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                  },
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
                sx={{
                  width: "100%",
                  height: "100px",
                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "none",

                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    "& fieldset": {
                      color: "whitesmoke",
                    },
                  },
                }}
                open={open}
                onOpen={() => setOpen(true)}
                options={hospitalList}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all)) {
                    if (value.length !== hospitalList.length) {
                      setValue3([]);
                      setValue2([]);
                      for (const hospital of hospitalList) {
                        setClinics((prev) => [...prev, ...hospital.clinics]);
                        setDoctors((prev) => [...prev, ...hospital.doctors]);
                      }
                    } else {
                      setClinics([]);
                      setDoctors([]);
                      setValue2([]);
                      setValue3([]);
                      setGroupINfo((prev) => ({ ...prev, doctors: [] }));
                      setGroupINfo((prev) => ({ ...prev, clinics: [] }));
                    }
                    return setValue(
                      value.length === hospitalList.length ? [] : hospitalList
                    );
                  }

                  setValue(newValue);
                  setClinics([]);
                  setDoctors([]);
                  for (const hospital of newValue) {
                    setClinics((prev) => [...prev, ...hospital.clinics]);
                    setDoctors((prev) => [...prev, ...hospital.doctors]);
                  }
                  const selClinics = [];
                  const selDoctors = [];
                  const cIds = [];
                  const dIds = [];
                  for (const hospital of newValue) {
                    for (const clinic of hospital.clinics)
                      if (value3.includes(clinic)) {
                        selClinics.push(clinic);
                        cIds.push(clinic.id);
                      }
                    for (const doctor of hospital.doctors)
                      if (value2.includes(doctor)) {
                        selDoctors.push(doctor);
                        dIds.push(doctor.id);
                      }
                  }
                  setGroupINfo((prev) => ({ ...prev, doctors: dIds }));
                  setGroupINfo((prev) => ({ ...prev, clinics: cIds }));
                  setValue3(selClinics);
                  setValue2(selDoctors);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} className=" font-poppins">
                    <Checkbox
                      icon={<CheckBoxIcon fontSize="small" />}
                      checkedIcon={<CheckBoxOutlineBlank fontSize="small" />}
                      style={{ marginRight: 8 }}
                      // checked={selected} <<<--- OLD
                      checked={
                        option.all
                          ? !(value.length === hospitalList.length)
                          : !selected
                      }
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Search"}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",

                        color: "rgb(51 65 85)",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full h-[300px]">
              <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                <p className="text-[18px] text-slate-300">Doctors</p>
                <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                  <IoIosAdd size={25} className=" font-medium" />
                  Add
                </Button>
              </div>
              <Autocomplete
                id="doctor-select-demo"
                disablePortal={true}
                multiple
                value={value2}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [{ name: "Select All...", all: true }, ...filtered];
                }}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                  },
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
                sx={{
                  width: "100%",
                  height: "100px",
                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "none",

                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    "& fieldset": {
                      color: "whitesmoke",
                    },
                  },
                }}
                open={open}
                onOpen={() => setOpen(true)}
                options={currDoctors}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all)) {
                    if (value2.length !== currDoctors.length) {
                      setValue2([]);
                      const ids = [];
                      for (const doctor of currDoctors) {
                        ids.push(doctor.id);
                      }
                      setGroupINfo((prev) => ({ ...prev, doctors: ids }));
                    } else {
                      setGroupINfo((prev) => ({ ...prev, doctors: [] }));
                    }
                    return setValue2(
                      value2.length === currDoctors.length ? [] : currDoctors
                    );
                  }
                  setValue2(newValue);
                  const ids = [];
                  for (const doctor of newValue) {
                    ids.push(doctor.id);
                  }
                  setGroupINfo((prev) => ({ ...prev, doctors: ids }));
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} className=" font-poppins">
                    <Checkbox
                      icon={<CheckBoxIcon fontSize="small" />}
                      checkedIcon={<CheckBoxOutlineBlank fontSize="small" />}
                      style={{ marginRight: 8 }}
                      // checked={selected} <<<--- OLD
                      checked={
                        option.all
                          ? !(value2.length === currDoctors.length)
                          : !selected
                      }
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Search"}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",

                        color: "rgb(51 65 85)",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full h-[300px]">
              <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                <p className="text-[18px] text-slate-300">Clinics</p>
                <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                  <IoIosAdd size={25} className=" font-medium" />
                  Add
                </Button>
              </div>
              <Autocomplete
                id="doctor-select-demo"
                disablePortal={true}
                multiple
                value={value3}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [{ name: "Select All...", all: true }, ...filtered];
                }}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                  },
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
                sx={{
                  width: "100%",
                  height: "100px",
                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "none",

                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    "& fieldset": {
                      color: "whitesmoke",
                    },
                  },
                }}
                open={open}
                onOpen={() => setOpen(true)}
                options={currClinics}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all)) {
                    if (value3.length !== currClinics.length) {
                      setValue3([]);
                      const ids = [];
                      for (const clinics of currClinics) {
                        ids.push(clinics.id);
                      }
                      setGroupINfo((prev) => ({ ...prev, clinics: ids }));
                    } else {
                      setGroupINfo((prev) => ({ ...prev, clinics: [] }));
                    }
                    return setValue3(
                      value3.length === currClinics.length ? [] : currClinics
                    );
                  }
                  setValue3(newValue);
                  const ids = [];
                  for (const clinics of newValue) {
                    ids.push(clinics.id);
                  }
                  setGroupINfo((prev) => ({ ...prev, clinics: ids }));
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} className=" font-poppins">
                    <Checkbox
                      icon={<CheckBoxIcon fontSize="small" />}
                      checkedIcon={<CheckBoxOutlineBlank fontSize="small" />}
                      style={{ marginRight: 8 }}
                      // checked={selected} <<<--- OLD
                      checked={
                        option.all
                          ? !(value3.length === currClinics.length)
                          : !selected
                      }
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Search"}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",

                        color: "rgb(51 65 85)",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full h-[300px]">
              <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                <p className="text-[18px] text-slate-300">Dictator</p>
                <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                  <IoIosAdd size={25} className=" font-medium" />
                  Add
                </Button>
              </div>
              <Autocomplete
                id="doctor-select-demo"
                disablePortal={true}
                multiple
                value={value4}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [{ name: "Select All...", all: true }, ...filtered];
                }}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                  },
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
                sx={{
                  width: "100%",
                  height: "100px",
                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "none",

                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    "& fieldset": {
                      color: "whitesmoke",
                    },
                  },
                }}
                open={open}
                onOpen={() => setOpen(true)}
                options={dictators}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all)) {
                    if (value4.length !== dictators.length) {
                      const ids = [];
                      for (const dictator of dictators) {
                        ids.push(dictator.id);
                      }
                      setGroupINfo((prev) => ({
                        ...prev,
                        transcripters: ids,
                      }));
                    } else {
                      setGroupINfo((prev) => ({
                        ...prev,
                        transcripters: [],
                      }));
                    }

                    return setValue4(
                      value4.length === dictators.length ? [] : dictators
                    );
                  }
                  const ids = [];
                  for (const dictator of newValue) {
                    ids.push(dictator.id);
                  }
                  setGroupINfo((prev) => ({ ...prev, transcripters: ids }));
                  setValue4(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} className=" font-poppins">
                    <Checkbox
                      icon={<CheckBoxIcon fontSize="small" />}
                      checkedIcon={<CheckBoxOutlineBlank fontSize="small" />}
                      style={{ marginRight: 8 }}
                      // checked={selected} <<<--- OLD
                      checked={
                        option.all
                          ? !(value4.length === dictators.length)
                          : !selected
                      }
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Search"}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",

                        color: "rgb(51 65 85)",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full h-[300px]">
              <div className="bg-slate-600 py-3 px-4 flex justify-between items-center rounded-t-lg">
                <p className="text-[18px] text-slate-300">Worktypes</p>
                <Button className="flex items-center gap-2 text-slate-200 py-4 text-[16px] font-poppins">
                  <IoIosAdd size={25} className=" font-medium" />
                  Add
                </Button>
              </div>
              <Autocomplete
                id="doctor-select-demo"
                disablePortal={true}
                multiple
                value={value5}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [{ name: "Select All...", all: true }, ...filtered];
                }}
                ListboxProps={{
                  style: {
                    maxHeight: "200px",
                  },
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
                sx={{
                  width: "100%",
                  height: "100px",
                  fontFamily: "poppins",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "none",

                      padding: "0px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976D2",
                    },
                  },
                  "& .MuiIconButton-root ": {
                    "& fieldset": {
                      color: "whitesmoke",
                    },
                  },
                }}
                open={open}
                onOpen={() => setOpen(true)}
                options={workTypes}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all)) {
                    if (value5.length !== workTypes.length) {
                      const ids = [];
                      for (const types of newValue) {
                        ids.push(types.id);
                      }
                      setGroupINfo((prev) => ({
                        ...prev,
                        work_types: ids,
                      }));
                    } else {
                      setGroupINfo((prev) => ({ ...prev, work_types: [] }));
                    }
                    return setValue5(
                      value5.length === workTypes.length ? [] : workTypes
                    );
                  }
                  const ids = [];
                  for (const types of workTypes) {
                    ids.push(types.id);
                  }
                  setGroupINfo((prev) => ({ ...prev, work_types: ids }));
                  setValue5(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} className=" font-poppins">
                    <Checkbox
                      icon={<CheckBoxIcon fontSize="small" />}
                      checkedIcon={<CheckBoxOutlineBlank fontSize="small" />}
                      style={{ marginRight: 8 }}
                      // checked={selected} <<<--- OLD
                      checked={
                        option.all
                          ? !(value5.length === workTypes.length)
                          : !selected
                      }
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Search"}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontFamily: "poppins",

                        color: "rgb(51 65 85)",
                      },
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex gap-2 w-full pr-16 justify-end pb-4">
            <Button
              onClick={(e) => editGroup(e)}
              className="bg-green-600 flex items-center justify-center py-5 "
            >
              <div className="flex gap-1 items-center text-slate-200 font-poppins ">
                <IoMdSave size={22} className="" />
                <p className="text-[16px]">Save</p>
              </div>
            </Button>
            <Button
              className="bg-red-500 flex items-center justify-center py-5 "
              onClick={() => setEditPool(false)}
            >
              <div className="flex gap-1 items-center text-slate-200 font-poppins ">
                <IoMdClose size={22} className="" />
                <p className="text-[16px]">Close</p>
              </div>
            </Button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default EditWorkpool;
