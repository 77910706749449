import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/StateContext";
import { Button, FormControl, MenuItem, Select } from "@mui/material";

import AddForm from "../forms/AddForm";
import { GiHospitalCross } from "react-icons/gi";
import axios from "axios";
import {
  ConfigProvider,
  Dropdown,
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
  Switch,
} from "antd";
import FilterForm from "../forms/FilterForm";
import { ColorRing, Grid } from "react-loader-spinner";
import { IoClose, IoMenu } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import Navbar from "../navbar";
import SideBarFloat from "../SideBarFloat";
import bg from "../../assets/D2.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { URLconstants } from "../../api/constants";
import { RowingSharp } from "@mui/icons-material";
const generateReportLabel = { inputProps: { "aria-label": "Generate Report" } };
const Hospital = () => {
  const {
    setSection,
    open,
    setOpen,
    handleDownload,
    section,
    fetchData,
    rows,
    loading,
    setLoading,
    setFilter,
    filter,
    handleFilterTable,
    total,
    pageSize,
    currentPage,
    setCurrentPage,
    filterFunction,
    filterInfo,
    setFilterInfo,
    visibilityFunction,
    passwordReset,
    setPassword,
    resetPassword,
  } = useContext(Context);
  const navigate = useNavigate();
  const [clinic, setClinic] = useState("");

  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const isEditing = (record) => record.key === editingKey;

  const name = section[0].toUpperCase() + section.slice(1, section.length - 1);
  const heading = [
    "Id",
    "Name",
    "Address",
    "Email",
    "Contact No.",
    "Created At",
  ];
  const [filterOn, setFilterOn] = useState(false);
  const dataArray = rows.map((item) => [
    item.id,
    item?.name,
    item.address,
    item.email,
    item.contact_number,
    item.createdAt,
  ]);

  const columns = [
    {
      title: "",
      dataIndex: "options",
      key: "options",
      width: "80px",
      render: (item, record) => {
        const items = [
          {
            key: "1",
            label: (
              <Button
                sx={{
                  textTransform: "none",
                }}
                onClick={async () => await visibilityFunction(record.id)}
              >
                <p className="font-poppins text-slate-700">Remove</p>
              </Button>
            ),
          },
          {
            key: "2",
            label: (
              <Button
                sx={{
                  textTransform: "none",
                }}
                onClick={() =>
                  setPassword({ open: true, id: record.hospital_id })
                }
              >
                <p className="font-poppins text-slate-700">Reset Password</p>
              </Button>
            ),
          },
        ];
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button>
              <IoMenu size={20} />
            </Button>
          </Dropdown>
        );
      },
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <a href="#" target="_blank">
          {name}
        </a>
      ),
      width: "14%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter your name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "14%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name="address">
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Generate Report",
      dataIndex: "generate_report",
      key: "generate_report",
      width: "14%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name="generate_report">
              <Switch />
            </Form.Item>
          );
        } else {
          console.log("Record Generate Report: ", record);
          return (
            <Switch
              disabled
              defaultChecked={record.generate_report ? true : false}
            />
          );
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "14%",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name="email">
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Contact_No.",
      dataIndex: "contact_number",
      key: "contact_number",
      width: "150px",
      editable: true,
      render: (text, record) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name="contact_number">
              <Input />
            </Form.Item>
          );
        } else {
          return <p className=" font-poppins text-slate-700">{text}</p>;
        }
      },
    },
    {
      title: "Clinics",
      dataIndex: "clinics",
      key: "clinics",
      render: (item) =>
        item?.length > 0 ? (
          <FormControl>
            <Select
              sx={{
                fontSize: "15px",
                color: "rgb(51,65,85)",
                fontFamily: "poppins",
                height: "35px",
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => handleChange(e)}
              value={clinic}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <p className="font-poppins text-[15px] text-slate-500 lg:mr-4">
                      Clinics
                    </p>
                  );
                }
                return selected;
              }}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "200px" }, // Adjust the height as per your requirement
                },
              }}
            >
              {item?.map((i) => (
                <MenuItem
                  value={i.name}
                  onClick={() => {
                    setSection("clinics");
                    setFilter(i.name);
                    navigate("/clinic");
                  }}
                  sx={{
                    fontFamily: "Poppins",
                  }}
                >
                  <p className="font-poppins text-[14px] text-slate-700">
                    {i.name}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <p className="font-poppins">N.A</p>
        ),
      width: "120px",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
      render: (item) => item.split("T")[0],
    },
    {
      title: "",
      dataIndex: "action",
      width: `${editingKey !== "" ? "12%" : "7%"}`,
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className=" flex gap-2">
            <Typography.Link
              onClick={() => save(record.id)}
              className=" font-poppins"
            >
              Save
            </Typography.Link>
            <>
              <Typography.Link
                title="Sure to cancel?"
                className=" font-poppins lg:block hidden"
                onClick={() => cancel()}
              >
                Cancel
              </Typography.Link>
              <Typography.Link
                title="Sure to cancel?"
                className=" font-poppins lg:hidden"
                onClick={() => cancel()}
              >
                <IoClose size={20} />
              </Typography.Link>
            </>
          </span>
        ) : (
          <>
            <Typography.Link
              className=" font-poppins lg:block hidden "
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link
              className=" font-poppins lg:hidden  "
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <CiEdit size={20} />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      name: record.name,
      staff_id: record.staff_id,
      email: record.email,
      contact_number: record.contact_number,
      address: record.address,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    const row = await form.validateFields();
    console.log("ID: ", id, row);
    try {
      const row = await form.validateFields();
      const { data } = await axios.put(
        `${URLconstants.fetchHospitals()}/${id}`,
        {
          data: row,
        }
      );
      await fetchData(currentPage);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setEditingKey("");
    }
  };

  const handleChange = (event) => {
    setClinic(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);

    if (!filterOn) {
      fetchData(page);
    } else {
      filterFunction(
        page,
        filterInfo.fieldName,
        filterInfo.subFilter,
        filterInfo.date
      );
    }
  };

  useEffect(() => {
    const fetchDataAndToggleLoading = async () => {
      try {
        setLoading(true);
        setCurrentPage(1);
        setFilterInfo({ date: "", fieldName: "", subFilter: "" });
        localStorage.setItem("section", "hospitals");
        if (filter) {
          await handleFilterTable();
        } else {
          await fetchData(1);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndToggleLoading();
  }, [section]);

  return (
    <>
      <Navbar />
      <SideBarFloat />
      <section className="scroll-smooth h-screen  bg-[#f0f0f0ea] font-poppins relative overflow-clip">
        {resetPassword.open && (
          <div>
            {loading && (
              <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100] ">
                <Grid
                  visible={true}
                  height="80"
                  width="80"
                  color="#4A57EC"
                  ariaLabel="grid-loading"
                  radius="12.5"
                  wrapperStyle={{}}
                  wrapperClass="grid-wrapper"
                />
              </div>
            )}
            <div
              className={`absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[1100]  ${
                loading && "opacity-[0.4]"
              } flex flex-col items-end gap-1  `}
            >
              <Button
                sx={{
                  borderRadius: "50%",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "60px",
                  height: "60px",
                }}
                onClick={() => setPassword(false)}
              >
                <IoClose size={25} className="text-slate-800" />
              </Button>
              <div className="bg-slate-800 p-4 rounded-2xl">
                <form
                  className="flex gap-4 items-center lg:flex-row md:flex-row flex-col"
                  onSubmit={(e) => passwordReset(e, resetPassword.id)}
                >
                  <input
                    type="text"
                    required
                    className="px-4 py-2 rounded-[1.2rem] outline-none text-[#f0f0f0d2] bg-slate-700  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border border-slate-600 flex justify-center w-full"
                    placeholder="Password"
                  />
                  <Button
                    sx={{
                      borderRadius: "1.3rem",
                      padding: "10px 2rem",
                      color: "#f0f0f0fe",
                      marginBottom: "4px",
                      boxShadow: "inset -2px 1px 10px #f0f0f0fe",
                    }}
                    type="submit"
                  >
                    <p className="font-poppins text-[16px]">Submit</p>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        )}
        <div>
          <img
            src={bg}
            alt="bg"
            className="w-[38rem] h-[40rem] absolute top-[2rem] right-[-5rem]"
          />
        </div>
        <div
          className={`px-8 h-full  w-full overflow-x-clip overflow-y-scroll scrollbar-hide relative z-[100] ${
            resetPassword.open && "opacity-30"
          }`}
        >
          <div
            className={`flex ${
              open ? "lg:w-7/12 md:w-7/12" : ""
            } pt-10 pb-10 mt-[4.5rem] justify-between lg:items-center md:items-end items-start lg:flex-row md:flex-row flex-col relative`}
          >
            <div className="flex gap-4 md:items-center lg:items-center relative z-[100] lg:flex-row md:flex-row flex-col items-start ">
              <div>
                <p className="text-slate-800 tracking-wide font-semibold lg:text-[48px] text-[32px]">
                  {open ? `Add ${name}` : `${name} List`}{" "}
                </p>
                <div className="border border-slate-500 rounded-[3rem] mt-2 w-[40%]"></div>
              </div>
              <Link to="/home">
                <Button
                  onClick={() => setSection("dash")}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <p className="lg:text-[18px] text-slate-500 font-poppins">
                    Home
                  </p>
                </Button>
              </Link>
            </div>
            {open ? (
              <Button
                sx={{ borderRadius: "1.5rem", textTransform: "none" }}
                onClick={() => setOpen((prev) => !prev)}
                className="w-[120px] h-[52px]  gap-2 border border-slate-400 rounded-[2.5rem] flex items-center justify-center shadow-[inset_-2px_1px_10px_#9de6f5]"
              >
                <p className="font-poppins text-[18px] text-slate-700">Close</p>
                <IoClose size={25} className="text-slate-900" />
              </Button>
            ) : (
              <div className="flex relative z-[100] items-center gap-4 lg:mt-0 mt-8">
                <Button
                  sx={{ borderRadius: "1.5rem", textTransform: "none" }}
                  onClick={() => setOpen((prev) => !prev)}
                  className="w-[100px] h-[52px]  gap-2 border border-slate-400 rounded-[2.5rem] flex items-center justify-center shadow-[inset_-2px_1px_10px_#9de6f5]"
                >
                  <p className="font-poppins text-[16px] text-slate-700">Add</p>
                  <GiHospitalCross size={20} className="text-slate-800" />
                </Button>
                <div className=" rounded-[1.5rem]  bg-slate-900">
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1.5rem",
                      padding: "10px",
                    }}
                    onClick={() => handleDownload(dataArray, heading)}
                  >
                    <p className="lg:text-[18px] font-poppins font-light text-slate-200  ">
                      Download Excel
                    </p>
                  </Button>
                </div>
              </div>
            )}
          </div>
          {open ? (
            <AddForm />
          ) : (
            <>
              <FilterForm setFilterOn={setFilterOn} />

              <div className="lg:py-8 md:py-8 pt-4 pb-[6rem] flex items-center justify-center">
                {loading ? (
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#27284a",
                      "#3c4a74",
                      "#4f6ea0",
                      "#5f96cd",
                      "#6ec0fa",
                    ]}
                  />
                ) : (
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBorderRadius: 20,
                          headerBg: "#cbd5e1",
                          headerSortHoverBg: "",
                        },
                      },
                    }}
                  >
                    <Form form={form} component={false}>
                      <Table
                        dataSource={rows}
                        columns={columns}
                        className="rounded-[1.5rem]   text-slate-800 shadow-lg shadow-slate-300  "
                        pagination={{
                          current: currentPage,
                          pageSize: pageSize,
                          showSizeChanger: false,
                          total: total,
                          onChange: handlePageChange,
                          style: {
                            padding: "0 12px",
                            fontFamily: "Poppins",
                          },
                        }}
                        scroll={{ y: 450, x: "calc(650px + 50%)" }}
                      />
                    </Form>
                  </ConfigProvider>
                )}
              </div>
            </>
          )}
        </div>

        <ToastContainer />
      </section>
    </>
  );
};

export default Hospital;
